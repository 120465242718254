import {
  CancelSerializer,
  Event,
  TimeFrame,
} from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Checkbox from 'components/checkbox';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Select from 'components/select';
import { PAYMENT_AUTH_DAYS, UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  cancelAppointment,
  fetchClientAppointments,
} from 'state/slice/appointments';
import { getAppointmentTimeFrame } from 'utils/business-logic';
import { SHARED_ROUTES, SHOW_HELP } from 'utils/routing';

type ClientCancelProps = {
  appointment: Event;
  onCancel(note: string): void;
  onClose(): void;
  isSubmitting: boolean;
};

const clientCancelOptions = [
  {
    label: 'I cannot make this time and will reschedule',
    value: 'reschedule',
  },
  {
    label: 'I no longer require this appointment',
    value: 'cancel',
  },
  {
    label: 'I was unsatisfied with my last appointment',
    value: 'unsatisfied',
  },
  {
    label: 'My instructor asked me to cancel for them',
    value: 'instructor',
  },
];

const ClientCancelOutside48 = ({
  appointment,
  isSubmitting,
  onCancel,
  onClose,
}: ClientCancelProps) => {
  const [note, setNote] = useState('');
  const now = moment();
  const authDt = moment(appointment.start)
    .tz(appointment.timezone)
    .subtract(PAYMENT_AUTH_DAYS, 'days');
  const outsideAuthWindow = now.isBefore(authDt, 'hours');
  return (
    <>
      <div className="mb-8 space-y-3">
        <p>
          {outsideAuthWindow
            ? 'No credit card charges have been made for this lesson, so no refund is required. '
            : 'All credit card authorizations for this lesson will be cancelled, so no refund is required. '}
          Any credit that was applied at the time of booking will be returned to
          your account.
        </p>
        <p>
          <strong>Is there any specific reason you're cancelling?</strong>
        </p>
        <Select
          name="reason"
          options={clientCancelOptions}
          onChange={(opt) => setNote(opt.label as string)}
          disabled={isSubmitting}
        />
      </div>
      <Controls variant="block">
        <BackButton onClick={onClose} disabled={isSubmitting}>
          Go back
        </BackButton>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => onCancel(note)}
          disabled={isSubmitting}
        >
          Cancel appointment
        </Button>
      </Controls>
    </>
  );
};

const ClientCancelWithin48 = ({
  appointment,
  isSubmitting,
  onCancel,
  onClose,
  ...rest
}: ClientCancelProps) => {
  const [note, setNote] = useState('');
  const [agreed, setAgreed] = useState(false);

  const editableAddons = appointment.receipt.lineItems.filter(
    (addon) => addon.editable
  );

  const editableAddonText =
    editableAddons.length === 1
      ? editableAddons[0].name.toLowerCase()
      : editableAddons.length === 2
      ? `${editableAddons[0].name.toLowerCase()} and ${editableAddons[1].name.toLowerCase()}`
      : 'following';

  return (
    <>
      <div className="mb-8 space-y-5">
        <p>
          This lesson is now non-refundable.
          <br />
          <button className="link" onClick={SHOW_HELP.CANCELLATION_POLICY}>
            Review policy
          </button>
        </p>
        {editableAddons.length > 0 && (
          <>
            <p>
              If you decide to cancel, the {editableAddonText} will be returned
              to your account as credit.
            </p>
            <ul className="p-4 space-y-2 rounded-lg bg-background">
              {editableAddons.map((addon, i) => (
                <li key={`add${i}`} className="flex justify-between ">
                  <span>{addon.name} fee:</span>
                  <span>+{addon.price.toCurrency()}</span>
                </li>
              ))}
              <hr />
              <li className="flex justify-between font-semibold">
                <span>Total credit returned:</span>
                <span>
                  {editableAddons
                    .reduce((agg, add) => agg + Number(add.price), 0)
                    .toCurrency()}
                </span>
              </li>
            </ul>
          </>
        )}
        <Checkbox
          onChange={(evt) => setAgreed(evt.target.checked)}
          label="I understand my payment is not refunded"
          disabled={isSubmitting}
        />
      </div>
      <Controls variant="block">
        <BackButton onClick={onClose} disabled={isSubmitting}>
          Go back
        </BackButton>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => onCancel(note)}
          disabled={!agreed || isSubmitting}
        >
          Cancel appointment
        </Button>
      </Controls>
    </>
  );
};

const ClientSelectProblemView = ({
  appointment,
  isOpen,
  onClose,
}: {
  appointment: Event;
  isOpen: boolean;
  onClose(): void;
}) => {
  const timer = useTimeoutRefresh(); // triggers re-render on the :00 and :30
  const timeFrame = getAppointmentTimeFrame(appointment);
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCancellation = async (note: string) => {
    const data: CancelSerializer = {
      reason: 'ClientCancelled',
      notes:
        note !== ''
          ? [
              {
                recipient: UserType.Admin,
                sender: UserType.Client,
                text: note,
              },
            ]
          : undefined,
    };
    setSubmitting(true);
    if (await dispatch(cancelAppointment(appointment, data))) {
      dispatch(
        fetchClientAppointments({
          start: appointment.date,
          end: appointment.date,
        })
      );
      history.push(SHARED_ROUTES.SCHEDULE.appointment(appointment.id));
    }
    setSubmitting(false);
  };
  const handleModalClose = () => {
    onClose();
  };
  const modalTitle =
    timeFrame === TimeFrame.Outside48
      ? `Cancel ${appointment.activity.appointmentNoun.toLowerCase()}`
      : 'Cancellations within 48 hours';
  return (
    <Modal
      name="Client — Cancel"
      title={modalTitle}
      open={isOpen}
      onClose={handleModalClose}
      maxWidth="xs"
    >
      {isSubmitting && <Loading />}
      {timeFrame === TimeFrame.Outside48 ? (
        <ClientCancelOutside48
          appointment={appointment}
          onClose={onClose}
          onCancel={handleCancellation}
          isSubmitting={isSubmitting}
        />
      ) : (
        <ClientCancelWithin48
          appointment={appointment}
          onClose={onClose}
          onCancel={handleCancellation}
          isSubmitting={isSubmitting}
        />
      )}
    </Modal>
  );
};

export default ClientSelectProblemView;

import api from 'api';
import {
  AppointmentProduct,
  AppointmentProductStatus,
} from 'api/Serializers/AppointmentProducts';
import {
  FacilitySchedulableSerializer,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import { Price } from 'api/Serializers/Price';
import { PropelServiceSerializer } from 'api/Serializers/Services';
import { AxiosError } from 'axios';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import InputCalendar from 'components/input-calendar';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Select from 'components/select';
import {
  DATE_FMT,
  INSTRUCTOR_RATE_INCR,
  MAX_PRICE,
  MIN_PRICE,
  NEXT_SUMMER_SWITCHOVER_DATE,
  SUMMER_START_DATE,
} from 'config';
import FacilityMap from 'containers/facility-map';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AddIcon, MinusIcon, PayoutIcon, WarningIcon } from 'icons';
import moment from 'moment-timezone';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getInstructorDetails } from 'state/selectors';
import { fetchInstructorDetail } from 'state/slice/instructor';
import {
  createAppointmentProduct,
  deleteAppointmentProduct,
  fetchAppointmentProducts,
} from 'state/slice/schedule';
import { LocalStore } from 'state/storage';

interface Props {
  username: string;
}

interface SelectNewFacilityProps {
  appointmentProducts: AppointmentProduct[];
  onSelect(facility: FacilitySchedulableSerializer): void;
  onGoBack(): void;
}

const AdmissionFeeChange = ({
  appointmentProduct,
}: {
  appointmentProduct: AppointmentProduct;
}) => {
  const showAdmissionFeeChanging =
    !!appointmentProduct.nextAdmissionFee &&
    (!appointmentProduct.nextPriceStartDate ||
      appointmentProduct.nextPriceStartDate <
        appointmentProduct.nextAdmissionFeeStartDate);
  if (!showAdmissionFeeChanging) {
    return null;
  }
  return (
    <Callout
      title={`Admission fee change (${
        appointmentProduct.nextAdmissionFee > appointmentProduct.admissionFee
          ? '+'
          : '-'
      }$${Math.abs(
        appointmentProduct.nextAdmissionFee - appointmentProduct.admissionFee
      )})`}
      type="info"
      className="my-4"
    >
      This {appointmentProduct.facility.assetType.toLowerCase()} host has set
      their fee to change on{' '}
      {moment(appointmentProduct.nextAdmissionFeeStartDate).format(
        DATE_FMT.MONTH_D_YEAR
      )}
      . This will impact your earnings for{' '}
      {appointmentProduct.activity.appointmentNoun.toLowerCase()}s{' '}
      <strong>booked</strong> on or after that date, so consider adjusting your
      price.
    </Callout>
  );
};

const BookingFeeChange = ({
  services,
}: {
  services: PropelServiceSerializer[];
}) => {
  const hasChanged =
    services.length > 1
      ? services[0].basePrice !== services[1].basePrice ||
        services[0].bookingRate !== services[1].bookingRate ||
        services[0].taxRate !== services[1].taxRate
      : false;
  if (!hasChanged) {
    return null;
  }
  return (
    <Callout title="Booking fee change" type="info" className="my-4">
      {services[1].changeDescription}
    </Callout>
  );
};

const SelectNewFacility = ({
  appointmentProducts,
  onSelect,
  onGoBack,
}: SelectNewFacilityProps) => {
  const instructor = useSelector(getInstructorDetails);

  useEffect(() => {}, []);

  return (
    <>
      <FacilityMap
        home={instructor?.address?.latlng}
        onFacilitySelect={onSelect}
        appointmentProducts={appointmentProducts}
        onGoBack={onGoBack}
        fullscreen={true}
      />
    </>
  );
};

interface Breakdown {
  price: number;
  admissionFee: number;
  bookingFee: number;
  admissionFeeTax: number;
  bookingFeeTax: number;
  admissionFeeTaxRate: number;
  bookingFeeTaxRate: number;
  salesTax: number;
  earnings: number;
  extraSwimmer: number;
  splitFeeTax: boolean;
}

const hasBreakdownChanged = (a: Breakdown, b: Breakdown) =>
  a.price &&
  b.price &&
  (a.price !== b.price ||
    a.admissionFee !== b.admissionFee ||
    a.bookingFee !== b.bookingFee ||
    a.admissionFeeTax !== b.admissionFeeTax ||
    a.bookingFeeTax !== b.bookingFeeTax ||
    a.salesTax !== b.salesTax ||
    a.earnings !== b.earnings ||
    a.extraSwimmer !== b.extraSwimmer);

const SetNewPrice = ({
  appointmentProduct,
  prices,
  isLoading,
  services,
  onSave,
  onDelete,
  onClose,
}: {
  appointmentProduct: AppointmentProduct;
  services: PropelServiceSerializer[];
  isLoading: boolean;
  prices: Price[];
  onSave(price: number, startDate: string): void;
  onDelete(priceId: number): void;
  onClose(): void;
}) => {
  // TODO: this needs its own price query so the appointment product query
  //  doesn't have to do so much heavy lifting
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStart] = useState<string>(() => {
    return prices.length === 0
      ? moment().format(DATE_FMT.DATE_KEY)
      : prices.length > 1
      ? prices[1].startDate
      : undefined;
  });
  const [price, setPrice] = useState(() => {
    return prices.length === 0
      ? appointmentProduct.facility.averageAppointmentPrice
      : prices.length === 1
      ? prices[0].price
      : prices.length > 1
      ? prices[1].price
      : appointmentProduct.facility.averageAppointmentPrice;
  });
  const [showDatePicker, setShowDatePicker] = useState(prices.length === 0);

  if (!appointmentProduct) {
    return null;
  }

  const getEarningsBreakdown = (
    price: number,
    startDate: string
  ): Breakdown => {
    let service;
    if (services.length === 1) {
      service = services[0];
    } else {
      service = services.reduce((current, service, i) => {
        if (!current) {
          return service;
        } else if (
          service.startDate <= startDate &&
          service.startDate > current.startDate
        ) {
          return service;
        } else {
          return current;
        }
      }, undefined);
    }
    let admissionFee = appointmentProduct.admissionFee;
    if (
      !!appointmentProduct.nextAdmissionFee &&
      startDate &&
      appointmentProduct.nextAdmissionFeeStartDate <= startDate
    ) {
      admissionFee = appointmentProduct.nextAdmissionFee;
    }
    if (!price || !service) {
      return {
        price: undefined,
        admissionFee,
        bookingFee: 0,
        admissionFeeTax: 0,
        bookingFeeTax: 0,
        admissionFeeTaxRate: 0,
        bookingFeeTaxRate: 0,
        salesTax: 0,
        earnings: 0,
        extraSwimmer: 0,
        splitFeeTax: false,
      };
    }
    const admissionFeeTaxRate = appointmentProduct.taxRate;
    const bookingFeeTaxRate = Number(service.taxRate);
    const bookingFee = price * Number(service.bookingRate);
    const bookingFeeTax = bookingFee * Number(service.taxRate);
    const admissionFeeTax = admissionFee * appointmentProduct.taxRate;
    const totalFees = admissionFee + bookingFee;
    const salesTax = admissionFeeTax + bookingFeeTax;
    const earnings = price - totalFees - salesTax;
    const extraSwimmer = earnings * 1.2;
    return {
      price,
      admissionFee,
      bookingFee,
      admissionFeeTax,
      bookingFeeTax,
      admissionFeeTaxRate,
      bookingFeeTaxRate,
      salesTax,
      earnings,
      extraSwimmer,
      splitFeeTax: Number(service.taxRate) !== appointmentProduct.taxRate,
    };
  };

  const currentBreakdown: Breakdown = getEarningsBreakdown(
    prices[0]?.price,
    prices[0]?.startDate
  );
  const newBreakdown = getEarningsBreakdown(price, startDate);
  const hasChanged = hasBreakdownChanged(currentBreakdown, newBreakdown);

  const handlePriceChange = (newPrice: number) => {
    if (newPrice > MAX_PRICE || newPrice < MIN_PRICE) {
      enqueueSnackbar({
        message: `The minimum price is ${MIN_PRICE.toCurrency()} and the maximum price is ${MAX_PRICE.toCurrency()}`,
        variant: 'error',
        preventDuplicate: true,
      });
    } else {
      setPrice(newPrice);
    }
  };

  const handleDelete = async () => {
    onDelete(prices[1].id);
  };

  const handleSave = () => {
    onSave(price, startDate);
  };

  const getCanSave = (): boolean => {
    if (prices.length === 0) {
      return !!startDate && !!price;
    } else if (prices.length === 1) {
      return !!startDate && price !== prices[0].price;
    } else if (prices.length === 2) {
      return (
        !!startDate &&
        price &&
        (startDate !== prices[1].startDate || price !== prices[1].price)
      );
    } else {
      return false;
    }
  };

  const handleDateSelect = (opt: { label: string; value: string }) => {
    if (opt.value === 'CUSTOM') {
      setShowDatePicker(true);
    } else {
      setStart(opt.value);
    }
  };

  const canSave = getCanSave();

  // Moment test to see if June 1st is AFTER today
  const isBeforeSummer = moment(NEXT_SUMMER_SWITCHOVER_DATE)
    .year(moment().year())
    .isAfter(moment());
  const nextSummerLabel = isBeforeSummer ? 'This summer' : 'Next summer';
  const nextSummerYear = isBeforeSummer ? moment().year() : moment().year() + 1;

  const options = !!appointmentProduct?.nextAdmissionFeeStartDate
    ? [
        {
          label: nextSummerLabel,
          value: moment(SUMMER_START_DATE)
            .set('year', nextSummerYear)
            .format(DATE_FMT.DATE_KEY),
        },
        {
          label: 'Next Year',
          value: moment('2020-01-01')
            .set('year', moment().year() + 1)
            .format(DATE_FMT.DATE_KEY),
        },
        {
          label: 'Admission Fee Change Date',
          value: moment(appointmentProduct?.nextAdmissionFeeStartDate).format(
            DATE_FMT.DATE_KEY
          ),
        },
        {
          label: 'Choose a specific date',
          value: 'CUSTOM',
        },
      ]
    : [
        {
          label: nextSummerLabel,
          value: moment(SUMMER_START_DATE)
            .set('year', nextSummerYear)
            .format(DATE_FMT.DATE_KEY),
        },
        {
          label: 'Next Year',
          value: moment('2020-01-01')
            .set('year', moment().year() + 1)
            .format(DATE_FMT.DATE_KEY),
        },
        {
          label: 'Choose a specific date',
          value: 'CUSTOM',
        },
      ];

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full py-4 space-y-4 sm:w-96">
        <h2 className="text-lg">
          Change Price for {appointmentProduct.facility.shortName}
        </h2>
        <p>
          Lessons booked before the date you set below will still be charged at
          your current price, regardless of when the lesson is delivered.
        </p>
        <AdmissionFeeChange appointmentProduct={appointmentProduct} />
        <div>
          <label htmlFor="startDate">Start Date</label>
          {!showDatePicker ? (
            <Select
              name="startDate"
              isSearchable={false}
              onChange={handleDateSelect}
              formatOptionLabel={(data) => (
                <div className="flex items-center justify-between">
                  <span className="font-medium text-gray-900">
                    {data.label}
                  </span>
                  <span className="text-sm italic text-gray-700">
                    {data.value !== 'CUSTOM' &&
                      moment(data.value).format(DATE_FMT.MONTH_D_YEAR)}
                  </span>
                </div>
              )}
              options={options}
            />
          ) : (
            <InputCalendar
              name="startDate"
              minDate={moment().add(1, 'day').format(DATE_FMT.DATE_KEY)}
              maxDate={moment().add(3, 'months').format(DATE_FMT.DATE_KEY)}
              onSelect={(date) => setStart(date)}
              disabled={!appointmentProduct.price}
              default={startDate}
              autoFocus={true}
            />
          )}
        </div>
        <div className="p-4 rounded-lg bg-background">
          <h2 className="font-semibold text-center text-md">New Price</h2>
          <div className="flex-center">
            <button
              className="rounded-full btn gray !px-1.5"
              onClick={() => handlePriceChange(price - INSTRUCTOR_RATE_INCR)}
            >
              <MinusIcon width={18} />
            </button>
            <div className="w-16 text-2xl font-semibold text-center text-gray-900">
              ${price}
            </div>
            <button
              className="rounded-full btn gray !px-1.5"
              onClick={() => handlePriceChange(price + INSTRUCTOR_RATE_INCR)}
            >
              <AddIcon width={18} />
            </button>
          </div>
        </div>
        {prices.length > 0 ? (
          <div className="grid grid-cols-4 space-y-2 text-base">
            <div className="col-span-2" />
            <div className="font-medium text-right text-gray-700">Current</div>
            <div className="font-medium text-right text-gray-700">New</div>

            <div className="col-span-2">Price per lesson</div>
            <div className="text-right text-gray-600 text-md">
              {currentBreakdown.price.toCurrency()}
            </div>
            <div className="font-semibold text-right text-gray-900 text-md">
              {hasChanged ? newBreakdown.price.toCurrency() : '—'}
            </div>

            {newBreakdown.splitFeeTax ? (
              <>
                <div className="col-span-2">Admission fee</div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.admissionFee.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.admissionFee.toCurrency()}`
                    : '—'}
                </div>

                <div className="col-span-2">Booking fee</div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.bookingFee.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.bookingFee.toCurrency()}`
                    : '—'}
                </div>

                <div className="col-span-2">
                  Admission fee tax (
                  {(newBreakdown.admissionFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.admissionFeeTax.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.admissionFeeTax.toCurrency()}`
                    : '—'}
                </div>

                <div className="col-span-2">
                  Booking fee tax (
                  {(newBreakdown.bookingFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.bookingFeeTax.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.bookingFeeTax.toCurrency()}`
                    : '—'}
                </div>
              </>
            ) : (
              <>
                <div className="col-span-2">Admission fee</div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.admissionFee.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.admissionFee.toCurrency()}`
                    : '—'}
                </div>

                <div className="col-span-2">Booking fee</div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.bookingFee.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged
                    ? `-${newBreakdown.bookingFee.toCurrency()}`
                    : '—'}
                </div>

                <div className="col-span-2">
                  Sales tax (
                  {(newBreakdown.admissionFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-light text-right text-gray-600">
                  -{currentBreakdown.salesTax.toCurrency()}
                </div>
                <div className="font-normal text-right text-gray-700">
                  {hasChanged ? `-${newBreakdown.salesTax.toCurrency()}` : '—'}
                </div>
              </>
            )}

            <hr className="col-span-4" />

            <div className="col-span-2 font-bold">Payout per lesson</div>
            <div className="font-light text-right text-gray-600">
              {currentBreakdown.earnings.toCurrency()}
            </div>
            <div className="font-bold text-right text-gray-900">
              {hasChanged ? newBreakdown.earnings.toCurrency() : '—'}
            </div>

            <div className="col-span-2 italic">With 2 swimmers</div>
            <div className="italic font-light text-right text-gray-600">
              {currentBreakdown.extraSwimmer.toCurrency()}
            </div>
            <div className="italic font-medium text-right text-gray-900">
              {hasChanged ? newBreakdown.extraSwimmer.toCurrency() : '—'}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-4 space-y-2 text-base">
            <div className="col-span-3" />
            <div className="font-medium text-right text-gray-700">New</div>

            <div className="col-span-3">Price per lesson</div>
            <div className="font-semibold text-right text-gray-900 text-md">
              {price.toCurrency()}
            </div>

            {newBreakdown.splitFeeTax ? (
              <>
                <div className="col-span-3">Admission fee</div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.admissionFee.toCurrency()}
                </div>

                <div className="col-span-3">Booking fee</div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.bookingFee.toCurrency()}
                </div>
                <div className="col-span-3">
                  Admission fee tax (
                  {(newBreakdown.admissionFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.admissionFeeTax.toCurrency()}
                </div>
                <div className="col-span-3">
                  Booking fee tax (
                  {(newBreakdown.bookingFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.bookingFeeTax.toCurrency()}
                </div>
              </>
            ) : (
              <>
                <div className="col-span-3">Admission fee</div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.admissionFee.toCurrency()}
                </div>

                <div className="col-span-3">Booking fee</div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.bookingFee.toCurrency()}
                </div>
                <div className="col-span-3">
                  Sales tax (
                  {(newBreakdown.admissionFeeTaxRate * 100).toFixed()}%)
                </div>
                <div className="font-normal text-right text-gray-700">
                  -{newBreakdown.salesTax.toCurrency()}
                </div>
              </>
            )}

            <hr className="col-span-4" />

            <div className="col-span-3 font-bold">Payout per lesson</div>
            <div className="font-bold text-right text-gray-900">
              {newBreakdown.earnings.toCurrency()}
            </div>

            <div className="col-span-3 italic">With 2 swimmers</div>
            <div className="italic font-medium text-right text-gray-900">
              {newBreakdown.extraSwimmer.toCurrency()}
            </div>
          </div>
        )}
        {canSave ? (
          prices.length > 0 ? (
            <Callout type="success" title={`${price.toCurrency()}/hr`}>
              {`Changing on ${moment(startDate, DATE_FMT.DATE_KEY).format(
                DATE_FMT.MONTH_D_YEAR
              )}`}
            </Callout>
          ) : (
            <Callout type="info" icon={<PayoutIcon width={24} />}>
              {`${price.toCurrency()}/hr`}
            </Callout>
          )
        ) : (
          <Callout
            type="warning"
            icon={<WarningIcon width={24} />}
            className="gap-2"
          >
            {hasChanged && price === prices[0]?.price && (
              <span>
                Your payout will change, even though your price is unchanged.{' '}
              </span>
            )}
            Select a date and a new price to continue
          </Callout>
        )}
        <Controls>
          {
            <Button onClick={onClose} variant="flat" disabled={isLoading}>
              Cancel
            </Button>
          }
          {prices.length > 1 && (
            <Button
              onClick={handleDelete}
              variant="flat"
              color="secondary"
              disabled={canSave || isLoading}
            >
              Delete ${prices[1].price} price
            </Button>
          )}
          <Button
            disabled={!canSave || isLoading}
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </Controls>
      </div>
    </>
  );
};

const AddLocationHelp = ({ onClose }) => (
  <>
    <p>When adding a new location, please note:</p>
    <ol>
      <li>
        You cannot add availability to more than one pool in any given day
      </li>
      <li>
        Each pool may have a different admission fee, so you should compare and
        consider this when selecting a pool to list at
      </li>
      <li>
        You can set your price for each pool separately to accommodate for
        differences in admission fee, drive time, or anything else.
      </li>
    </ol>
    <Controls>
      <Button color="primary" variant="flat" onClick={onClose}>
        I Understand
      </Button>
    </Controls>
  </>
);

const EditPriceHelp = ({ onClose }) => (
  <div className="space-y-4">
    <p>
      If you have existing clients, please ensure you have considered the
      following:
    </p>
    <ol>
      <li>
        Raising your price more than $5-$10 at a time may shock clients and
        cause them to book fewer lessons
      </li>
      <li>
        Price changes are best received by clients when set well into the future
        instead of effective immediately
      </li>
      <li>
        Clients appreciate when you Message them about price changes and provide
        substantiating reasons
      </li>
    </ol>
    <Callout type="success" title="Here's an example message:">
      <em>
        "Hey! Just a quick note to let you know my rates will be increasing by
        $5 in two weeks. But don't worry, any lessons booked before then will
        still be at the current price. Happy to chat more at our next lesson!"
      </em>
    </Callout>
    <p>
      If you haven't done so yet, you should{' '}
      <a
        href="https://blog.propelhq.com/changing-your-private-lesson-prices"
        target="_blank"
      >
        read our blog post
      </a>{' '}
      on the topic of raising your price.
    </p>
    <Controls>
      <Button color="primary" variant="flat" onClick={onClose}>
        I Understand
      </Button>
    </Controls>
  </div>
);

const InstructorFacilitySettings = ({ username }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const instructor = useSelector(getInstructorDetails);
  const [showAddLocationHelp, setShowAddLocationHelp] = useState(false);
  const [apptProdToRemove, setApptProdToRemove] =
    useState<AppointmentProduct>();
  const [isRemovingApptProd, setIsRemovingApptProd] = useState(false);
  const [showEditPriceHelp, setShowEditPriceHelp] = useState(false);
  const [addAppointmentProduct, setAddingAppointmentProduct] = useState(false);
  const [editAppointmentProduct, setEditingAppointmentProduct] =
    useState<AppointmentProduct>(undefined);

  const [currentAppointmentProducts, setAppointmentProducts] = useState<
    AppointmentProduct[]
  >([]);
  const [currentPrices, setPrices] = useState<Price[]>([]);
  const [currentServices, setServices] = useState<PropelServiceSerializer[]>(
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    return Promise.all([
      api.appointmentProducts.list({ username }),
      api.prices.list({ username }),
      api.services.list(),
    ]).then((responses) => {
      setAppointmentProducts(responses[0].data);
      setPrices(responses[1].data);
      setServices(responses[2].data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    dispatch(fetchInstructorDetail(username));
    fetchData();
  }, []);

  const handleEditPrice = (appointmentProduct: AppointmentProduct) => {
    if (!!appointmentProduct.price) {
      if (LocalStore.getPreference('showEditPriceHelp', true)) {
        setShowEditPriceHelp(true);
      }
    }
    setEditingAppointmentProduct(appointmentProduct);
    setAddingAppointmentProduct(false);
  };

  const handleSelectNewFacility = async (
    facility: FacilitySchedulableSerializer
  ) => {
    const newAptProd = await dispatch(
      createAppointmentProduct(facility.id, instructor.id)
    );
    if (newAptProd) {
      try {
        await fetchData();
      } catch (err) {}
      handleEditPrice(newAptProd);
    } else {
      handleCloseModal();
    }
  };

  const handleAddFacilityClick = () => {
    if (LocalStore.getPreference('showAddLocationHelp', true)) {
      setShowAddLocationHelp(true);
    } else {
      setAddingAppointmentProduct(true);
    }
  };

  const handleCloseModal = () => {
    setAddingAppointmentProduct(false);
    setEditingAppointmentProduct(undefined);
  };

  const handleSaveNewPrice = async (price: number, startDate: string) => {
    setIsLoading(true);
    try {
      const data = {
        price,
        startDate,
        appointmentProductId: editAppointmentProduct.id,
      };
      await api.prices.create(data);
      handleCloseModal();
      if (editAppointmentProduct.status === AppointmentProductStatus.Pending) {
        dispatch(fetchAppointmentProducts(true));
      }
      await fetchData();
    } catch (err) {
      logger.error(err as AxiosError);
      enqueueSnackbar({
        message: 'There was a problem saving that price, please try again.',
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleCloseModalHelp = () => {
    if (showAddLocationHelp) {
      LocalStore.setPreference('showAddLocationHelp', false);
      setShowAddLocationHelp(false);
      setAddingAppointmentProduct(true);
    } else if (showEditPriceHelp) {
      LocalStore.setPreference('showEditPriceHelp', false);
      setShowEditPriceHelp(false);
    }
  };

  const handleDeletePrice = async (priceId: number) => {
    try {
      setIsLoading(true);
      await api.prices.delete(priceId);
      await fetchData();
    } catch (error) {
      logger.error(error);
      enqueueSnackbar({
        message: 'Could not remove price',
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const liveLocations = currentAppointmentProducts.filter(
    (appointmentProduct) =>
      [
        AppointmentProductStatus.Active,
        AppointmentProductStatus.Pending,
      ].includes(appointmentProduct.status)
  );
  const closedLocations = currentAppointmentProducts.filter(
    (aptProd) => aptProd.facility.status === FacilityStatus.Closed
  );

  return (
    <>
      {isLoading && <Loading />}
      <BookingFeeChange services={currentServices} />
      <div className="py-4">
        <div className="flex items-center justify-between">
          <h5>Current Locations</h5>
          <button
            className="btn gray"
            onClick={handleAddFacilityClick}
            disabled={isLoading}
          >
            Add New Location
          </button>
        </div>
        <div className="my-4 space-y-4">
          {!isLoading && liveLocations.length === 0 ? (
            <Callout title="No Active Locations" type="warning">
              {currentAppointmentProducts.length > 0 ? (
                <span>
                  None of your locations are currently active. Add a new
                  location to relist on Propel!
                </span>
              ) : (
                <span>
                  You don't have any locations added. Add a new location to
                  relist on Propel!
                </span>
              )}
            </Callout>
          ) : (
            liveLocations.map((appointmentProduct) => {
              const prices = currentPrices.filter(
                (price) => price.appointmentProductId === appointmentProduct.id
              );
              const facility = appointmentProduct.facility;
              const price1 = prices[0];
              const price2 = prices[1];
              const canAddPrice = !!price1 && !price2;
              const noPrice = !price1;
              return (
                <div
                  key={appointmentProduct.id}
                  className="py-4 border-t border-gray-300"
                >
                  <div className="flex items-center mb-2 space-x-2">
                    <Avatar src={facility.avatar} diameter={6} border={true} />
                    <h3 className="flex-1 text-md">{facility.shortName}</h3>
                  </div>
                  <AdmissionFeeChange appointmentProduct={appointmentProduct} />
                  <div className="space-y-2 text-sm">
                    {noPrice ? (
                      <div className="flex items-center justify-between space-x-2">
                        <div className="flex-1">No price</div>
                        <div className="flex gap-3">
                          <button
                            className="btn gray"
                            onClick={() =>
                              setApptProdToRemove(appointmentProduct)
                            }
                          >
                            Remove facility
                          </button>
                          <button
                            className="btn gray"
                            onClick={() => handleEditPrice(appointmentProduct)}
                          >
                            Add price
                          </button>
                        </div>
                      </div>
                    ) : canAddPrice ? (
                      <>
                        <div className="flex items-center justify-between space-x-2">
                          <div className="flex-1">
                            Price since{' '}
                            {moment(price1.startDate).format(
                              DATE_FMT.MON_D_YEAR
                            )}
                          </div>
                          <div className="font-semibold">
                            {price1.price.toCurrency()}
                          </div>
                        </div>
                        <div className="flex gap-3 text-right">
                          <button
                            className="btn gray"
                            onClick={() =>
                              setApptProdToRemove(appointmentProduct)
                            }
                          >
                            Remove facility
                          </button>
                          <button
                            className="btn gray"
                            onClick={() => handleEditPrice(appointmentProduct)}
                          >
                            Change price
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center justify-between space-x-2">
                          <div className="flex-1">
                            Price since{' '}
                            {moment(price1.startDate).format(
                              DATE_FMT.MON_D_YEAR
                            )}
                          </div>
                          <div className="font-semibold">
                            {price1.price.toCurrency()}
                          </div>
                        </div>
                        <div className="flex items-center justify-between space-x-2">
                          <div className="flex-1">
                            Price starting on{' '}
                            {moment(price2.startDate).format(
                              DATE_FMT.MON_D_YEAR
                            )}
                          </div>
                          <div className="font-semibold">
                            {price2.price.toCurrency()}
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            className="btn gray"
                            onClick={() => handleDeletePrice(price2.id)}
                          >
                            Remove price change
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <div className="border-b border-gray-300" />
          {closedLocations.length > 0 ? (
            <div>
              <h5>Closed locations</h5>
              {closedLocations.map((appointmentProduct) => {
                return (
                  <div
                    key={appointmentProduct.id}
                    className="flex justify-between py-2 text-gray-700"
                  >
                    <div className="flex items-center mb-2 space-x-2">
                      <Avatar
                        src={appointmentProduct.facility.avatar}
                        diameter={6}
                        border={true}
                      />
                      <div className="flex-1 text-md">
                        {appointmentProduct.facility.shortName}
                      </div>
                    </div>
                    <div className="space-y-2 text-sm italic">Closed</div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        name="Remove facility"
        title="Remove facility"
        open={apptProdToRemove !== undefined}
        onClose={() => setApptProdToRemove(undefined)}
        maxWidth="xs"
        fullWidth
      >
        <p>
          Are you sure you want to remove{' '}
          {apptProdToRemove?.facility.displayName} from your locations?
        </p>
        <p>
          <i>
            Note: You must cancel all future availability, proposals, and
            appointments before removing a facility.
          </i>
        </p>
        <Controls>
          <Button
            disabled={isRemovingApptProd}
            variant="flat"
            onClick={() => setApptProdToRemove(undefined)}
          >
            Cancel
          </Button>
          <Button
            variant="flat"
            color="secondary"
            isLoading={isRemovingApptProd}
            onClick={async () => {
              setIsRemovingApptProd(true);
              try {
                await dispatch(deleteAppointmentProduct(apptProdToRemove));
                await fetchData();
                dispatch(fetchAppointmentProducts());
              } finally {
                setIsRemovingApptProd(false);
                setApptProdToRemove(undefined);
              }
            }}
          >
            Remove
          </Button>
        </Controls>
      </Modal>
      <Modal
        name="Instructor — Help Adding New Locations"
        open={showAddLocationHelp}
        onClose={handleCloseModalHelp}
        title="Adding New Locations"
        maxWidth="sm"
      >
        <AddLocationHelp onClose={handleCloseModalHelp} />
      </Modal>
      <Modal
        name="Instructor — Help Changing Your Price"
        open={showEditPriceHelp}
        onClose={handleCloseModalHelp}
        title="Changing Your Price"
        maxWidth="sm"
      >
        <EditPriceHelp onClose={handleCloseModalHelp} />
      </Modal>
      <Modal
        name="Instructor — Select New Location"
        open={addAppointmentProduct}
        fullScreen={true}
        variant="borderless"
      >
        <SelectNewFacility
          onSelect={handleSelectNewFacility}
          onGoBack={handleCloseModal}
          appointmentProducts={currentAppointmentProducts}
        />
      </Modal>
      <Modal
        name="Instructor — Select New Price"
        open={!!editAppointmentProduct && !showEditPriceHelp}
      >
        {!!editAppointmentProduct && !showEditPriceHelp && (
          <SetNewPrice
            isLoading={isLoading}
            prices={currentPrices.filter(
              (price) =>
                price.appointmentProductId === editAppointmentProduct?.id
            )}
            services={currentServices}
            onClose={handleCloseModal}
            onSave={handleSaveNewPrice}
            onDelete={handleDeletePrice}
            appointmentProduct={editAppointmentProduct}
          />
        )}
      </Modal>
    </>
  );
};

export default InstructorFacilitySettings;

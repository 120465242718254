import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import api from 'api';
import { HTTP_404_NOT_FOUND } from 'api/status';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import PasswordRequirementHint from 'components/input-password/password-requirement-hint';
import Link from 'components/link';
import Redirect from 'components/redirect';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, SubmitState } from 'config';
import Page from 'containers/page';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import { GenericServerError } from 'lang/en/Snackbars';
import { PasswordResetConfirmMeta as Meta } from 'metadata';
import { RouteParams } from 'models/route';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { resetAuthState } from 'state/slice/authentication';
import { APP_ROUTES } from 'utils/routing';

type SnackbarType = 'default' | 'success' | 'error';
interface SnackbarData {
  message: string;
  type: SnackbarType;
}

enum ErrorCodes {
  InvalidUrl = 1,
  Mismatched = 2,
  TooShort = 3,
  NeedsComplexity = 4,
  Insecure = 5,
}

const Errors: Error[] = [
  {
    code: ErrorCodes.InvalidUrl,
    message:
      'Invalid or expired password reset URL; submit a new password reset request.',
    type: 'error',
  },
  {
    code: ErrorCodes.Mismatched,
    message: 'Passwords do not match.',
    type: 'error',
  },
  {
    code: ErrorCodes.TooShort,
    message:
      'Passwords must be at least 8 characters long, and contain letters and symbols/digits.',
    type: 'error',
  },
  {
    code: ErrorCodes.NeedsComplexity,
    message: 'Passwords must also contain a number or special character.',
    type: 'error',
  },
  {
    code: ErrorCodes.Insecure,
    message:
      "Hmm, that password isn't very secure. Add some complexity to keep your account secure!",
    type: 'error',
  },
];

interface Error extends SnackbarData {
  code: ErrorCodes;
}

const PasswordResetSuccessful = () => (
  <div className="px-4 pt-12 pb-16 mb-4 space-y-8 bg-white rounded-lg shadow-lg sm:px-12">
    <div>
      <h2 className="mt-6 text-xl font-bold text-left text-gray-800">
        You've successfully changed your password
      </h2>
      <Link
        to={APP_ROUTES.LOGIN}
        className="font-medium text-blue-600 hover:text-blue-500"
        tabIndex={5}
      >
        Continue to sign in
      </Link>
    </div>
  </div>
);

const TIMEOUT_REDIRECT_MS = 15 * 60 * 1000;

const PasswordReset = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showHint, setShowHint] = useState(false);
  const [state, setState] = useState<SubmitState>(SubmitState.UNSUBMITTED);
  const [error, setError] = useState<Error>();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [hasTriedInsecurePwd, setTriedInsecurePwd] = useState(false);
  const dispatch = useAppDispatch();
  const match = useRouteMatch<RouteParams>();

  const getError = (code: ErrorCodes) => Errors.find((e) => e.code === code);

  const verifyToken = async () => {
    try {
      const response = await api.auth.verifyResetToken(match.params.uid);
      handleInputChange({
        target: { name: 'email', value: response.data.email },
      });
    } catch (error) {
      enqueueSnackbar(getError(ErrorCodes.InvalidUrl));
      setError(getError(ErrorCodes.InvalidUrl));
    }
  };

  const handlePasswordSubmit = async () => {
    setShowHint(false);
    setState(SubmitState.IN_TRANSIT);
    try {
      await api.auth.changePassword(
        { password: inputs.password },
        match.params.uid
      );
      setState(SubmitState.SUBMITTED);
    } catch (error: any) {
      setState(SubmitState.ERROR);
      if (!error.response) {
        enqueueSnackbar(GenericServerError);
      } else if (error.response.status === HTTP_404_NOT_FOUND) {
        setError(getError(ErrorCodes.InvalidUrl));
      } else {
        const code = Number(error.response.data.password.code);
        setTriedInsecurePwd(code === ErrorCodes.Insecure);
        setError(getError(code));
      }
    }
  };

  const handleFocus = () => {
    setShowHint(true);
  };

  const onSubmit = () => {
    setError(undefined);
    if (inputs.password.length < 8) {
      setError(getError(ErrorCodes.TooShort));
    } else if (inputs.password !== inputs.confirm) {
      setError(getError(ErrorCodes.Mismatched));
    } else if (inputs.password.search(/[^A-Za-z]/) === -1) {
      setError(getError(ErrorCodes.NeedsComplexity));
    } else {
      handlePasswordSubmit();
    }
  };

  const handleBlur = (event) => {
    setShowHint(false);
  };

  const initialData = {
    password: '',
    confirm: '',
    email: '',
  };
  const { inputs, handleInputChange, handleSubmit } = useForm(
    initialData,
    onSubmit
  );

  useMemo(() => {
    dispatch(resetAuthState());
    verifyToken();
    setTimeout(
      () => setError(getError(ErrorCodes.InvalidUrl)),
      TIMEOUT_REDIRECT_MS
    );
  }, []);

  if (
    !match ||
    !match.params.uid ||
    (error && error.code === ErrorCodes.InvalidUrl)
  ) {
    return <Redirect to={APP_ROUTES.REQUEST_NEW_PASSWORD} push={true} />;
  }

  return (
    <Page {...Meta}>
      <div className="flex justify-center px-4 py-12 bg-background min-h-90vh sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          {state === SubmitState.SUBMITTED ? (
            <PasswordResetSuccessful />
          ) : (
            <Card height={3} maxWidth="md">
              <div className="my-6">
                <h2 className="text-xl font-bold text-left text-gray-800">
                  Reset your password
                </h2>
                <p>Enter a new password for your Propel account.</p>
              </div>
              <div>
                <PasswordRequirementHint
                  isVisible={showHint}
                  password={inputs.password}
                />
              </div>
              <form className="mt-8" onSubmit={handleSubmit}>
                <input
                  id="username"
                  name="username"
                  type="text"
                  className="hidden"
                  autoComplete="username"
                  value={inputs.email}
                  readOnly={true}
                />
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6">
                    <label htmlFor="password">New Password</label>
                    <div className="relative">
                      <span
                        onClick={() => setShowPassword1(!showPassword1)}
                        className="absolute top-0 right-0 flex items-center justify-center w-12 h-12 leading-none text-gray-500 cursor-pointer hover:text-blue-500"
                      >
                        {showPassword1 ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </span>
                      <input
                        id="password"
                        name="password"
                        type={showPassword1 ? 'text' : 'password'}
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        autoComplete="new-password"
                        minLength={MIN_PASSWORD_LENGTH}
                        maxLength={MAX_PASSWORD_LENGTH}
                        disabled={state === SubmitState.IN_TRANSIT}
                        placeholder="New Password"
                        required
                        className={`pr-12 ${!!error ? 'input-error' : ''}`}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={inputs.password}
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="confirm">Confirm</label>
                    <div className="relative">
                      <span
                        onClick={() => setShowPassword2(!showPassword2)}
                        className="absolute top-0 right-0 flex items-center justify-center w-12 h-12 leading-none text-gray-500 cursor-pointer hover:text-blue-500"
                      >
                        {showPassword2 ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </span>
                      <input
                        id="confirm"
                        name="confirm"
                        type={showPassword2 ? 'text' : 'password'}
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                        disabled={state === SubmitState.IN_TRANSIT}
                        minLength={MIN_PASSWORD_LENGTH}
                        maxLength={MAX_PASSWORD_LENGTH}
                        required
                        className={`pr-12 ${!!error ? 'input-error' : ''}`}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={inputs.confirm}
                      />
                    </div>
                    {!!error && error.message && (
                      <div className="mt-1">
                        <Callout type="error">{error.message}</Callout>
                      </div>
                    )}
                  </div>
                  <div className="col-span-6 text-sm">
                    <Button
                      type="submit"
                      disabled={state === SubmitState.IN_TRANSIT}
                      variant="contained"
                      color="primary"
                      className={
                        state === SubmitState.IN_TRANSIT ? 'animate-pulse' : ''
                      }
                      fullWidth={true}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </form>
            </Card>
          )}
        </div>
      </div>
    </Page>
  );
};

export default PasswordReset;

import { PropelAccount } from 'api/Serializers/Accounts';
import { Event } from 'api/Serializers/Appointments';
import Callout from 'components/callout';
import Modal from 'components/modal';
import Slider from 'components/slider';
import { DATE_FMT, UserType } from 'config';
import { Report } from 'models/Assistance';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAccountDetail } from 'state/selectors';
import { getReportResults } from '../utils';

const Data = ({ title, children }) => (
  <div>
    <div className="m-0 text-sm font-bold text-gray-700">{title}</div>
    {children}
  </div>
);

const Section = ({ title, children, hr = true }) => (
  <div>
    {hr && <hr />}
    <h4 className="mb-2">{title}</h4>
    <div className="space-y-3">{children}</div>
  </div>
);

const ResultSection = ({
  user,
  report,
}: {
  user: PropelAccount;
  report: Report;
}) => {
  const { result } = report;
  const { type } = user;
  const results = getReportResults(result);
  const category = report.category;

  if (!results) {
    return (
      <Callout className="mt-2" type="info" title="Results not available..." />
    );
  }

  if (type === UserType.Client) {
    return (
      <Section title="Results">
        {category === 'REPORT' ? (
          <Callout type="success" title="Continue your lesson" />
        ) : category === 'MODIFY' ? (
          <Callout type="info" title="Modify booking">
            This booking will be modified
          </Callout>
        ) : category === 'CANCELLATION' ? (
          <>
            <Callout type="error" title="Cancel this lesson" />
            {results.isClientRefunded ? (
              <Callout
                type="success"
                title="Your payment will be automatically refunded to the original payment method"
              />
            ) : (
              <Callout type="error" title="Your payment will not be returned" />
            )}
          </>
        ) : null}
      </Section>
    );
  } else if (type === UserType.Host) {
    return (
      <Section title="Results">
        {result === 'NO_CHANGE' ? (
          <Callout type="success" title="Lesson will continue" />
        ) : (
          <>
            {results.isAppointmentCancelled ? (
              <Callout type="error" title="Cancel this lesson" />
            ) : (
              <Callout type="success" title="Lesson will continue" />
            )}
            {results.isClientRefunded ? (
              <Callout
                type="success"
                title="The client's payment will be automatically refunded to the original payment method"
              />
            ) : (
              <Callout
                type="error"
                title="The client's payment will not be returned"
              />
            )}
            {results.isHostPaid ? (
              <Callout
                type="success"
                title="This lesson will be paid in full"
              />
            ) : (
              <Callout type="error" title="This lesson is not paid out" />
            )}
          </>
        )}
      </Section>
    );
  } else if (type === UserType.Admin) {
    return (
      <Section title="Results">
        {result === 'NO_CHANGE' ? (
          <Callout type="success" title="Lesson will continue" />
        ) : (
          <>
            {results.isAppointmentCancelled ? (
              <Callout type="error" title="Cancel this lesson" />
            ) : (
              <Callout type="success" title="Lesson will continue" />
            )}
            {results.isClientRefunded ? (
              <Callout
                type="success"
                title="The client's payment will be automatically refunded to the original payment method"
              />
            ) : (
              <Callout
                type="error"
                title="The client's payment will not be returned"
              />
            )}
            {results.isInstructorPaid ? (
              <Callout
                type="success"
                title={
                  results.isClientRefunded
                    ? 'Instructor will receive payment from Propel as an equivalent bonus payment'
                    : 'Instructor will receive payment from the client'
                }
              />
            ) : (
              <Callout
                type="error"
                title="Instructor will not receive payment"
              />
            )}
          </>
        )}
      </Section>
    );
  } else if (type === UserType.Instructor) {
    return (
      <Section title="Results">
        {result === 'NO_CHANGE' ? (
          <Callout type="success" title="Continue your lesson" />
        ) : (
          <>
            {results.isAppointmentCancelled ? (
              <Callout type="error" title="Cancel this lesson" />
            ) : (
              <Callout type="success" title="Continue your lesson" />
            )}
            {results.isClientRefunded ? (
              <Callout
                type="success"
                title="The client's payment will be automatically refunded to the original payment method"
              />
            ) : (
              <Callout
                type="error"
                title="The client's payment will not be returned"
              />
            )}
            {results.isInstructorPaid ? (
              <Callout
                type="success"
                title={
                  results.isClientRefunded
                    ? 'You will receive payment from Propel as an equivalent bonus payment'
                    : 'You will receive payment from your client'
                }
              />
            ) : (
              <Callout type="error" title="You will not receive payment" />
            )}
          </>
        )}
      </Section>
    );
  } else {
    return null;
  }
};

const ReportSummary = ({
  report,
  appointment,
}: {
  report: Report;
  appointment: Event;
}) => {
  const user = useSelector(getAccountDetail);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageClick = () => {
    setModalOpen(true);
  };
  return (
    <>
      <div className="my-4">
        <Section title="Report" hr={false}>
          <Data title="Submitted by">
            {user.fullName}
            {' on '}
            {moment(report.created).format(DATE_FMT.MONTH_D_TIME_A)}
          </Data>
        </Section>
        <Section title="Details">
          <>
            {report.actions &&
              report.actions
                .filter((data) => data.description !== undefined)
                .map((data, i) => (
                  <Data key={`n${i}`} title={data.title}>
                    <span className="break-words whitespace-pre-wrap">
                      {data.description}
                    </span>
                  </Data>
                ))}
            {report.images && report.images.length > 0 && (
              <Data title="Images">
                <div className="grid grid-cols-2 gap-4">
                  {report.images.map(({ id, url, ...rest }, index) => (
                    <div
                      key={`img-${index}`}
                      className="w-40 h-40"
                      onClick={handleImageClick}
                    >
                      <div className="relative w-full pt-40 overflow-hidden rounded-lg">
                        <img
                          src={url}
                          className="absolute z-10 block object-cover w-full min-w-full min-h-full -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </Data>
            )}
          </>
        </Section>
        <ResultSection user={user} report={report} />
        <Modal
          name="Report summary images"
          open={isModalOpen}
          maxWidth="xl"
          onClose={() => setModalOpen(false)}
        >
          <div className="w-full max-w-5xl min-w-xs">
            <Slider>
              {report.images.map((image, i) => {
                return <img key={image.id} src={image.url} className="" />;
              })}
            </Slider>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReportSummary;

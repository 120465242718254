import { TimeFrame } from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Redirect from 'components/redirect';
import { FETCH_STATE } from 'config';
import ReportSummary from 'features/assistance/components/report-summary';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AssistanceWizardProps } from 'models/Assistance';
import React, { useState } from 'react';
import { fetchAppointment } from 'state/slice/appointments';
import { submitReport } from 'state/slice/assistance';
import { SHARED_ROUTES } from 'utils/routing';

const AssistanceSummary = (props: AssistanceWizardProps) => {
  const [isComplete, setComplete] = useState(false);
  const [submitState, setSubmitState] = useState(FETCH_STATE.IDLE);
  const { appointment, state } = props;
  const { cancelled, timeFrame } = appointment;
  const dispatch = useAppDispatch();
  const onSuccess = () => {
    props.trackAction({
      type: 'EXIT',
      key: 'state',
      value: FETCH_STATE.FULFILLED,
    });
    setComplete(true);
  };
  const onError = () => setSubmitState(FETCH_STATE.FAILED);
  const handleSubmit = async () => {
    setSubmitState(FETCH_STATE.POST);
    dispatch(submitReport(onSuccess, onError));
  };

  const canSubmit = !cancelled && timeFrame < TimeFrame.Locked;

  if (isComplete) {
    dispatch(fetchAppointment(appointment.id));
    return <Redirect to={SHARED_ROUTES.SCHEDULE.appointment(appointment.id)} />;
  }

  return (
    <>
      <h2 className="mb-1">Review & Submit</h2>
      {canSubmit ? (
        <Callout
          type="question"
          title="Here's the final report. Ready to submit?"
        />
      ) : (
        <Callout
          type="error"
          title="Unable to submit reports for this lesson"
        />
      )}
      <ReportSummary report={state} appointment={appointment} />
      {/* <p className="italic font-light text-center text-gray-600">
        A copy of this report will be sent to your email
      </p> */}
      <Controls variant="block">
        <BackButton />
        <Button
          isLoading={submitState === FETCH_STATE.POST}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!canSubmit}
        >
          Submit
        </Button>
      </Controls>
    </>
  );
};

export default AssistanceSummary;

import CreditIcon from '@mui/icons-material/AttachMoney';
import NoCreditIcon from '@mui/icons-material/MoneyOff';
import Redeem from '@mui/icons-material/Redeem';
import {
  CancelSerializer,
  Event,
  TimeFrame,
} from 'api/Serializers/Appointments';
import Button from 'components/button';
import BackButton from 'components/button-back';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  FETCH_STATE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_MAX,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX,
  MIN_CANCEL_NOTE_LENGTH,
  UserType,
} from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ArrowForwardIcon } from 'icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAccountReliability,
  getAccountReliabilityFetchState,
  getUser,
} from 'state/selectors';
import { fetchReliability } from 'state/slice/account';
import { cancelAppointment } from 'state/slice/appointments';
import { SHARED_ROUTES } from 'utils/routing';

type Reason =
  | 'CONFLICT'
  | 'RESCHEDULE'
  | 'TRANSPORT'
  | 'PERSONAL'
  | 'FAMILY'
  | 'CLIENT_FIT'
  | 'CLIENT_ASK'
  | 'FACILITY_ISSUE';
const Index = ({
  appointment,
  onClickNext,
  onClickBack,
}: {
  appointment: Event;
  onClickNext: (reason: string) => void;
  onClickBack: () => void;
}) => {
  const [reason, setReason] = useState<Reason>();
  const handleSetReason = (event) => {
    setReason(event.currentTarget.value);
  };
  return (
    <div className="space-y-4">
      {/* {appointment.timeFrame > TimeFrame.HasStarted ? (
        <Callout title="Warning" type="error">
          
        </Callout>
      ) : appointment.timeFrame > TimeFrame.Outside48 ? (
        <Callout title="Warning" type="error">
          Cancelling within 48 hours of a lesson is very disrupting to your
          clients, and should be done only in rare circumstances.
        </Callout>
      ) : null} */}
      <h4>
        {appointment.timeFrame < TimeFrame.HasStarted
          ? "Please tell us why you're cancelling"
          : "Please tells us why you didn't make it"}
      </h4>
      <p>Your selection is not shared with your client.</p>
      <div className="my-8 space-y-3">
        <ButtonLarge
          value="CONFLICT"
          selected={reason === 'CONFLICT'}
          variant="check"
          title="Scheduling conflict"
          onClick={handleSetReason}
        />
        <ButtonLarge
          value="TRANSPORT"
          selected={reason === 'TRANSPORT'}
          variant="check"
          title="Trouble with transportation"
          onClick={handleSetReason}
        />
        <ButtonLarge
          value="PERSONAL"
          selected={reason === 'PERSONAL'}
          variant="check"
          title="Illness, injury, or mental health issue"
          onClick={handleSetReason}
        />
        <ButtonLarge
          value="FAMILY"
          selected={reason === 'FAMILY'}
          variant="check"
          title="Family emergency"
          onClick={handleSetReason}
        />
        <ButtonLarge
          value="CLIENT_FIT"
          selected={reason === 'CLIENT_FIT'}
          variant="check"
          title="Client is not a good fit"
          onClick={handleSetReason}
        />
        <ButtonLarge
          value="CLIENT_ASK"
          selected={reason === 'CLIENT_ASK'}
          variant="check"
          title="Client asked to cancel or reschedule"
          onClick={handleSetReason}
        />
        {appointment.timeFrame >= TimeFrame.SameDay && (
          <ButtonLarge
            value="FACILITY_ISSUE"
            selected={reason === 'FACILITY_ISSUE'}
            variant="check"
            title={`${appointment.activity.facilityDescription} not available or is unsuitable`}
            onClick={handleSetReason}
          />
        )}
      </div>
      <Controls variant="block">
        <Button
          color="primary"
          variant="flat"
          onClick={() => onClickNext(reason)}
          disabled={!reason}
        >
          Continue
        </Button>
      </Controls>
    </div>
  );
};

const CancellationRateNotice = ({
  appointment,
  onClickNext,
  onClickBack,
}: {
  appointment: Event;
  onClickNext: () => void;
  onClickBack: () => void;
}) => {
  const reliability = useSelector(getAccountReliability);
  const fetchState = useSelector(getAccountReliabilityFetchState);
  if (fetchState === FETCH_STATE.GET) {
    return (
      <div>
        <Loading position="inline-contained" />{' '}
      </div>
    );
  }

  if (appointment.timeFrame === TimeFrame.Outside48) {
    const currentCancellations = reliability.recentCancellations;
    const currentAppointments = reliability.recentAppointments;
    const currentRateTxt =
      currentCancellations > 0
        ? `${currentCancellations} ${'cancellation'.pluralize(
            currentCancellations
          )} out of ${currentAppointments} ${'booking'.pluralize(
            currentAppointments
          )}*`
        : '0 cancellations* — great work!';

    const expectedCancellations = currentCancellations + 1;
    const expectedAppointments = currentAppointments + 1;

    const expectedRateTxt = `${expectedCancellations} ${'cancellation'.pluralize(
      expectedCancellations
    )} out of ${expectedAppointments} ${'booking'.pluralize(
      expectedAppointments
    )}*`;

    return (
      <>
        <h2 className="mb-2">Overall cancellation rate</h2>
        <div className="space-y-5">
          <p>Here's how your overall cancellation rate will change:</p>
          <div>
            <div className="flex">
              <div className="w-1/2 pr-3">
                <div className="h-full border border-gray-800 rounded-xl">
                  <div className="px-4 py-5">
                    <div className="text-xl font-semibold text-gray-900">
                      {(reliability.recentCancellationRate * 100).toFixed(1)}%
                    </div>
                    <div className="text-gray-600 ">{currentRateTxt}</div>
                  </div>
                </div>
              </div>
              <div className="absolute z-10 flex items-center justify-center w-12 h-12 center-xy">
                <ArrowForwardIcon width={24} />
              </div>
              <div className="w-1/2 pl-3">
                <div className="h-full border border-gray-800 rounded-xl">
                  <div className="px-4 py-5">
                    <div className="text-xl font-semibold text-gray-900">
                      {(
                        reliability.projectedRecentCancellationRate * 100
                      ).toFixed(1)}
                      %
                    </div>
                    <div className="text-gray-600 ">{expectedRateTxt}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-xs text-center">
            *Cancelled or completed by you in the last 60 days
          </p>
          {reliability.recentCancellationRate <=
            INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX &&
            reliability.projectedRecentCancellationRate >
              INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX && (
              <Callout type="warning" title="High cancellation rate warning">
                This cancellation will cause your rate to exceed{' '}
                {(INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX * 100).toFixed(1)}%,
                the High Cancellation limit. Profiles with a cancellation rate
                higher than this may not be recommended and won't show on pool
                pages.
              </Callout>
            )}
        </div>
        <Controls variant="block">
          <BackButton onClick={onClickBack}>Go back</BackButton>
          <Button color="primary" variant="flat" onClick={onClickNext}>
            Continue
          </Button>
        </Controls>
      </>
    );
  } else {
    const currentCancellations = reliability?.lateCancellations ?? 0;
    const currentAppointments = reliability?.recentAppointments ?? 0;
    const currentRateTxt =
      currentCancellations > 0
        ? `${currentCancellations} ${'cancellation'.pluralize(
            currentCancellations
          )} out of ${currentAppointments} ${'booking'.pluralize(
            currentAppointments
          )}*`
        : '0 cancellations* — great work!';

    const expectedCancellations = currentCancellations + 1;
    const expectedAppointments = currentAppointments + 1;

    const expectedRateTxt = `${expectedCancellations} ${'cancellation'.pluralize(
      expectedCancellations
    )} out of ${expectedAppointments} ${'booking'.pluralize(
      expectedAppointments
    )}*`;

    return (
      <>
        <h2 className="mb-2">Late cancellations</h2>
        <div className="space-y-5">
          <div className="">
            <p>
              Just like instructors, hosts require payment for cancellations
              made within 48 hours. When cancelling late, you are responsible
              for paying admission fees.
            </p>
            <p>
              If you maintain a Late Cancellation Rate of{' '}
              {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}% or
              less, Propel will fully reimburse you these costs.
            </p>
            <p>
              Here's how your <strong>Late Cancellation Rate</strong> will
              change:
            </p>
          </div>
          <div className="flex items-start justify-center w-full h-full">
            <div className="w-1/2 h-full pr-4">
              <div className="border border-gray-800 rounded-xl">
                <div className="flex flex-col justify-center h-full px-4 py-5">
                  <span className="text-xs font-semibold text-gray-600">
                    Current rate
                  </span>
                  <div className="text-3xl font-bold text-gray-900">
                    {(reliability.lateCancellationRate * 100).toFixed(1)}%
                  </div>
                  <div className="text-sm text-gray-700">{currentRateTxt}</div>
                </div>
              </div>
            </div>
            <div className="absolute center-xy">
              <ArrowForwardIcon width={24} />
            </div>
            <div className="w-1/2 h-full pl-4">
              <div className="border border-gray-800 rounded-xl">
                <div className="flex flex-col justify-center h-full px-4 py-5">
                  <span className="text-xs font-semibold text-gray-600">
                    Resulting rate
                  </span>
                  <span className="text-3xl font-bold text-gray-900">
                    {(reliability.projectedLateCancellationRate * 100).toFixed(
                      1
                    )}
                    %
                  </span>
                  <span className="text-sm text-gray-700">
                    {expectedRateTxt}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <p className="text-xs text-center">
            *Cancelled or completed by you in the last 60 days
          </p>
          {reliability.projectedLateCancellationRate >
          INSTRUCTOR_LATE_CANCELLATION_RATE_MAX ? (
            reliability.lateCancellationRate <=
            INSTRUCTOR_LATE_CANCELLATION_RATE_MAX ? (
              /**
               * Instructor is currently below the max, but this will push them above!
               */
              <>
                <p>
                  Your rate will exceed{' '}
                  {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
                  after this cancellation. Propel will not reimburse the
                  admission fee.
                </p>
              </>
            ) : (
              /**
               * Instructor is currently above the max threshold
               */
              <>
                <p>
                  Your rate exceeds{' '}
                  {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}
                  %. Propel will not reimburse the admission fee.
                </p>
              </>
            )
          ) : (
            /**
             * Instructor will stay below max threshold
             */
            <>
              <Callout type="success" title="Great job keeping your rate low!">
                Your Late Cancellation Rate will not be higher than{' '}
                {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%, so
                Propel will reimburse the admission fee for this late
                cancellation.
              </Callout>
            </>
          )}
        </div>
        <Controls variant="block" className="my-4">
          <BackButton onClick={onClickBack}>Go back</BackButton>
          <Button color="primary" variant="flat" onClick={onClickNext}>
            Continue
          </Button>
        </Controls>
      </>
    );
  }
};

const Note = ({ appointment, note, setNote, onClickNext, onClickBack }) => {
  const canContinue = note.length >= MIN_CANCEL_NOTE_LENGTH;
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <p>
        Your client will receive email and SMS notifications that you are unable
        to attend this lesson, along with your note below:
      </p>
      <Callout className="my-8" type="info" title="Payment returned to client">
        Your client's payment will be automatically refunded to the original
        payment method.
      </Callout>
      <div className="my-8 space-y-6">
        <div>
          <label htmlFor="noteToClient">{`To: ${appointment.client.fullName}`}</label>
          <textarea
            id="noteToClient"
            name="noteToClient"
            placeholder="I'm sorry to cancel your booking..."
            defaultValue={note}
            maxLength={1000}
            onChange={(evt) => setNote(evt.target.value)}
            rows={3}
          />
          {note !== '' && note.length < MIN_CANCEL_NOTE_LENGTH && (
            <div className="mb-2 text-sm text-red-400">
              Write at least {MIN_CANCEL_NOTE_LENGTH - note.length} more{' '}
              {'character'.pluralize(MIN_CANCEL_NOTE_LENGTH - note.length)}
            </div>
          )}
        </div>
      </div>
      <Controls variant="block">
        <BackButton onClick={onClickBack} />
        <Button
          disabled={!canContinue}
          variant="contained"
          color="primary"
          onClick={onClickNext}
        >
          Continue
        </Button>
      </Controls>
    </>
  );
};

const Credit = ({
  appointment,
  credit,
  setCredit,
  onClickNext,
  onClickBack,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleCredit = (value: number) => (event) => {
    setCredit(value);
    onClickNext();
  };
  return (
    <>
      <h2>Cancellation Assistance</h2>
      <Callout className="my-8" type="question">
        <div>Would you like to provide additional compensation?</div>
        <div className="text-right">
          <span className="link" onClick={handleModalOpen}>
            Learn More
          </span>
        </div>
      </Callout>
      <div className="my-8 space-y-3">
        <ButtonLarge
          title="Small lesson credit of $10"
          subtitle="A slight discount when you cancel and require the client to rebook"
          icon={<CreditIcon className="text-gray-600" color="inherit" />}
          onClick={handleCredit(10)}
          selected={credit === 10}
        />
        <ButtonLarge
          title="Bigger lesson credit of $20"
          subtitle="A welcome gesture when you cancel within 7 days, when rebooking is difficult"
          icon={<CreditIcon className="text-gray-600" color="inherit" />}
          onClick={handleCredit(20)}
          selected={credit === 20}
        />
        <ButtonLarge
          title={`1/2 lesson credit of $${Math.ceil(
            Number(appointment.receipt.value) / 2
          ).toFixed(0)}`}
          subtitle="An apology when you cancel within 48 hours, which clients cannot do"
          icon={<CreditIcon className="text-gray-600" color="inherit" />}
          onClick={handleCredit(Number(appointment.receipt.value) / 2)}
          selected={credit === Number(appointment.receipt.value) / 2}
        />
        <ButtonLarge
          title={`Full lesson credit of $${Number(
            appointment.receipt.value
          ).toFixed(0)}`}
          subtitle={`A "Next ones on me" when you cancel last minute, a major inconvenience`}
          icon={<CreditIcon className="text-gray-600" color="inherit" />}
          onClick={handleCredit(Number(appointment.receipt.value))}
          selected={credit === Number(appointment.receipt.value)}
        />
        <ButtonLarge
          title="Nothing, only return their lesson fee"
          subtitle="Your client will receive the full value of their lesson but nothing more"
          icon={<NoCreditIcon className="text-gray-600" color="inherit" />}
          onClick={handleCredit(0)}
          selected={credit === 0}
        />
      </div>
      <Controls variant="block">
        <BackButton onClick={onClickBack}>Go back</BackButton>
      </Controls>
      <Modal
        name="Instructor — What is courtesy credit"
        open={showModal}
        onClose={handleModalClose}
        maxWidth="xs"
        title="Additional compensation"
      >
        <div className="mt-2">
          <div className="flex items-center justify-center p-8 space-x-3 text-6xl text-purple-700">
            <Redeem fontSize="inherit" color="inherit" />
          </div>
          <p>
            Additional compensation is like giving your client a Propel gift
            card to compensate for cancelling their appointment. The amount you
            choose will be deducted from your next payout.
          </p>
          <Controls>
            <Button variant="flat" color="default" onClick={handleModalClose}>
              Got it
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

const Confirm = ({
  appointment,
  credit,
  note,
  submitting,
  onClickNext,
  onClickBack,
}) => {
  const reliability = useSelector(getAccountReliability);

  return (
    <>
      <h2>Cancellation Assistance</h2>
      <div className="my-8 space-y-4">
        <Callout title="Ready to submit?" type="question">
          Here are the details of your cancellation.
        </Callout>
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">
            {`To: ${appointment.client.fullName}`}
          </h4>
          <div className="p-4 text-sm text-gray-900 break-words whitespace-pre-wrap bg-gray-200 rounded-lg">
            {note}
          </div>
        </div>
        <hr />
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">
            Courtesy Credit
          </h4>
          <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            {credit === 0
              ? 'No Courtesy Credit'
              : `${credit.toCurrency()} Courtesy Credit`}
          </div>
        </div>
        {appointment.timeFrame !== TimeFrame.Outside48 && (
          <>
            <hr />
            <div>
              <h4 className="m-0 text-sm font-bold text-gray-700">
                Admission fee
              </h4>
              {reliability.projectedLateCancellationRate >
              INSTRUCTOR_LATE_CANCELLATION_RATE_MAX ? (
                reliability.lateCancellationRate <=
                INSTRUCTOR_LATE_CANCELLATION_RATE_MAX ? (
                  <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
                    Your Late Cancellation Rate will exceed{' '}
                    {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
                    after this cancellation. Propel will not cover the admission
                    fee.
                  </div>
                ) : (
                  <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
                    Your Late Cancellation Rate exceeds{' '}
                    {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%.
                    Propel will not cover the admission fee.
                  </div>
                )
              ) : (
                <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
                  Your Late Cancellation Rate remains below{' '}
                  {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%.
                  Propel will reimburse you the admission fee.
                </div>
              )}
            </div>
          </>
        )}
        <hr />
        <div>
          <h4 className="m-0 text-sm font-bold text-gray-700">Payment</h4>
          <div className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            You will not be paid for this lesson. Your client's payment will be
            automatically returned to the original payment method.
          </div>
        </div>
      </div>
      <Controls variant="block">
        <BackButton disabled={submitting} onClick={onClickBack}>
          Go back
        </BackButton>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={onClickNext}
          disabled={submitting}
        >
          Cancel appointment
        </Button>
      </Controls>
    </>
  );
};

const DenyClientAsk = ({
  appointment,
  onClickBack,
}: {
  appointment: Event;
  onClickBack: () => void;
}) => {
  return (
    <>
      <h4 className="mb-3">Handling client cancellation requests</h4>
      <p>
        If your client can't make it to their lesson, don't cancel for them.
        Instead, send them a message asking them to cancel. That way, your pay
        and cancellation rate won't be impacted.
      </p>
      <Controls variant="block">
        <BackButton onClick={onClickBack}>Go back</BackButton>
        <Button
          variant="flat"
          color="primary"
          to={SHARED_ROUTES.MESSAGES.nav(appointment.client.messageId)}
        >
          Message your client
        </Button>
      </Controls>
    </>
  );
};
const DenyHostAsk = ({
  appointment,
  onClickBack,
}: {
  appointment: Event;
  onClickBack: () => void;
}) => {
  const facilityNoun = appointment.activity.facilityDescription;
  return (
    <>
      <h4 className="mb-3">Handling facility issues</h4>
      <p>
        If there is a problem with the {facilityNoun.toLowerCase()}, don't
        cancel on behalf of the host. Instead, report a facility issue. That
        way, you'll be eligible to receive a bonus payment and your cancellation
        rate won't be impacted.
      </p>
      <Controls variant="block">
        <BackButton onClick={onClickBack}>Go back</BackButton>
        <Button
          variant="flat"
          color="primary"
          to={SHARED_ROUTES.ASSISTANCE.nav(appointment.id, 'facility')}
        >
          Report a facility issue
        </Button>
      </Controls>
    </>
  );
};

type Step =
  | 'index'
  | 'note'
  | 'credit'
  | 'confirm'
  | 'rateChange'
  | 'denyClientAsk'
  | 'denyHostAsk';
const InstructorCancellationView = ({
  appointment,
  onClose,
}: {
  appointment: Event;
  onClose(): void;
}) => {
  const user = useSelector(getUser);
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');
  const [credit, setCredit] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState<Step>('index');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async () => {
    const data: CancelSerializer = {
      reason:
        appointment.timeFrame >= TimeFrame.Within2
          ? 'InstructorAbsent'
          : 'InstructorCancelled',
      instructorCourtesyCredit: credit,
      extra: reason,
      notes: [
        {
          recipient: UserType.Client,
          sender: user.type,
          text: note,
        },
      ],
    };
    setSubmitting(true);
    if (await dispatch(cancelAppointment(appointment, data))) {
      dispatch(fetchReliability());
      history.push(SHARED_ROUTES.SCHEDULE.appointment(appointment.id));
    }
    setSubmitting(false);
  };

  const handleNext = (data?) => {
    if (step === 'index') {
      const reason: Reason = data as Reason;
      setReason(reason);
      if (reason === 'CLIENT_ASK') {
        setStep('denyClientAsk');
      } else if (reason === 'FACILITY_ISSUE') {
        setStep('denyHostAsk');
      } else {
        setStep('rateChange');
      }
    } else if (step === 'rateChange') {
      setStep('note');
    } else if (step === 'note') {
      setStep('credit');
    } else if (step === 'credit') {
      setStep('confirm');
    } else if (step === 'confirm') {
      handleSubmit();
    } else {
      setStep('index');
    }
  };

  const handleBack = () => {
    if (step === 'index') {
      onClose();
    } else if (step === 'rateChange') {
      setStep('index');
    } else if (step === 'note') {
      setStep('rateChange');
    } else if (step === 'credit') {
      setStep('note');
    } else if (step === 'confirm') {
      setStep('credit');
    } else {
      setStep('index');
    }
  };

  useEffect(() => {
    dispatch(fetchReliability());
  }, []);

  return (
    <>
      {step === 'index' ? (
        <Index
          appointment={appointment}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'denyClientAsk' ? (
        <DenyClientAsk appointment={appointment} onClickBack={handleBack} />
      ) : step === 'denyHostAsk' ? (
        <DenyHostAsk appointment={appointment} onClickBack={handleBack} />
      ) : step === 'note' ? (
        <Note
          appointment={appointment}
          note={note}
          setNote={setNote}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'credit' ? (
        <Credit
          appointment={appointment}
          credit={credit}
          setCredit={setCredit}
          onClickNext={handleNext}
          onClickBack={handleBack}
        />
      ) : step === 'confirm' ? (
        <Confirm
          submitting={submitting}
          appointment={appointment}
          credit={credit}
          note={note}
          onClickNext={handleSubmit}
          onClickBack={handleBack}
        />
      ) : step === 'rateChange' ? (
        <CancellationRateNotice
          appointment={appointment}
          onClickBack={handleBack}
          onClickNext={handleNext}
        />
      ) : null}
    </>
  );
};

export default InstructorCancellationView;

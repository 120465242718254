import { FacilityInstructorSerializer } from 'api/Serializers/Facilities';
import Avatar from 'components/avatar';
import Link from 'components/link';
import { ThumbIcon } from 'icons';
import React from 'react';

interface Props extends FacilityInstructorSerializer {
  to?: string;
  onClick?(): void;
}

const InstructorCard = ({
  avatar,
  basePrice,
  displayName,
  shortDescription,
  numRecommended,
  numDelivered,
  numBookable,
  segments,
  specialization1,
  specialization2,
  onClick,
  ...rest
}: Props) => {
  const specializations = [specialization1, specialization2].filter(
    (spec) => !!spec
  );
  const getDOWClasses = (dayOfWeek: string) => {
    const base = 'leading-none font-medium !px-1.5';
    if (rest[dayOfWeek] > 0) {
      return `${base} highlight`;
    }
    return `${base} disabled`;
  };
  return (
    <section
      onClick={onClick}
      className="w-full p-3 overflow-hidden transition-all duration-150 bg-white border border-gray-400 shadow-sm rounded-2xl"
    >
      {numRecommended > 2 && (
        <div className="absolute top-3 text-xs right-3 items-center gap-1 flex px-1.5 text-gray-100 bg-gray-900 rounded-full py-1 leading-none">
          <ThumbIcon fontSize="inherit" width={14} fill="#ffffff" />{' '}
          <span>{numRecommended}</span>
        </div>
      )}
      <div className="space-y-3">
        <div className="flex gap-2">
          <div>
            <Avatar src={avatar} diameter={32} variant="rounded" />
          </div>
          <div className="pt-1 space-y-1">
            <h3 className="my-0 font-bold text-gray-900 text-md">
              {displayName}
            </h3>

            <div className="text-sm font-normal text-gray-800">
              <div className="">
                {segments.concat(specializations).join(' · ')}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-sm button-group">
            <div className={getDOWClasses('sunday')}>Sun</div>
            <div className={getDOWClasses('monday')}>Mon</div>
            <div className={getDOWClasses('tuesday')}>Tue</div>
            <div className={getDOWClasses('wednesday')}>Wed</div>
            <div className={getDOWClasses('thursday')}>Thu</div>
            <div className={getDOWClasses('friday')}>Fri</div>
            <div className={getDOWClasses('saturday')}>Sat</div>
          </div>
        </div>
        <div className="flex justify-between px-3 text-xs font-normal text-gray-700">
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              <span>{numBookable}</span>
            </div>
            <div className="text-xs text-gray-700">Open spots</div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              <span>{numDelivered}</span>
            </div>
            <div className="text-xs text-gray-700">Lessons delivered</div>
          </div>
          <div className="">
            <div className="flex justify-center space-x-2 font-bold text-md">
              ${basePrice}
            </div>
            <div className="text-xs text-gray-700">per lesson</div>
          </div>
        </div>
      </div>
    </section>
  );
};

const InstructorCardComponent = ({ to, ...rest }: Props) =>
  safe(to) ? (
    <Link to={to}>
      <InstructorCard {...rest} />
    </Link>
  ) : (
    <InstructorCard {...rest} />
  );

export default InstructorCardComponent;

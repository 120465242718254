import { Activity } from 'api/Serializers/Activities';
import {
  FacilityTimeSlotType,
  InstructorTimeSlotType,
  ScheduleChangeAction,
  ScheduleDateSerializer,
  ScheduleDateStatus,
  ScheduleTimeSerializer,
  Tense,
} from 'api/Serializers/Schedules';
import Callout, { CalloutType } from 'components/callout';
import { DATE_FMT } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FacilityIcon } from 'icons';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getActivity,
  getProposalCreateList,
  getScheduleAppointmentProduct,
  getScheduleChanges,
} from 'state/selectors';
import { addScheduleChange } from 'state/slice/schedule';
import { getDatetimeTense } from 'utils/date';
import * as AvailabilitySlots from './availability/time-slots';
import * as ProposalSlots from './proposals/time-slots';

export const getDescription = (
  activity: Activity,
  timeSlot: ScheduleTimeSerializer
) => {
  let description = '';
  const getTitle = (timeSlot) =>
    timeSlot.otherData
      ? `${timeSlot.otherData.clientName}, ${
          timeSlot.otherData.numParticipants
        } ${activity.clientDescription.toLowerCase()}`.pluralize(
          timeSlot.otherData.numParticipants
        )
      : `Error getting ${activity.appointmentNoun.toLowerCase()} data`;

  if (timeSlot.instructorStatus === InstructorTimeSlotType.Closed) {
    if (timeSlot.facilityStatus === FacilityTimeSlotType.Open) {
      description = timeSlot.isBookable
        ? 'Open'
        : `${activity.facilityDescription} is unavailable`;
    } else if (
      timeSlot.facilityStatus === FacilityTimeSlotType.Closed ||
      timeSlot.facilityStatus === FacilityTimeSlotType.Blocked
    ) {
      description = `${activity.facilityDescription} is closed`;
    } else if (timeSlot.facilityStatus === FacilityTimeSlotType.Full) {
      description = `${activity.facilityDescription} is full`;
    } else if (timeSlot.facilityStatus === FacilityTimeSlotType.Private) {
      description = 'Open - early access';
    }
    return description;
  } else if (timeSlot.instructorStatus === InstructorTimeSlotType.Available) {
    description = 'Available';
    if (
      timeSlot.facilityStatus === FacilityTimeSlotType.Closed ||
      timeSlot.facilityStatus === FacilityTimeSlotType.Blocked
    ) {
      description += `, ${activity.facilityDescription.toLowerCase()} is closed`;
    } else if (
      timeSlot.facilityStatus === FacilityTimeSlotType.Full ||
      (timeSlot.facilityStatus === FacilityTimeSlotType.Open &&
        !timeSlot.isBookable)
    ) {
      description += `, ${activity.facilityDescription.toLowerCase()} is full`;
    }
    return description;
  } else if (timeSlot.instructorStatus === InstructorTimeSlotType.Pending) {
    description = `${getTitle(
      timeSlot
    )} - Proposed ${activity.appointmentNoun.toLowerCase()}`;
    if (
      timeSlot.facilityStatus === FacilityTimeSlotType.Closed ||
      timeSlot.facilityStatus === FacilityTimeSlotType.Blocked
    ) {
      description += `, ${activity.facilityDescription.toLowerCase()} is closed`;
    } else if (timeSlot.facilityStatus === FacilityTimeSlotType.Full) {
      description += `, ${activity.facilityDescription.toLowerCase()} is full`;
    }
    return description;
  } else if (timeSlot.instructorStatus === InstructorTimeSlotType.Booked) {
    description = getTitle(timeSlot);
    return description;
  }
  return description;
};

const StatusCallout = ({
  date,
  status,
}: {
  date: string;
  status: ScheduleDateStatus;
}) => {
  let title: string;
  let message: string;
  let type: CalloutType;
  let icon = null;
  if (status === 'valid') {
    return null;
  } else if (status === 'instructor_works_elsewhere') {
    title = 'Scheduled elsewhere';
    message = "You're scheduled at another location.";
    type = 'warning';
    icon = <FacilityIcon width={24} />;
  } else if (status === 'facility_closed') {
    title = 'Temporarily unavailable';
    message = 'Location is not available.';
    type = 'info';
  } else if (status === 'fetch_error') {
    title = 'Network error';
    message = 'Error getting schedule, please refresh.';
    type = 'error';
  } else if (status === 'private') {
    title = 'Not public';
    message = 'Schedule is not open for public access';
    type = 'info';
  } else if (status === 'early_access') {
    title = 'Early access for proposals only';
    message = 'Availability submission opens later.';
    type = 'info';
  } else {
    return null;
  }
  return (
    <Callout type={type} title={title} icon={icon}>
      {message}
    </Callout>
  );
};

const Menu = ({
  mode,
  date,
  times,
  changes,
  minuteStart,
  canChange,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const appointmentProduct = useSelector(getScheduleAppointmentProduct);
  const numRemove = changes.filter(
    (c) => c.action === ScheduleChangeAction.Delete
  ).length;
  const numAdd =
    times.filter((t) => t.instructorStatus === InstructorTimeSlotType.Available)
      .length +
    changes.filter((c) => c.action === ScheduleChangeAction.Create).length;

  const handleClear = () => {
    changes.map((c) => {
      dispatch(
        addScheduleChange({
          ...c,
          action: ScheduleChangeAction.Delete,
        })
      );
    });
  };
  const handleChange = (evt) => {
    handleClear();
    onChange(minuteStart === 0 ? 30 : 0);
  };

  const handleRemoveAvailability = () => {
    changes
      .filter((change) => change.action === ScheduleChangeAction.Create)
      .map((change) =>
        dispatch(
          addScheduleChange({
            ...change,
            action: ScheduleChangeAction.Delete,
          })
        )
      );
    times
      .filter(
        (timeSlot) =>
          timeSlot.instructorStatus === InstructorTimeSlotType.Available
      )
      .filter(
        (timeSlot) =>
          !changes.some((change) => change.datetime === timeSlot.datetime)
      )
      .map((timeSlot) =>
        dispatch(
          addScheduleChange({
            aptProdId: appointmentProduct.id,
            id: timeSlot.id,
            datetime: timeSlot.datetime,
            date: moment(timeSlot.datetime)
              .tz(appointmentProduct.timezone)
              .format(DATE_FMT.DATE_KEY),
            action: ScheduleChangeAction.Delete,
          })
        )
      );
  };
  if (times.length === 0) {
    return null;
  }
  return (
    <div className="flex items-center justify-between px-6 py-2 -mx-6 border-t border-gray-100 bg-gray-50">
      <div className={`flex rounded-md border border-gray-300 overflow-hidden`}>
        <button
          className={`disabled:text-gray-500 disabled:bg-gray-100 p-2 text-xs flex-1 focus:ring-0 ${
            minuteStart === 0 ? 'font-semibold bg-blue-50 text-blue-600' : ''
          }`}
          onClick={minuteStart === 0 ? null : handleChange}
          disabled={!canChange}
        >
          :00
        </button>
        <span className="border-l border-slate-200" />
        <button
          className={`disabled:text-gray-500 disabled:bg-gray-100 p-2 text-xs flex-1 focus:ring-0 ${
            minuteStart === 30
              ? 'font-semibold bg-blue-50 text-blue-600'
              : 'gray'
          }`}
          onClick={minuteStart === 30 ? null : handleChange}
          disabled={!canChange}
        >
          :30
        </button>
      </div>
      <div>
        {mode === 'availability' && (
          <button
            className="btn gray"
            onClick={handleRemoveAvailability}
            disabled={numAdd === 0 || numRemove === numAdd}
          >
            {canChange ? 'Clear changes' : 'Remove available times'}
          </button>
        )}
      </div>
    </div>
  );
};

type Mode = 'availability' | 'proposals';
const TimeSlots = ({
  aptProdId,
  times,
  // tense,
  date,
  status,
  mode,
}: ScheduleDateSerializer & { mode: Mode }) => {
  let Available, Booked, Closed, Proposal, NewProposal;

  if (mode === 'availability') {
    Available = AvailabilitySlots.Available;
    Booked = AvailabilitySlots.Booked;
    Closed = AvailabilitySlots.Closed;
    Proposal = AvailabilitySlots.Proposal;
  } else if (mode === 'proposals') {
    Available = ProposalSlots.Available;
    Booked = ProposalSlots.Booked;
    Closed = ProposalSlots.Closed;
    Proposal = ProposalSlots.Proposal;
    NewProposal = ProposalSlots.NewProposal;
  }

  const activity = useSelector(getActivity);
  const scheduleChanges = useSelector(getScheduleChanges);
  const proposals = useSelector(getProposalCreateList).filter(
    (p) => p.date === date
  );
  const hasProposals = proposals.length > 0;
  const added = scheduleChanges.filter((c) => c.date === date);
  const canChange = times.every(
    (time) => time.instructorStatus === InstructorTimeSlotType.Closed
  );
  const [minuteStart, setMinuteStart] = useState(
    added.length > 0 ? moment(added[0].datetime).minute() : 0
  );
  const filteredTimes = canChange
    ? times.filter((t) => moment(t.datetime).minute() === minuteStart)
    : times;

  if (
    status === 'valid' ||
    (status === 'early_access' && mode === 'proposals')
  ) {
    return (
      <>
        {status === 'early_access' && mode === 'proposals' && (
          <Callout type="success" title="Early access" />
        )}
        <Menu
          minuteStart={minuteStart}
          changes={added}
          date={date}
          times={times}
          onChange={setMinuteStart}
          canChange={canChange}
          mode={mode}
        />
        <div className="mt-2 -mx-6 divide-y divide-gray-300">
          {filteredTimes.map((timeSlot, k) => {
            const tense = getDatetimeTense(timeSlot.datetime);
            const timeSlotChange = scheduleChanges
              .filter((c) => c.datetime === timeSlot.datetime)
              .sort((a, b) => (a.created > b.created ? 1 : -1))
              .pop();
            const finalInstructorStatus = !timeSlotChange
              ? timeSlot.instructorStatus
              : timeSlotChange.action === ScheduleChangeAction.Create
              ? InstructorTimeSlotType.Available
              : InstructorTimeSlotType.Closed;
            if (
              tense <= Tense.Today &&
              timeSlot.instructorStatus !== InstructorTimeSlotType.Booked
            ) {
              return null;
            }
            if (mode === 'proposals' && hasProposals) {
              const iProposal = proposals.findIndex(
                (p) => p.datetime === timeSlot.datetime
              );
              if (iProposal !== -1) {
                return (
                  <NewProposal
                    key={timeSlot.datetime}
                    timeSlot={timeSlot}
                    {...proposals[iProposal]}
                  />
                );
              }
            }
            if (finalInstructorStatus === InstructorTimeSlotType.Closed) {
              return (
                <Closed
                  key={timeSlot.datetime}
                  aptProdId={aptProdId}
                  tense={tense}
                  timeSlot={timeSlot}
                  isChanged={!!timeSlotChange}
                  description={
                    !timeSlot.description
                      ? getDescription(activity, timeSlot)
                      : timeSlot.description // LEGACY
                  }
                />
              );
            } else if (
              finalInstructorStatus === InstructorTimeSlotType.Available
            ) {
              return (
                <Available
                  key={timeSlot.datetime}
                  aptProdId={aptProdId}
                  tense={tense}
                  timeSlot={timeSlot}
                  isChanged={!!timeSlotChange}
                  description={
                    !timeSlot.description
                      ? getDescription(activity, timeSlot)
                      : timeSlot.description // LEGACY
                  }
                />
              );
            } else if (
              finalInstructorStatus === InstructorTimeSlotType.Pending
            ) {
              return (
                <Proposal
                  key={timeSlot.datetime}
                  aptProdId={aptProdId}
                  tense={tense}
                  timeSlot={timeSlot}
                  description={
                    !timeSlot.description
                      ? getDescription(activity, timeSlot)
                      : timeSlot.description // LEGACY
                  }
                />
              );
            } else if (
              finalInstructorStatus === InstructorTimeSlotType.Booked
            ) {
              return (
                <Booked
                  key={timeSlot.datetime}
                  aptProdId={aptProdId}
                  tense={tense}
                  timeSlot={timeSlot}
                  description={
                    !timeSlot.description
                      ? getDescription(activity, timeSlot)
                      : timeSlot.description // LEGACY
                  }
                />
              );
            }
            return null;
          })}
        </div>
      </>
    );
  } else {
    return <StatusCallout date={date} status={status} />;
  }
};

export default TimeSlots;

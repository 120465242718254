import {
  FacilityAssetType,
  FacilityCategory,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import { AxiosResponse } from 'axios';
import { METHOD, UserType } from 'config';
import { Report } from 'models/Assistance';
import { Status } from '../Accounts';
import { Activity as ActivitySerializer } from '../Activities';
import { Participant } from '../Clients';
import { Media } from '../Media';
import { PaymentMethodSerializer } from '../PaymentMethods';
import { SchedulableObject } from '../Schedules';

export type CancellationReason =
  | 'AdminCancelled'
  | 'FacilityCancelled'
  | 'PoolClosure'
  | 'ColdWater'
  | 'Overcrowded'
  | 'PoolFouling'
  | 'ClientCancelled'
  | 'ClientAbsent'
  | 'ClientDissatisfied'
  | 'InstructorCancelled'
  | 'InstructorAbsent'
  | 'CancelPolicyWaived';

interface CancellationNote {
  text: string;
  recipient: UserType;
  sender: UserType;
}

enum PricePolicy {
  /** No change in price, no refund */
  REGULAR_FEE = 'REG',
  /** Charge user the facility access fee */
  CHARGED_ADMISSION_FEES = 'CAF',
  /** Pay Protection instances occur when Propel must foot the bill for this entity [not applicable to clients] */
  PAY_PROTECTION = 'PPP',
  /** Used to denote a client refund or instructor/host non-payment */
  NO_FEE = 'NOF',
}
export interface Cancellation {
  id?: string;
  instructorCourtesyCredit: number;
  propelCourtesyCredit: number;
  created: string;
  causedBy: string;
  title: string;
  description: string;
  notes: CancellationNote[];
  reportedBy: {
    id: number;
    firstName: string;
    username: string;
    type: UserType;
  };
  timeFrame: TimeFrame;
  results: string[];
  reason?: CancellationReason;
  clientPricePolicy?: PricePolicy;
  canWaivePolicy?: boolean;
}

interface Region {
  label: string;
  slug: string;
  timezone: string;
  city: string;
  distance: number;
  latlng: { lat: number; lng: number };
}

export interface Client {
  id?: number;
  messageId: number;
  slug: string;
  firstName: string;
  fullName: string;
  username?: string;
  displayName: string;
  avatar?: string;
  status: Status;
}

export interface Instructor {
  id: number;
  messageId: number;
  avatar: string;
  displayName: string;
  firstName: string;
  fullName: string;
  status: Status;
  username: string;
  slug?: string;
}

export interface Facility {
  id: number;
  slug: string;
  status: FacilityStatus;
  shortName: string;
  displayName: string;
  avatar: string;
  category: FacilityCategory;
  assetType: FacilityAssetType;
  temperature: '25-27' | '28-29' | '30-31' | '32-33';
  admissionFee?: number;
  averageAppointmentPrice?: number;
  accessDirectionsUrl?: string;
  region?: Region;
  media?: Media[];
  hero?: string;
  instructorNotes?: string;
}

export enum TimeFrame {
  Outside48 = 0,
  Within48 = 1,
  SameDay = 2,
  Within2 = 3,
  HasStarted = 4,
  HasEnded = 5,
  Complete = 6,
  Locked = 7,
}

export enum PaymentIntentStatus {
  RequiresPaymentMethod = 'requires_payment_method',
  RequiresConfirmation = 'requires_confirmation',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  Processing = 'processing',
  Canceled = 'canceled',
  Succeeded = 'succeeded',
}

export enum AppointmentPaymentActivityLogAction {
  Create = 'CREATE',
  Cancel = 'CANCEL',
  ChangePaymentMethod = 'CHANGE_PAYMENT_METHOD',
  Authorize = 'AUTHORIZE',
  Capture = 'CAPTURE',
  Settle = 'SETTLE',
  UpdatePrice = 'UPDATE_PRICE',
  ManualRefund = 'MANUAL_REFUND',
}

interface AppointmentPaymentActivityLogSerializer {
  created: string;
  action: AppointmentPaymentActivityLogAction;
  succeeded: boolean;
}

interface PaymentIntentSerializer {
  id: string;
  created: string;
  status: PaymentIntentStatus;
  redirectUrl: string;
  amount: number;
  amountCapturable: number;
  amountReceived: number;
  amountRefunded: number;
}

interface ReceiptLineItem {
  name: string;
  price: string;
  editable: boolean;
}

interface Receipt {
  price: string;
  value: string;
  charged: string;
  lineItems: ReceiptLineItem[];
  refunded: string;
  revenue: string;
  admissionFee?: string;
  admissionFeeTax?: string;
  bookingFee?: string;
  bookingFeeTax?: string;
}

export interface Event {
  activity: ActivitySerializer;
  cancellation: Cancellation;
  cancelled: boolean;
  client: Client;
  created: string;
  date: string;
  description: string;
  end: string;
  facility: Facility;
  facilityNumAppointment?: number;
  id: string;
  instructor: Instructor;
  lastPaymentAction?: AppointmentPaymentActivityLogSerializer;
  lastPaymentIntent?: PaymentIntentSerializer;
  modified: string;
  notes: string;
  numAppointment: number;
  numParticipants: number;
  participant1: string;
  participant2: string;
  participants: Participant[];
  paymentMethod?: PaymentMethodSerializer;
  receipt: Receipt;
  reports: Report[];
  start: string;
  summary: string;
  time: string;
  timeFrame: TimeFrame;
  timezone: string;
}

export type EventList = Pick<
  Event,
  | 'id'
  | 'activity'
  | 'start'
  | 'end'
  | 'date'
  | 'summary'
  | 'cancelled'
  | 'timezone'
  | 'facility'
  | 'client'
  | 'instructor'
  | 'notes'
  | 'numParticipants'
  | 'created'
  | 'modified'
  | 'timeFrame'
  | 'cancellation'
  | 'paymentMethod'
>;

export interface EventListParams {
  start?: string;
  end?: string;
  username?: string;
  facility?: number;
  cancelled?: boolean;
  limit?: number;
  order?: 'asc' | 'desc';
}

export interface AppointmentFeedbackStageSerializer {
  appointmentFeedbackSubmitted: boolean;
  googleReviewSubmitted: boolean;
  instructorReviewSubmitted: boolean;
  instructor: {
    firstName: string;
    fullName: string;
    slug: string;
    avatar: string;
  };
  facility: {
    displayName: string;
    avatar: string;
    hero: string;
    googleReviewUrl: string;
  };
}

interface ProxySessionParticipantSerializer {
  expires: string;
  phone: string;
  proxyPhone: string;
}

export interface AppointmentBookingData {
  bookingObjectType: 'availability' | 'proposal';
  bookingObjectId: string;
  clientId: number;
  paymentMethod: PaymentMethodSerializer;
  participants: Participant[];
}

export interface CancelSerializer {
  reason: CancellationReason;
  notes: CancellationNote[];
  instructorCourtesyCredit?: number;
  propelCourtesyCredit?: number;
  extra?: string;
}

export interface AppointmentListSerializer extends SchedulableObject {
  cancellation: {
    created: string;
    description: string;
  };
  paymentMethod?: PaymentMethodSerializer;
  notes: string;
  numParticipants: number;
  participants: Participant[];
}

export interface AppointmentsApi {
  list: (
    params: EventListParams,
    abortController?: AbortController
  ) => Promise<AxiosResponse<AppointmentListSerializer[]>>;
  retrieve: (id: string) => Promise<AxiosResponse<Event>>;
  partialUpdate: (
    id: string,
    data: Partial<Event>
  ) => Promise<AxiosResponse<Event>>;
  cancel: (id: string, data: CancelSerializer) => Promise<AxiosResponse<Event>>;
  paymentMethod: (
    id: string,
    data: { id: string }
  ) => Promise<AxiosResponse<any>>;
  feedbackStage: (
    id: string
  ) => Promise<AxiosResponse<AppointmentFeedbackStageSerializer>>;
  googleReview: (id: string) => Promise<AxiosResponse<any>>;
  receipt: (id: string) => Promise<AxiosResponse<string>>;
  proxySessions: (
    id: string,
    params: { recipient: UserType },
    data?: { phone: string },
    method?: METHOD
  ) => Promise<AxiosResponse<ProxySessionParticipantSerializer>>;
}

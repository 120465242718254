import { Google as GoogleIcon } from '@mui/icons-material';
import api from 'api';
import InstructorAccount, {
  InstructorActivityPreferences,
  InstructorPreferredTime,
  WeekDayIntegers,
} from 'api/Serializers/Accounts/Instructor';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import ButtonCopyText from 'components/button-copy-text';
import Callout from 'components/callout';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import Controls from 'components/controls';
import InputAddress from 'components/input-address';
import Loading from 'components/loading';
import Modal from 'components/modal';
import Select, { SelectOption } from 'components/select';
import { FETCH_STATE, FIELDS, TIME_OPTIONS } from 'config';
import InstructorFacilitySettings from 'containers/instructor-facility-settings';
import useAccountPatch from 'hooks/useAccountPatch';
import { AccountIcon, FacilityIcon, ScheduleIcon, SettingsIcon } from 'icons';
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { getActivity, getCurrentUser, getUsername } from 'state/selectors';
import { SHOW_HELP } from 'utils/routing';

const saveDebounced = debounce((save, data) => {
  save(data);
}, 500);

const SelectWeekdayTimes = ({
  title,
  weekDay,
  onChange,
  preference,
}: {
  title: string;
  weekDay: WeekDayIntegers;
  onChange(data: Partial<InstructorPreferredTime>): void;
  preference: InstructorPreferredTime;
}) => {
  const isSet =
    preference?.start !== undefined && preference?.end !== undefined;
  const [init, setInit] = useState(false);
  const [enabled, setEnabled] = useState(isSet);
  const [start, setStart] = useState<SelectOption<string>>(
    isSet
      ? TIME_OPTIONS.find((opt) => opt.value === preference.start)
      : TIME_OPTIONS[0]
  );
  const [end, setEnd] = useState<SelectOption<string>>(
    isSet
      ? TIME_OPTIONS[
          TIME_OPTIONS.findIndex((opt) => opt.value === preference.end)
        ]
      : TIME_OPTIONS[TIME_OPTIONS.length - 1]
  );

  const startOptions = TIME_OPTIONS.slice(0, -1);
  const endOptions = TIME_OPTIONS.slice(
    TIME_OPTIONS.findIndex((val) => val.value === start.value) + 1
  );

  const handleToggle = () => setEnabled(!enabled);

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    if (enabled) {
      /** Validate End value when Start changes */
      const startIndex = TIME_OPTIONS.findIndex(
        (val) => val.value === start?.value
      );
      let endIndex = TIME_OPTIONS.findIndex((val) => val.value === end?.value);
      if (startIndex >= endIndex && endIndex > -1) {
        endIndex = startIndex + 1;
        setEnd(TIME_OPTIONS[endIndex]);
      }
      onChange({ weekDay, start: start?.value, end: end?.value });
    } else {
      onChange({ weekDay });
    }
  }, [start, end, enabled]);

  const name = `${title.toLowerCase()}_cbx`;

  return (
    <div className="flex flex-col justify-between w-full sm:flex-row">
      <div className="flex items-center ">
        <Checkbox
          name={name}
          checked={enabled}
          onChange={handleToggle}
          label={title}
          className="font-semibold"
        />
      </div>
      <div className="flex items-center space-x-4">
        <Select
          name="start"
          value={start}
          options={startOptions}
          onChange={(option: SelectOption<string>) => setStart(option)}
          disabled={!enabled}
          className="flex-1 w-32"
        />
        <span>to</span>
        <Select
          name="end"
          value={end}
          options={endOptions}
          onChange={(option: SelectOption<string>) => setEnd(option)}
          disabled={!enabled}
          className="flex-1 w-32"
        />
      </div>
    </div>
  );
};

const SelectWeekdays = ({
  preferences,
  handleSingleDayChange,
}: {
  preferences: InstructorActivityPreferences;
  handleSingleDayChange(data: InstructorPreferredTime): void;
}) => {
  return (
    <div className="flex flex-col items-center space-y-3">
      <SelectWeekdayTimes
        key="granular-monday"
        title="Monday"
        weekDay={2}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 2)}
      />
      <SelectWeekdayTimes
        key="granular-tuesday"
        title="Tuesday"
        weekDay={3}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 3)}
      />
      <SelectWeekdayTimes
        key="granular-wednesday"
        title="Wednesday"
        weekDay={4}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 4)}
      />
      <SelectWeekdayTimes
        key="granular-thursday"
        title="Thursday"
        weekDay={5}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 5)}
      />
      <SelectWeekdayTimes
        key="granular-friday"
        title="Friday"
        weekDay={6}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 6)}
      />
      <SelectWeekdayTimes
        key="granular-saturday"
        title="Saturday"
        weekDay={7}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 7)}
      />
      <SelectWeekdayTimes
        key="granular-sunday"
        title="Sunday"
        weekDay={1}
        onChange={handleSingleDayChange}
        preference={preferences.times.find((time) => time.weekDay === 1)}
      />
    </div>
  );
};

const PreferredTimes = () => {
  const username = useSelector(getUsername);
  const activity = useSelector(getActivity);
  const [showHelp, setShowHelp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetchState, setFetchState] = useState(FETCH_STATE.GET);
  const [preferences, setPreferences] = useState<InstructorActivityPreferences>(
    {
      activity,
      times: [],
      numDesiredBookings: undefined,
    }
  );
  const handleSave = async () => {
    try {
      const data = {
        ...preferences,
        activityId: preferences.activity.id,
      };
      await api.account.preferences(username, data);
      enqueueSnackbar({
        message: 'Nice! Thanks for updating your work preferences.',
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar({
        message: 'There was an error saving your changes. Please try again.',
        variant: 'error',
      });
    }
  };
  const handleSingleDayChange = (data: InstructorPreferredTime) => {
    const times = preferences.times.filter(
      (time) => time.weekDay !== data.weekDay
    );
    if (data.start && data.end) {
      times.push(data);
    }
    setPreferences((curr) => ({ ...curr, times }));
  };
  const handleSetHours = (evt) => {
    const numDesiredBookings = Number(evt.currentTarget.value);
    setPreferences((curr) => ({
      ...curr,
      numDesiredBookings,
    }));
  };
  const fetchPreferences = async () => {
    try {
      const response = await api.account.preferences(username);
      if (response.data) {
        setPreferences(response.data);
      }
      setFetchState(FETCH_STATE.IDLE);
    } catch (err) {
      setFetchState(FETCH_STATE.FAILED);
    }
  };
  useEffect(() => {
    fetchPreferences();
  }, []);
  if (fetchState === FETCH_STATE.GET) {
    return (
      <div className="flex justify-center w-full">
        <Loading
          message="Getting your current preferences..."
          position="inline-contained"
        />
      </div>
    );
  } else if (fetchState === FETCH_STATE.FAILED) {
    return (
      <div className="flex justify-center w-full">
        <Callout type="error">
          Error getting current preferences. Please refresh to try again.
        </Callout>
      </div>
    );
  }
  return (
    <div className="space-y-6">
      <p>
        Tell us about your ideal schedule — how much and when you'd like to
        work.
      </p>

      <div>
        <h4>Hours per week</h4>
        <p>
          Used to understand your workload goals and alert you when adjusting
          your availability or price may be helpful.
        </p>
        <div className="">
          <label htmlFor="numDesiredBookings">
            How many hours per week do you want to work?
          </label>
          <input
            type="number"
            name="numDesiredBookings"
            placeholder="0"
            defaultValue={preferences.numDesiredBookings}
            className="w-24"
            onChange={handleSetHours}
          />
        </div>
      </div>
      <div>
        <h4>Days and times</h4>
        <p>
          Separate from the availability you will set for clients to book, this
          is used to help pool hosts set their schedules and alert you when new
          times open that you may be interested in.
        </p>
        <SelectWeekdays
          preferences={preferences}
          handleSingleDayChange={handleSingleDayChange}
        />
      </div>
      <Controls>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save work preferences
        </Button>
      </Controls>
      <Modal
        open={showHelp}
        onClose={() => setShowHelp(false)}
        name="What are preferred times"
        title="What are preferred times?"
        maxWidth="xs"
      >
        <div className="space-y-4">
          <Callout>
            Preferred times are a simple way to tell us when you'd like to work.
            We then use it to ask your hosts for schedules that you really want.
          </Callout>
          <p>
            Every 2 months, each host reviews and adjusts their Propel schedule.
            We always ask for as much time as possible, but hosts can be limited
            by other commitments, maintenance schedules, or busy seasons.
          </p>
          <p>
            By adding your preferred times, we can show the host when you plan
            to work, which can influence the schedule to what works best for
            you.
          </p>
          <Controls>
            <Button
              color="primary"
              variant="flat"
              onClick={() => setShowHelp(false)}
            >
              Got it
            </Button>
          </Controls>
        </div>
      </Modal>
    </div>
  );
};

const CalendarSync = () => {
  const account = useSelector(getCurrentUser);
  return (
    <>
      <p>Sync your Propel lesson schedule to a calendar app.</p>
      <div className="space-y-3">
        <h5>Add to your calendar</h5>
        <div>
          <Button
            className="w-64"
            to={(account as InstructorAccount).calendarLink.replace(
              /^https?:\/\//i,
              'webcal://'
            )}
            color="primary"
            variant="flat"
            icon={<ScheduleIcon width={24} />}
          >
            Your device default
          </Button>
        </div>
        <div>
          <Button
            className="w-64"
            to={`https://calendar.google.com/calendar/r?pli=1&cid=${
              (account as InstructorAccount).calendarLink
            }`}
            color="primary"
            variant="flat"
            icon={<GoogleIcon />}
          >
            Google Calendar
          </Button>
        </div>
        <hr />
        <h5>Manually Subscribe in other apps</h5>
        <ButtonCopyText
          className="w-64"
          color="primary"
          alertText="Link copied to clipboard!"
          copyText={(account as InstructorAccount).calendarLink}
        >
          Copy Calendar Link
        </ButtonCopyText>
        <p>
          You can paste this link into any other app that supports calendar
          subscriptions.
        </p>
        <Callout
          type="warning"
          title="Be Aware of Calendar Subscription Limitations"
        >
          Some apps update very infrequently causing schedules to be outdated.
          For help avoiding this{' '}
          <span
            className="font-medium hover:underline hover:cursor-pointer"
            onClick={SHOW_HELP.CALENDAR_SUBSCRIPTION}
          >
            see our help center article
          </span>
          .
        </Callout>
      </div>
    </>
  );
};

const Contact = () => {
  const account = useSelector(getCurrentUser);
  const { handleAccountUpdate } = useAccountPatch();
  const [address, setAddress] = useState({ ...account.address });
  const [phoneNumber, setPhoneNumber] = useState(account.phoneNumber);
  const [init, setInit] = useState(false);
  useEffect(() => {
    setInit(true);
  }, []);
  useEffect(() => {
    if (init && address) {
      saveDebounced(handleAccountUpdate, { address });
    }
  }, [address]);
  useEffect(() => {
    if (init && phoneNumber) {
      saveDebounced(handleAccountUpdate, { phoneNumber });
    }
  }, [phoneNumber]);
  return (
    <>
      <h5>Email Address</h5>
      <h5>{account.email}</h5>
      <div className="max-w-md">
        <label htmlFor="email">Email address</label>
        <PhoneInput
          name="email"
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          country="CA"
          placeholder="604-555-0938"
        />
      </div>
      <div className="max-w-md">
        <label htmlFor={FIELDS.PHONE_NUMBER}>Phone</label>
        <PhoneInput
          name={FIELDS.PHONE_NUMBER}
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          country="CA"
          placeholder="604-555-0938"
        />
      </div>
      <div className="max-w-md mt-2">
        <InputAddress
          name="address"
          onChange={(address) => setAddress(address)}
          {...address}
        />
      </div>
    </>
  );
};

const Tab = ({ active, onClick, children }) =>
  active ? (
    <div className="flex flex-1 pb-2 bg-white rounded-t-xl group">
      <div className="absolute w-4 h-full -left-4">
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-background rounded-br-2xl" />
      </div>
      <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left">
        {children}
      </span>
      <div className="absolute w-4 h-full -right-4">
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-white" />
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-background rounded-bl-2xl" />
      </div>
    </div>
  ) : (
    <button onClick={onClick} className="z-10 flex flex-1 pb-2 group">
      <span className="flex items-center flex-1 gap-2 px-2.5 py-2 text-left transition-colors duration-300 bg-slate-200/50 rounded-xl group-hover:bg-slate-200">
        {children}
      </span>
    </button>
  );

type Modes = 'locations' | 'work' | 'calendar_sync' | 'contact';
const Settings = () => {
  const account = useSelector(getCurrentUser);
  const [mode, setMode] = useState<Modes>('work');
  const options: SelectOption<Modes>[] = [
    {
      label: 'Work preferences',
      value: 'work',
      data: SettingsIcon,
    },
    {
      label: 'Locations & prices',
      value: 'locations',
      data: FacilityIcon,
    },
    {
      label: 'Calendar sync',
      value: 'calendar_sync',
      data: ScheduleIcon,
    },
    {
      label: 'Contact info',
      value: 'contact',
      data: AccountIcon,
    },
  ];
  const selected = options.find((opt) => opt.value === mode);
  return (
    <Dashboard title="Settings">
      <div>
        <div className="hidden pt-1 mx-6 space-x-2 text-sm text-gray-800 md:flex bg-background rounded-t-xl">
          {options.map(({ label, value, data: Icon }) => (
            <Tab
              key={value}
              active={mode === value}
              onClick={() => setMode(value)}
            >
              <Icon width={18} /> {label}
            </Tab>
          ))}
        </div>
        <div className="max-w-xs mx-auto mb-6 md:hidden card">
          <Select
            placeholder="Menu"
            options={options}
            defaultValue={selected}
            onChange={(opt) => setMode(opt.value)}
          />
        </div>
        <Card maxWidth="full" title={selected.label as string}>
          {mode === 'work' ? (
            <PreferredTimes />
          ) : mode === 'locations' ? (
            <InstructorFacilitySettings username={account.username} />
          ) : mode === 'calendar_sync' ? (
            <CalendarSync />
          ) : mode === 'contact' ? (
            <Contact />
          ) : null}
        </Card>
      </div>
    </Dashboard>
  );
};

export default Settings;

import {
  ClockIcon,
  EllipsisHorizontalCircleIcon,
  SunIcon,
} from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import {
  AppointmentListSerializer,
  Facility,
} from 'api/Serializers/Appointments';
import { Availability } from 'api/Serializers/Availability';
import { ProposalListItem } from 'api/Serializers/Proposals';
import { SchedulableObject, Tense } from 'api/Serializers/Schedules';
import Button from 'components/button';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Link from 'components/link';
import Modal from 'components/modal';
import {
  CAL_LIST_ITEM_NAME_PREFIX,
  DATE_FMT,
  FETCH_STATE,
  QueryParams,
} from 'config';
import FacilityScheduleUpdates from 'containers/facility-updates';
import ReadMore from 'containers/read-more';
import AppointmentDetail from 'features/appointment-detail';
import { CalendarDayBookings } from 'features/schedule/calendar-date-box';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import useTimeoutRefresh from 'hooks/useTimeoutRefresh';
import {
  ArrowForwardIcon,
  CloseIcon,
  DoneIcon,
  FlagIcon,
  InfoIcon,
  ProposalIcon,
  ScheduleIcon,
} from 'icons';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import {
  getAccountDetail,
  getAppointments,
  getAppointmentsFetchState,
  getAvailability,
  getAvailabilityFetchState,
  getProposals,
  getProposalsFetchState,
  getScheduleRenderDate,
} from 'state/selectors';
import { fetchAppointments } from 'state/slice/appointments';
import { fetchAvailability } from 'state/slice/availability';
import { fetchProposals } from 'state/slice/proposals';
import {
  getDateTense,
  getDatetimeTense,
  getMonthStartEndParams,
} from 'utils/date';
import { SHARED_ROUTES } from 'utils/routing';
import ProposalDetailLoader from '../proposals/detail';
import ScheduleTemplate, { ScheduleLoading } from '../template';

function scrollToTarget(target) {
  scroller.scrollTo(target, {
    smooth: 'easeInOutCubic',
    duration: 1000,
    offset: -24,
    containerId: 'ScheduleList',
  });
}

const TODAY_EMPTY_ID = '__TODAY_EMPTY__';
type SchedulableObjectType =
  | 'APPOINTMENT'
  | 'PROPOSAL'
  | 'AVAILABLE'
  | 'UNAVAILABLE';
interface TypedSchedulableObject extends SchedulableObject {
  type: SchedulableObjectType;
}
interface ScheduleObjectProps {
  object: TypedSchedulableObject;
  isClickable: boolean;
  setInfoModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmptySchedule = () => (
  <div
    className={`bg-white shadow-lg px-2 sm:px-4 mx-auto pb-6 pt-8 rounded-lg max-w-xl my-8`}
  >
    <Callout title="No availability submitted yet!" type="info">
      <p>
        Simply select a date from the calendar, or use these links to get
        started
      </p>
      <Controls>
        <Button
          to={SHARED_ROUTES.SCHEDULE.availability}
          variant="flat"
          color="primary"
        >
          Add availability
        </Button>
        <Button
          to={SHARED_ROUTES.SCHEDULE.weekly}
          variant="flat"
          color="primary"
        >
          Set a weekly schedule
        </Button>
      </Controls>
    </Callout>
  </div>
);

function getTimeBadge(object: TypedSchedulableObject, tense: Tense) {
  const time = moment(object.start).tz(object.timezone).format(DATE_FMT.TIME);
  if (object.cancelled) {
    return (
      <span className="space-x-0.5 text-red-900 bg-red-100 badge">
        <CloseIcon width={13} /> <span>{time}</span>
      </span>
    );
  } else if (tense === Tense.Past) {
    return (
      <span className="space-x-0.5 bg-slate-100 badge text-slate-700">
        <DoneIcon width={13} /> <span>{time}</span>
      </span>
    );
  } else if (object.type === 'APPOINTMENT') {
    if (tense === Tense.Now) {
      return (
        <span className="space-x-0.5 text-white bg-blue-500 badge">
          <FlagIcon width={13} /> <span>{time}</span>
        </span>
      );
    }
    return (
      <span className="space-x-0.5 text-green-900 bg-green-100 badge">
        <ScheduleIcon width={13} /> <span>{time}</span>
      </span>
    );
  } else if (object.type === 'PROPOSAL') {
    return (
      <span className="space-x-0.5 text-orange-900 bg-orange-100 badge">
        <ProposalIcon width={13} /> <span>{time}</span>
      </span>
    );
  } else if (object.type === 'AVAILABLE') {
    return (
      <span className="space-x-0.5 text-blue-900 bg-blue-100 badge">
        <ClockIcon width={13} /> <span>{time}</span>
      </span>
    );
  } else if (object.type === 'UNAVAILABLE') {
    return (
      <span className="space-x-0.5 text-gray-900 bg-gray-100 badge">
        <ClockIcon width={13} /> <span>{time}</span>
      </span>
    );
  }
  return null;
}
function getBgColor(object: TypedSchedulableObject, tense: Tense) {
  if (object.cancelled || tense === Tense.Past) {
    return 'bg-gray-50 text-gray-600 hover:bg-gray-300';
  } else if (object.type === 'APPOINTMENT') {
    if (tense === Tense.Now) {
      return 'font-semibold text-gray-900 hover:bg-blue-50';
    }
    return 'font-semibold text-gray-800 hover:bg-green-50 hover:text-green-900';
  } else if (object.type === 'PROPOSAL') {
    return 'font-semibold text-gray-800 hover:bg-orange-50 hover:text-orange-900';
  } else if (object.type === 'AVAILABLE') {
    return 'cursor-default text-gray-800 ';
  } else if (object.type === 'UNAVAILABLE') {
    return 'cursor-default text-gray-700 italic bg-gray-50 ';
  }
  return 'text-gray-800 hover:bg-blue-50 bg-gray-100 hover:text-gray-900';
}

export const ScheduleObject = ({
  object,
  isClickable,
  setInfoModalOpen,
}: ScheduleObjectProps) => {
  const tense: Tense = getDatetimeTense(object.start);
  const bgColor = getBgColor(object, tense);
  return (
    <div className={`transition-colors duration-150 px-card group ${bgColor}`}>
      <div className="py-3 mx-2 sm:mx-0 sm:my-0">
        <div className="relative flex items-start w-full text-normal ">
          <div className={`w-14 -mt-[3px]`}>{getTimeBadge(object, tense)}</div>
          <div className="flex-1 px-2">
            <div className="flex flex-1 gap-1 leading-4">
              <div className="text-inherit">{object.summary}</div>
              {!!setInfoModalOpen && (
                <IconButton
                  className="!mt-[-5px] text-inherit"
                  size="small"
                  onClick={() => setInfoModalOpen(true)}
                >
                  <InfoIcon width={18} />
                </IconButton>
              )}
            </div>
            {object.participants && object.participants.length > 0 ? (
              <div className="mt-2">
                <div className="text-xs font-normal text-gray-600 uppercase">{`${
                  object.participants.length
                } ${object.activity.clientDescription.pluralize(
                  object.participants.length
                )}`}</div>
                {object.participants.map((participant) => (
                  <div
                    key={participant.id}
                    className="flex items-center justify-between font-normal"
                  >
                    <div className="text-sm text-gray-800">
                      {participant.name}, age {participant.age}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {isClickable && (
              <div className="absolute right-0 w-6 h-6 text-gray-600 center-y group-hover:text-gray-800">
                <ArrowForwardIcon width={24} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TodayEmpty = ({ date }) => {
  const moDate = moment(date, DATE_FMT.DATE_KEY);
  return (
    <Element
      className="px-2 py-px mx-auto sm:px-6"
      name={`${CAL_LIST_ITEM_NAME_PREFIX}-${date}`}
    >
      <div
        className={`shadow shadow-blue-300/30 py-6 px-card mx-auto space-y-6 bg-white rounded-2xl border border-blue-400 max-w-lg`}
      >
        <div>
          <h2>Today</h2>
          <h5>{moDate.format(DATE_FMT.DOW_MON_D)}</h5>
        </div>
        <div className="flex items-center mx-auto space-x-2">
          <div className="font-semibold text-gray-900 text-md">
            Nothing booked today
          </div>
          <div className="text-yellow-400">
            <SunIcon width={28} />
          </div>
        </div>
      </div>
    </Element>
  );
};

const getDateFacility = (schedule: TypedSchedulableObject[]): Facility => {
  if (!schedule || schedule.length === 0) {
    return undefined;
  } else if (schedule.some((obj) => !obj.cancelled)) {
    return schedule.find((obj) => !obj.cancelled).facility;
  } else {
    return schedule.sort((a, b) => (a.created > b.created ? -1 : 1))[0]
      .facility;
  }
};

/**
 * Given the list of schedulable objects, select the most likely
 * facility, which will either be whichever obj is uncancelled
 * or it will be the most recently created one
 * @returns most likely facility for this date
 */
const DaySchedule = ({
  date,
  schedule,
  setWeeklySchedulesFacility,
}: {
  date: string;
  schedule: TypedSchedulableObject[];
  setWeeklySchedulesFacility: React.Dispatch<React.SetStateAction<Facility>>;
}) => {
  const [blockedModalIsOpen, setBlockedModalIsOpen] = useState(false);
  const match = useRouteMatch();

  useEffect(() => {
    if (!schedule || schedule.length === 0) {
      return;
    } else if (moment().format(DATE_FMT.DATE_KEY) === date) {
      const target = `${CAL_LIST_ITEM_NAME_PREFIX}-${date}`;
      setTimeout(() => scrollToTarget(target), 250);
    }
  }, [schedule]);

  if (schedule[0].id === TODAY_EMPTY_ID) {
    return <TodayEmpty date={date} />;
  }

  const moDate = moment(date, DATE_FMT.DATE_KEY);
  const tense = getDateTense(date);
  const border = tense === Tense.Today ? 'border border-blue-400' : '';
  const confirmed = schedule?.filter((apt) => apt.cancelled === false);
  const cancelled = schedule?.filter(
    (apt) => apt.cancelled === true && apt.type === 'APPOINTMENT'
  );
  const facility = getDateFacility(schedule);

  return (
    <>
      <Element
        className="px-2 py-px mx-auto sm:px-6"
        name={`${CAL_LIST_ITEM_NAME_PREFIX}-${date}`}
      >
        <div
          className={`py-6 px-card mx-auto space-y-6 bg-white rounded-2xl max-w-lg shadow shadow-slate-300/30 ${border}`}
        >
          <div>
            <div className="flex gap-1">
              <h2>{facility.displayName}</h2>
              <IconButton
                size="small"
                onClick={() => setWeeklySchedulesFacility(facility)}
              >
                <EllipsisHorizontalCircleIcon width={24} />
              </IconButton>
            </div>
            <h5 className="font-medium">{moDate.format(DATE_FMT.DOW_MON_D)}</h5>
          </div>
          <div className="divide-y divide-gray-300 -mx-card">
            {confirmed.length > 0 ? (
              confirmed.map((object) => {
                if (object.type === 'APPOINTMENT') {
                  return (
                    <Link
                      key={object.id}
                      className="block"
                      to={`${match.url}?${QueryParams.AppointmentId}=${object.id}`}
                    >
                      <ScheduleObject object={object} isClickable={true} />
                    </Link>
                  );
                } else if (object.type === 'PROPOSAL') {
                  const url = `${match.url}?${QueryParams.ProposalId}=${object.id}`;
                  return (
                    <Link key={object.id} className="block" to={url}>
                      <ScheduleObject object={object} isClickable={true} />
                    </Link>
                  );
                } else {
                  return (
                    <ScheduleObject
                      key={object.id}
                      object={object}
                      isClickable={false}
                      setInfoModalOpen={
                        // TODO: Shouldn't be comparing against the summary as
                        //  this could change on the back end
                        object.summary === 'Blocked'
                          ? setBlockedModalIsOpen
                          : undefined
                      }
                    />
                  );
                }
              })
            ) : (
              <div className={`bg-gray-200 text-gray-700`}>
                <div className="py-3 mx-2 rounded-lg sm:mx-0 sm:my-0">
                  <div className="relative flex items-center w-full text-normal">
                    <div className="flex-1 px-card">
                      <div className="font-semibold text-gray-700">
                        Nothing scheduled
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {cancelled.length > 0 && (
            <>
              <ReadMore
                initialHeight={0}
                openBtnText={`${cancelled.length} ${'Cancellation'.pluralize(
                  cancelled.length
                )}`}
              >
                <div className="py-1 space-y-1">
                  <h5>Cancellations</h5>
                  {cancelled.map((object) => {
                    return (
                      <Link
                        key={object.id}
                        className="block"
                        to={`${match.url}?${QueryParams.AppointmentId}=${object.id}`}
                      >
                        <ScheduleObject object={object} isClickable={true} />
                      </Link>
                    );
                  })}
                </div>
              </ReadMore>
            </>
          )}
        </div>
      </Element>
      <Modal
        name="Blocked Modal"
        open={blockedModalIsOpen}
        onClose={() => setBlockedModalIsOpen(false)}
        maxWidth="xs"
        title="Blocked times"
      >
        <p>
          You have posted availability, but the pool is either full or closed at
          this time.
        </p>
        <p>
          Try adding more availability when the pool is open to get more
          bookings.
        </p>
        <Controls className="!pb-0">
          <Button variant="flat" onClick={() => setBlockedModalIsOpen(false)}>
            Close
          </Button>
        </Controls>
      </Modal>
    </>
  );
};

const normalize =
  (type: SchedulableObjectType) =>
  (obj: SchedulableObject): TypedSchedulableObject => ({
    id: obj.id,
    activity: obj.activity,
    facility: obj.facility,
    instructor: obj.instructor,
    client: obj.client,
    participants: obj.participants,
    summary: obj.summary,
    created: obj.created,
    modified: obj.modified,
    start: obj.start,
    end: obj.end,
    date: obj.date,
    time: obj.time,
    cancelled: obj.cancelled,
    timezone: obj.timezone,
    timeFrame: obj.timeFrame,
    type,
  });

const normalizeAppointments = (
  appointments: AppointmentListSerializer[]
): TypedSchedulableObject[] => appointments.map(normalize('APPOINTMENT'));
const normalizeProposals = (
  proposals: ProposalListItem[]
): TypedSchedulableObject[] => proposals.map(normalize('PROPOSAL'));
const normalizeAvailability = (
  availability: Availability[]
): TypedSchedulableObject[] =>
  availability.map((obj) =>
    obj.isBookable ? normalize('AVAILABLE')(obj) : normalize('UNAVAILABLE')(obj)
  );

const ScheduleViewMode = ({
  CalendarTools,
}: {
  CalendarTools?: JSX.Element;
}) => {
  useTimeoutRefresh();
  const [isLoading, setIsLoading] = useState(true);
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const { mode } = useParams<{ mode: string }>();
  const [schedule, setSchedule] =
    useState<[string, TypedSchedulableObject[]][]>(undefined);
  const appointments = useSelector(getAppointments);
  const proposals = useSelector(getProposals);
  const availability = useSelector(getAvailability);
  const renderDate = useSelector(getScheduleRenderDate);
  const [weeklySchedulesFacility, setWeeklySchedulesFacility] =
    useState<Facility>();
  const minDate = moment(account.liveSince).startOf('month');
  const maxDate = moment().add(6, 'months');

  const appointmentFetchState = useSelector(getAppointmentsFetchState);
  const availabilityFetchState = useSelector(getAvailabilityFetchState);
  const proposalFetchState = useSelector(getProposalsFetchState);

  const onAppointmentClose = () => {
    history.push(SHARED_ROUTES.SCHEDULE.ROOT);
  };

  const handleDayClick = (date: string) => {
    if (moment(renderDate).isSame(date, 'month')) {
      const target = `${CAL_LIST_ITEM_NAME_PREFIX}-${date}`;
      scrollToTarget(target);
    }
  };

  useEffect(() => {
    scrollToTarget('TopOfList');
    setIsLoading(true);
    setSchedule(undefined);
    const params = getMonthStartEndParams(renderDate);
    Promise.all([
      dispatch(fetchAppointments(params)),
      dispatch(fetchProposals(params)),
      dispatch(fetchAvailability(params)),
    ]);
  }, [renderDate]);

  useEffect(() => {
    const hasLoaded =
      appointmentFetchState === FETCH_STATE.IDLE &&
      availabilityFetchState === FETCH_STATE.IDLE &&
      proposalFetchState === FETCH_STATE.IDLE;
    if (hasLoaded) {
      const combinedSchedules: TypedSchedulableObject[] = [
        ...normalizeAppointments(appointments),
        ...normalizeProposals(proposals),
        ...normalizeAvailability(availability),
      ];
      const today = moment().format(DATE_FMT.DATE_KEY);
      if (
        moment(renderDate).isSame(moment(), 'month') &&
        !combinedSchedules.some((obj) => obj.date === today)
      ) {
        combinedSchedules.push({
          id: TODAY_EMPTY_ID,
          date: today,
          start: moment().format(DATE_FMT.DATETIME_FIELD),
          type: TODAY_EMPTY_ID,
          summary: 'Nothing booked today!',
        } as any);
      }
      setSchedule(
        Object.entries<TypedSchedulableObject[]>(
          combinedSchedules
            .sort((a, b) => (a.start < b.start ? -1 : 1))
            .groupBy((obj) => obj.date)
        )
      );
      setIsLoading(false);
    }
  }, [appointmentFetchState, availabilityFetchState, proposalFetchState]);

  return (
    <>
      <ScheduleTemplate
        minDate={minDate}
        maxDate={maxDate}
        isLoading={isLoading}
        mode="bookings"
        CalendarDayLoader={(props) => (
          <CalendarDayBookings {...props} onClick={handleDayClick} />
        )}
      >
        {!schedule ? (
          <ScheduleLoading />
        ) : schedule.length === 0 ? (
          <EmptySchedule />
        ) : (
          schedule.map(([date, schedule]) => (
            <DaySchedule
              key={date}
              date={date}
              schedule={schedule}
              setWeeklySchedulesFacility={setWeeklySchedulesFacility}
            />
          ))
        )}
      </ScheduleTemplate>
      <AppointmentDetail
        id={query.get(QueryParams.AppointmentId)}
        onClose={onAppointmentClose}
      />
      <ProposalDetailLoader
        id={query.get(QueryParams.ProposalId)}
        onClose={onAppointmentClose}
      />
      <Modal
        name="Facility Weekly Schedules"
        title="Weekly Schedules"
        open={weeklySchedulesFacility !== undefined}
        onClose={() => setWeeklySchedulesFacility(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <div>
          {weeklySchedulesFacility && (
            <FacilityScheduleUpdates
              facility={weeklySchedulesFacility}
              truncate={false}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default ScheduleViewMode;

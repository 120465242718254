import Button from 'components/button';
import Error from 'components/error';
import { FETCH_STATE } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useForm from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthenticationFetchState } from 'state/selectors';
import { forgotPassword, resetAuthState } from 'state/slice/authentication';

const PasswordResetForm = () => {
  const [invalid, setInvalid] = useState(false);
  const fetchState = useSelector(getAuthenticationFetchState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetAuthState());
  }, []);

  const onSubmit = () => {
    setInvalid(false);
    const email = inputs.email.trim().toLowerCase();
    if (email === '') {
      setInvalid(true);
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setInvalid(true);
    } else {
      dispatch(forgotPassword({ email }));
    }
  };

  const initialData = {
    email: '',
  };
  const { inputs, handleInputChange, handleSubmit } = useForm(
    initialData,
    onSubmit
  );

  return (
    <form className="mt-8" onSubmit={handleSubmit}>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            required
            autoComplete="email"
            className={`mt-2 ${invalid ? 'border-red-600' : ''}`}
            onChange={handleInputChange}
            value={inputs.email}
            maxLength={254}
          />
          {invalid && <Error>Email address is invalid</Error>}
        </div>
        <div className="col-span-6 text-sm">
          <Button
            disabled={fetchState === FETCH_STATE.POST}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={true}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PasswordResetForm;

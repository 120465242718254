import Add from '@mui/icons-material/Add';
import api from 'api';
import { ClientAccount } from 'api/Serializers/Clients';
import { PaymentMethodSerializer } from 'api/Serializers/PaymentMethods';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import Callout from 'components/callout';
import Card from 'components/card';
import Controls from 'components/controls';
import { getBrandDisplayName } from 'components/credit-card-brand';
import CreditCardDetails from 'components/credit-card-details';
import CreditCardForm from 'components/credit-card-form';
import InputSuggestPlace from 'components/input-suggest-place';
import Loading from 'components/loading';
import Logo from 'components/logo';
import Modal from 'components/modal';
import { QueryParams } from 'config';
import useAccountPatch from 'hooks/useAccountPatch';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import Address from 'models/geo';
import { AccountRouteParams } from 'models/route';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAccountCredit,
  getAccountDetail,
  getUsername,
} from 'state/selectors';
import { retrieveAccount } from 'state/slice/account';
import { getAddressString } from 'utils/geo';
import { APP_ROUTES, SHARED_ROUTES } from 'utils/routing';

const UpdateAddress = () => {
  const dispatch = useAppDispatch();
  const account = useSelector(getAccountDetail);
  const [address, setAddress] = useState<Address>();
  const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const { handleAccountUpdate } = useAccountPatch();

  return (
    <Card title="Your location" maxWidth="lg">
      <div className="flex flex-col gap-y-4">
        <div>
          <p>
            Update your location to get better recommendations for pools in your
            area.
          </p>
          <div>
            <label>Postal code or address</label>
            <InputSuggestPlace
              id="address"
              name="address"
              placeholder="Postal code or address"
              className={addressError ? 'border-red-600' : 'border-blue-500'}
              initialValue={getAddressString(account.address)}
              error={addressError}
              types={['geocode']}
              onChange={() => {
                if (addressError) {
                  setAddressError(false);
                }
                if (address !== undefined) {
                  setAddress(undefined);
                }
              }}
              onSuggestSelect={(elt) => {
                if (!elt?.placeId) return;
                setAddress({
                  line1: elt.line1 ?? '',
                  city: elt.city,
                  region: elt.region,
                  regionCode: elt.regionCode,
                  country: elt.country,
                  countryCode: elt.countryCode,
                  postalCode: elt.postalCode,
                });
              }}
              disabled={isSubmittingAddress}
            />
            {addressError && (
              <div className="text-red-600">
                Select from the options presented
              </div>
            )}
          </div>
        </div>
        <Controls className="!pb-0">
          <Button
            color="primary"
            variant="flat"
            onClick={async () => {
              if (address === undefined) {
                setAddressError(true);
                return;
              }
              setIsSubmittingAddress(true);
              try {
                await handleAccountUpdate({ address });
              } catch (err) {
                enqueueSnackbar({
                  message:
                    "We're having trouble updating your account. Please refresh and try again.",
                  variant: 'error',
                });
              }
              try {
                await dispatch(retrieveAccount(account.username));
                enqueueSnackbar({
                  message: 'Your location has been updated',
                  variant: 'success',
                });
                setIsSubmittingAddress(false);
              } catch (err) {
                location.reload();
              }
            }}
            isLoading={isSubmittingAddress}
          >
            Update
          </Button>
        </Controls>
      </div>
    </Card>
  );
};

const PaymentMethods = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const credit = useSelector(getAccountCredit);
  const account = useSelector(getAccountDetail) as ClientAccount;
  const username = useSelector(getUsername);
  const [isUpdating, setIsUpdating] = useState(false);
  const [toDelete, setToDelete] = useState<PaymentMethodSerializer>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDeleteFailed, setShowDeleteFailed] = useState(false);
  const { params, ...rest } = useRouteMatch<AccountRouteParams>();
  const isCreating = params.action === 'payment';

  useEffect(() => {
    if (query.get(QueryParams.NewCredit)) {
      enqueueSnackbar({
        message: 'Credit has been applied to your account!',
        variant: 'success',
      });
    }
  }, []);

  const handleAddPaymentClose = () => {
    history.push(SHARED_ROUTES.SETTINGS.ROOT);
  };

  const handleSetDefaultCard = async (id: string) => {
    try {
      setIsUpdating(true);
      await api.paymentMethods.default(id);
      await dispatch(retrieveAccount(username));
      enqueueSnackbar({
        message: 'Default payment method updated',
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar({
        message: 'Could not change default payment method; try again',
        variant: 'error',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteCard = async (id: string) => {
    try {
      setIsUpdating(true);
      await api.paymentMethods.delete(id);
      await dispatch(retrieveAccount(username));
      enqueueSnackbar({
        message: 'Payment method removed',
        variant: 'success',
      });
    } catch (err) {
      if ((err as any).response?.status === 422) {
        setShowDeleteFailed(true);
      } else {
        enqueueSnackbar({
          message: 'Error while removing payment method; try again',
          variant: 'error',
        });
      }
    } finally {
      setIsUpdating(false);
    }
  };
  return (
    <>
      {isUpdating && (
        <Loading position="absolute" message="Updating payment methods..." />
      )}
      <Card
        className={isUpdating ? 'pointer-events-none opacity-50' : ''}
        title="Payment methods"
        maxWidth="lg"
      >
        <div className="flex flex-col mb-4">
          {account.paymentMethods?.length ? (
            account.paymentMethods.map((pm) => (
              <div
                className="flex justify-between py-6 border-t border-gray-300 last:border-b"
                key={pm.id}
              >
                <CreditCardDetails paymentMethod={pm} />
                <div className="flex flex-col items-end justify-center space-y-2">
                  {!pm.isDefault && (
                    <button
                      className="text-sm !no-underline link"
                      onClick={() => handleSetDefaultCard(pm.id)}
                    >
                      Set Default
                    </button>
                  )}
                  <button
                    className="text-sm text-red-700 !no-underline link hover:text-red-600"
                    onClick={() => {
                      setToDelete(pm);
                      setShowConfirmDelete(true);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <Callout
              className="w-full"
              type="warning"
              title="Nothing here yet!"
            >
              Click "Add" to securely save new payment details to your Propel
              account
            </Callout>
          )}
          <div className="flex justify-between py-6 border-t border-gray-300 last:border-b">
            <div className="flex items-center gap-4">
              <div>
                <div
                  className={`w-12 flex justify-center overflow-hidden text-center border border-gray-400 rounded`}
                >
                  <Logo diameter={10} />
                </div>
              </div>
              <div className="space-y-0">
                <div className="text-base font-semibold">Propel Credit</div>
                <div className="space-x-3 text-sm text-gray-600">
                  Never Expires
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center space-y-2">
              {credit.toCurrency()}
            </div>
          </div>
        </div>
        <Controls className="!pb-0 !mb-0">
          <Button
            variant="flat"
            icon={<Add />}
            to={SHARED_ROUTES.SETTINGS.nav('payment')}
            onClick={() => history.push(SHARED_ROUTES.SETTINGS.nav('payment'))}
          >
            Add
          </Button>
        </Controls>
      </Card>
      <Modal
        name="Client — Add payment method"
        open={isCreating}
        onClose={handleAddPaymentClose}
        title="Add payment method"
      >
        <CreditCardForm
          onCreated={handleAddPaymentClose}
          onClose={handleAddPaymentClose}
        />
      </Modal>
      <Modal
        name="Client — Remove payment method"
        title="Remove payment method"
        maxWidth="sm"
        open={showConfirmDelete}
        onClose={() => {
          setToDelete(undefined);
          setShowConfirmDelete(false);
        }}
      >
        <p>
          Your {getBrandDisplayName(toDelete?.brand)} ending in{' '}
          <strong>{toDelete?.last4}</strong> will be removed.
        </p>
        <Controls className="!mt-6 !mb-0">
          <Button
            variant="flat"
            onClick={() => {
              setToDelete(undefined);
              setShowConfirmDelete(false);
            }}
          >
            Go back
          </Button>
          <Button
            variant="flat"
            color="secondary"
            onClick={() => {
              handleDeleteCard(toDelete.id);
              setShowConfirmDelete(false);
            }}
          >
            Remove
          </Button>
        </Controls>
      </Modal>
      <Modal
        name="Client — Remove payment method (Blocked)"
        title="Remove payment method"
        maxWidth="sm"
        open={showDeleteFailed}
        onClose={() => setShowDeleteFailed(false)}
      >
        <Callout type="error" title="Cannot remove payment method">
          This payment method is attached to one or more unpaid bookings and
          cannot be removed.
        </Callout>
        <p>
          To continue, cancel or change payment methods with the appointments
          that use this card and try again.
        </p>
        <Controls className="!mt-6 !mb-0">
          <Button variant="flat" onClick={() => setShowDeleteFailed(false)}>
            Go back
          </Button>
          <Button
            variant="flat"
            color="primary"
            onClick={() => {
              history.push(APP_ROUTES.ACCOUNT);
              setShowDeleteFailed(false);
            }}
          >
            Go to schedule
          </Button>
        </Controls>
      </Modal>
    </>
  );
};

const Settings = () => {
  return (
    <Dashboard title="Settings">
      <PaymentMethods />
      <UpdateAddress />
    </Dashboard>
  );
};

export default Settings;

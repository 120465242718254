import api from 'api';
import Avatar from 'components/avatar';
import Button from 'components/button';
import CommonQuestions from 'components/common-questions';
import Hero from 'components/hero';
import Link from 'components/link';
import EmailMarketingSignUp from 'containers/email-marketing-sign-up';
import Page from 'containers/page';
import { PlaceIcon, StarFilledIcon } from 'icons';
import { Home as Meta } from 'metadata';
import React, { useEffect, useState } from 'react';
import { APP_ROUTES } from 'utils/routing';

export const Locations = [
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvMjAxOC0xMi03L2FmMGYyOGNjLTk1Y2EtNGRmYS04NmRlLTI1OWRiNTFkODRhMC5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA1NjAsICJoZWlnaHQiOiAzMTV9fX0=',
    to: `${APP_ROUTES.BROWSE_CITIES}/vancouver-bc`,
    region: 'Vancouver, BC',
    displayName: 'Residence Inn',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvbWFudWFsL2JjLXJpY2htb25kLWNlbnRyYWwvcmljaG1vbmQgY2VudHJhbCAtIGVkaXRlZC5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA1NjAsICJoZWlnaHQiOiAzMTV9fX0=',
    to: `${APP_ROUTES.BROWSE_CITIES}/richmond-bc`,
    region: 'Richmond, BC',
    displayName: 'River Rock Casino Resort',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvMjAxOC0xLTMwL2ZjMDA0MjM1LWMzNWItNDUyNS1hMjQyLWFiMTU4ZGVhNDk2MC5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA1NjAsICJoZWlnaHQiOiAzMTV9fX0=',
    to: `${APP_ROUTES.BROWSE_CITIES}/burnaby-bc`,
    region: 'Burnaby, BC',
    displayName: 'Fitness 2000',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvbWFudWFsL2JjLWxhbmdsZXktZmVybnJpZGdlL2JjLWxhbmdsZXktZmVybnJpZGdlLTEuanBnIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNTYwLCAiaGVpZ2h0IjogMzE1fX19',
    to: `${APP_ROUTES.BROWSE_CITIES}/langley-bc`,
    region: 'Langley, BC',
    displayName: 'Langley Fernridge',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvMjAxOC0xMi0xMC8yZTM0ZGJhYS0wNTkyLTRhYTUtYTBjNi0zNTQ5ZmI2OThkOGQuanBnIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNTYwLCAiaGVpZ2h0IjogMzE1fX19',
    to: `${APP_ROUTES.BROWSE_CITIES}/toronto-on`,
    region: 'Toronto, ON',
    displayName: 'DoubleTree by Hilton',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvMjAxOS0wMi0yMS8wYWJjNjI3NC03MWExLTRjZWItYTJmYS00MTFlZGZkNDBkZjkuanBnIiwgImVkaXRzIjogeyJyZXNpemUiOiB7IndpZHRoIjogNTYwLCAiaGVpZ2h0IjogMzE1fX19',
    to: `${APP_ROUTES.BROWSE_CITIES}/yorkville-on`,
    region: 'Yorkville, ON',
    displayName: 'Yorkville Royal Sonesta',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvbWFudWFsL3NoZXJhdG9uLXN1aXRlcy1jYWxnYXJ5LWVhdS1jbGFpcmUtMC5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA1NjAsICJoZWlnaHQiOiAzMTV9fX0=',
    to: `${APP_ROUTES.BROWSE_CITIES}/calgary-ab`,
    region: 'Calgary, AB',
    displayName: 'Sheraton Suites Eau Claire',
  },
  {
    srcSm:
      'https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiAib2xla3NpYWsiLCAia2V5IjogInVwbG9hZHMvbWFudWFsL2FiLXJlZC1kZWVyLWRhdmVucG9ydC9hYi1yZWQtZGVlci1kYXZlbnBvcnQtMS5qcGciLCAiZWRpdHMiOiB7InJlc2l6ZSI6IHsid2lkdGgiOiA1NjAsICJoZWlnaHQiOiAzMTV9fX0=',
    to: `${APP_ROUTES.BROWSE_CITIES}/calgary-ab`,
    region: 'Red Deer, AB',
    displayName: 'Red Deer Davenport',
  },
];

const InstructorTypes = [
  {
    src: 'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci10eXBlcy10b2RkbGVycy5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
    to: APP_ROUTES.BROWSE_CITIES,
    title: 'Toddlers (2 yrs+)',
    minScreen: 'xs',
  },
  {
    src: 'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci10eXBlcy1jaGlsZHJlbi5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9',
    to: APP_ROUTES.BROWSE_CITIES,
    title: 'Children',
    minScreen: 'xs',
  },
  {
    src: 'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci10eXBlcy1hZHVsdHMucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ==',
    to: APP_ROUTES.BROWSE_CITIES,
    title: 'Adults & Teens',
    minScreen: 'xs',
  },
  {
    src: 'https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci10eXBlcy1hZGFwdGVkLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0=',
    to: APP_ROUTES.BROWSE_CITIES,
    title: 'Adapted Aquatics',
    minScreen: 'xs',
  },
];

const HomePage = () => (
  <Page {...Meta}>
    <div className="landing-page">
      <Hero
        background={`https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hlcm9zL2NoaWxkLXNwbGFzaC5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjE0MDAsImhlaWdodCI6MTAwMH19LCJidWNrZXQiOiJvbGVrc2lhayJ9`}
        backgroundPosition="72% 0%"
        title="Learn to swim — hassle free"
      >
        <div className="max-w-2xl">
          <div className="">
            <h2 className="text-xl font-semibold text-white md:text-2xl drop-shadow">
              Book swim lessons anytime with experienced instructors
            </h2>
            <h4 className="font-normal text-white text-opacity-90 text-md md:text-lg drop-shadow">
              No registration days • No complicated policies • No hidden fees
            </h4>
          </div>
          <div className="flex justify-center my-4 sm:justify-start">
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="!h-16 mt-4 mr-4"
              to={APP_ROUTES.BROWSE_CITIES}
              icon={<PlaceIcon width={28} className="text-white" />}
            >
              Find nearby locations
            </Button>
          </div>
        </div>
      </Hero>
      <section className="!py-16 !space-y-4 bg-background">
        <h3 className="subtitle !text-gray-600">As featured on</h3>
        <div className="w-full py-4 mx-auto overflow-x-auto">
          <div className="flex justify-between w-full max-w-3xl gap-8 mx-auto min-w-[720px] opacity-70 grayscale">
            <div className="flex items-center mx-auto">
              <img
                alt="Toronto Star Feature"
                src={urlToImageServerUrl(
                  '/assets/images/icons/home_news_toronto.png',
                  131 * 2,
                  32 * 2
                )}
                style={{ height: 32 }}
              />
            </div>
            <div className="flex items-center mx-auto">
              <img
                alt="Global News Feature"
                src={urlToImageServerUrl(
                  '/assets/images/icons/home_news_global.png',
                  90 * 2,
                  50 * 2
                )}
                style={{ height: 50 }}
              />
            </div>
            <div className="flex items-center mx-auto">
              <img
                alt="CBC News Feature"
                src={urlToImageServerUrl(
                  '/assets/images/icons/home_news_cbc.png',
                  57 * 2,
                  51 * 2
                )}
                style={{ height: 51 }}
              />
            </div>
            <div className="flex items-center mx-auto">
              <img
                alt="Startup Toronto Feature"
                src={urlToImageServerUrl(
                  '/assets/images/icons/home_news_startup.png',
                  94 * 2,
                  50 * 2
                )}
                style={{ height: 50 }}
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                alt="Vancouver Sun Feature"
                src={urlToImageServerUrl(
                  '/assets/images/icons/home_news_vancouver.png',
                  150 * 2,
                  24 * 2
                )}
                style={{ height: 24 }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div>
          <h2 className="title">Never deal with a waitlist again</h2>
          <h3 className="subtitle">
            Propel is the easiest way to book flexible, effective swimming
            lessons, right now.
          </h3>
        </div>
        <div>
          <div className="flex flex-col justify-between max-w-sm mx-auto space-y-14 sm:space-y-0 sm:max-w-5xl sm:space-x-8 md:space-x-16 sm:flex-row">
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 100 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvaW5zdHJ1Y3Rvci1pY28tbGcucG5nIiwiYnVja2V0Ijoib2xla3NpYWsifQ=="
              />
              <h4 className="heading">Pick an instructor, not a class</h4>
              <div>
                Select from top rated swim instructors and book hour long
                lessons that you can enjoy by yourself or with a friend.
              </div>
            </div>
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 100 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvY2FsZW5kYXItaWNvLWxnLnBuZyIsImJ1Y2tldCI6Im9sZWtzaWFrIn0="
              />
              <h4 className="heading">Fit swim lessons into your life</h4>
              <div>
                Make your own lesson schedule with no registration days or rigid
                lesson sets. Reschedule or cancel anytime online.
              </div>
            </div>
            <div className="flex-1 text-center sm:text-left">
              <img
                style={{ width: 79 }}
                className="mx-auto mb-2"
                src="https://oleksiak.propelhq.com/v4/eyJrZXkiOiJhc3NldHMvaW1hZ2VzL2hvbWUvY2hlY2tsaXN0LWljby1sZy5wbmciLCJidWNrZXQiOiJvbGVrc2lhayJ9"
              />
              <h4 className="heading">Level up at your own pace</h4>
              <div>
                Progress through levels faster with direct and personalized
                feedback throughout every lesson.
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button
            variant="contained"
            shape="rounded-full"
            size="large"
            color="primary"
            to={APP_ROUTES.BROWSE_CITIES}
          >
            Book your first lesson
          </Button>
        </div>
      </section>
      <section className="bg-background">
        <div>
          <h2 className="title">An environment for learning</h2>
          <h3 className="subtitle">
            Learn in a quiet setting, often with free amenities like wifi or
            towel service.
          </h3>
        </div>
        <div className="image-grid">
          {Locations.map((item, key) => (
            <div key={key} className="space-y-2 text-center">
              <Link to={item.to}>
                <img src={item.srcSm} className="rounded-xl" />
                <h5>{item.region}</h5>
              </Link>
            </div>
          ))}
        </div>
        <div>
          <Button
            size="large"
            to={APP_ROUTES.BROWSE_CITIES}
            variant="contained"
            color="primary"
          >
            Find a location near you
          </Button>
        </div>
      </section>
      <section className="bg-white">
        <div>
          <h2 className="title">An instructor for everyone</h2>
          <h3 className="subtitle">
            Find instructors with the skills to help you gain independence in
            water, from toddlers to adults.
          </h3>
        </div>
        <div className="image-grid">
          {InstructorTypes.map((item, key) => (
            <div key={key} className="space-y-2 text-center">
              <img src={item.src} className="rounded-xl" />
              <h5>{item.title}</h5>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Button
            to={APP_ROUTES.LEARN_MORE.SWIMMERS}
            variant="flat"
            color="primary"
            size="large"
          >
            See how it works
          </Button>
        </div>
      </section>
      <section className="bg-background">
        <WallOfLove />
      </section>
      <section className="bg-white">
        <EmailMarketingSignUp />
      </section>
      <CommonQuestions type="client" />
    </div>
  </Page>
);

const WallOfLove = () => {
  const [ratings, setRatings] = useState<{ count: number; average: number }>();

  useEffect(() => {
    (async () => {
      const response = await api.reviews.ratings();
      setRatings(response.data);
    })();
  }, []);
  return (
    <>
      <div>
        <h2 className="title">Wall of love</h2>
        <h3 className="subtitle">
          60,000+ lessons delivered & thousands of happy swimmers
        </h3>
      </div>
      <div className="flex justify-center gap-3">
        <div className="flex-col hidden gap-3 sm:flex">
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTEuanBnIn0="
            reviewer="Mandy M."
            comment="I took only two lessons and now I feel completely confident to go swim laps on my own."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTIuanBnIn0="
            reviewer="Ramona M."
            comment="My girls both had fears of water. They are now so calm, and confident. This has been a beautiful journey to watch."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTMucG5nIn0="
            reviewer="Miriam W."
            comment="This is a game changer for us."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTQucG5nIn0="
            reviewer="Felicia F."
            comment="I really like the idea. Love love love the flexibility."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTUuanBnIn0="
            reviewer="Kim S."
            comment="The smile on my daughter's face when we left was worth everything. 🥰"
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTYucG5nIn0="
            reviewer="Megan M."
            comment="It took swimming lessons from a stressful experience to a really fun evening with my kids."
          />
        </div>
        <div className="flex flex-col gap-3">
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTcuanBnIn0="
            reviewer="Rebeca D."
            comment="My kids learned more in a few lessons than years in a different swimming school."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTgucG5nIn0="
            reviewer="Kelly B."
            comment="Well worth the money for the 1 on 1 and time saved 🤩🤩."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTkucG5nIn0="
            reviewer="Liv H."
            comment="Registration is simple and there's no need to line up or do waitlists like at other private swimming places."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTEwLnBuZyJ9"
            reviewer="Pau L."
            comment="Highly recommend for children on the spectrum!"
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTExLmpwZyJ9"
            reviewer="Stacey M."
            comment="Being an adult learning how to swim again was a bit embarrassing and intimidating but Propel made it so easy!"
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTEyLmpwZyJ9"
            reviewer="Haider A."
            comment="This will help me throughout my life later on where ever I go."
          />
        </div>
        <div className="flex-col hidden gap-3 lg:flex">
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTEzLmpwZyJ9"
            reviewer="Ronnalee H."
            comment="We've wasted enough money with group lessons [...] My kids have come so far, so fast with private lessons."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTE0LmpwZyJ9"
            reviewer="Jamie J."
            comment="LOVE Propel!! Great way to book swimming lessons on the fly!! Works so well with our schedule."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTE1LmpwZyJ9"
            reviewer="Daniel B."
            comment="My kiddo was nervous going in, but was all smiles throughout!"
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTE2LnBuZyJ9"
            reviewer="Alix L."
            comment="We love Propel. Its so easy to use, the instructors are amazing, 10/10."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTE3LnBuZyJ9"
            reviewer="J L."
            comment="I love getting coached by the same instructor throughout my lessons."
          />
          <GoogleReviewCard
            avatar="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9hdmF0YXJzL3dhbGwtb2YtbG92ZS93b2wtYXZhdGFyLTE4LmpwZyJ9"
            reviewer="Katie B."
            comment="My daughter is very proud of herself."
          />
        </div>
      </div>
      <div className="flex flex-col items-center !mt-20 gap-12">
        <div className="w-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 326667 333333"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path
              d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
              fill="#4285f4"
            />
            <path
              d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
              fill="#34a853"
            />
            <path
              d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
              fill="#fbbc04"
            />
            <path
              d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
              fill="#ea4335"
            />
          </svg>
        </div>
        {ratings !== undefined && ratings.average >= 4.8 && (
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center gap-3">
              <div className="flex gap-1">
                <StarFilledIcon className="text-yellow-400" width={48} />
                <StarFilledIcon className="text-yellow-400" width={48} />
                <StarFilledIcon className="text-yellow-400" width={48} />
                <StarFilledIcon className="text-yellow-400" width={48} />
                <StarFilledIcon className="text-yellow-400" width={48} />
              </div>
              <span className="text-3xl font-semibold">{ratings.average}</span>
            </div>
            <span>
              Based on {ratings.count - (ratings.count % 50)}+ reviews
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const GoogleReviewCard = ({
  reviewer,
  avatar,
  // location,
  comment,
}: {
  reviewer: string;
  avatar: string;
  // location: string;
  comment: string;
}) => {
  return (
    <div className="card !p-4 max-w-xs">
      <div className="flex flex-col gap-3">
        <div className="flex gap-3">
          <Avatar src={avatar} diameter={16} />
          <p className="m-0 text-md">{comment}</p>
        </div>
        <div className="text-sm text-right text-gray-600">
          — {reviewer}
          {/* {reviewer} in {location} */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;

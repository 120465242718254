import InstructorAccount from 'api/Serializers/Accounts/Instructor';
import { AppointmentListSerializer } from 'api/Serializers/Appointments';
import Dashboard from 'components/account/dashboard';
import Button from 'components/button';
import CardActions from 'components/card-actions';
import Controls from 'components/controls';
import Link from 'components/link';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  DATE_FMT,
  FETCH_STATE,
  INSTRUCTOR_LATE_CANCELLATION_RATE_MAX,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_AVG,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE,
  INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX,
  QueryParams,
} from 'config';
import { ScheduleObject } from 'features/schedule/as-instructor/bookings';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { HelpIcon } from 'icons';
import moment from 'moment-timezone';
import { INSTRUCTOR_ROUTES } from 'pages/account/instructor/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountDetail,
  getAccountEarnings,
  getAccountEarningsFetchState,
  getAccountReliability,
  getEarlyAccessWindows,
  getInstructorOnboarding,
  getUpcomingAppointments,
} from 'state/selectors';
import { fetchEarlyAccessWindows } from 'state/slice/account';
import { fetchNextAppointments } from 'state/slice/appointments';
import { LocalStore } from 'state/storage';
import { isTaxSeason } from 'utils/date';
import { EXTERNAL_ROUTES, SHARED_ROUTES, SHOW_HELP } from 'utils/routing';

type ModalType =
  | 'Cancellation rate'
  | 'Hourly earnings'
  | 'Equipment bonus'
  | 'Repeat clients';

const Overview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [futureDates, setFutureDates] = useState<
    [string, AppointmentListSerializer[]][]
  >([]);
  const dispatch = useAppDispatch();
  const appointments = useSelector(getUpcomingAppointments);
  const earlyAccessWindows = useSelector(getEarlyAccessWindows);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dispatch(fetchNextAppointments()),
      dispatch(fetchEarlyAccessWindows()),
    ]).then((responses) => {
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    const today = moment().format(DATE_FMT.DATE_KEY);
    setFutureDates(
      Object.entries(
        appointments
          .filter((apt) => !apt.cancelled && apt.date >= today)
          .groupBy((apt) => apt.date)
      )
    );
  }, [appointments]);
  if (isLoading) {
    return <Loading message="Getting updates..." />;
  }
  return (
    <div className="space-y-4">
      {earlyAccessWindows.length > 0 && (
        <div className="card">
          <h2>Schedule early access</h2>
          {earlyAccessWindows.map((earlyAccessWindow) => {
            return (
              <div key={earlyAccessWindow.id}>
                <p>
                  {earlyAccessWindow.facility.displayName} has a new schedule,
                  and you have early access!
                </p>
                <p>
                  Starting{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.startDate).format(
                      DATE_FMT.DOW_MONTH_D
                    )}
                  </span>{' '}
                  until end of day{' '}
                  <span className="font-semibold">
                    {moment(earlyAccessWindow.endDate).format(DATE_FMT.MONTH_D)}
                  </span>
                  , you have exclusive access to send proposals to your clients.
                </p>
                <p>
                  The schedule opens for general availability on{' '}
                  {moment(earlyAccessWindow.rollout.publicAccessDate).format(
                    DATE_FMT.DOW_MONTH_D_YEAR
                  )}
                  .
                </p>
              </div>
            );
          })}
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
            >
              Open Calendar
            </Button>
          </CardActions>
        </div>
      )}
      <div className="card">
        <h2>Next-up</h2>
        {futureDates.length === 0 ? (
          <div className="p-4 my-4 text-gray-500 bg-gray-200 rounded-lg">
            No upcoming bookings
          </div>
        ) : (
          <div className="space-y-4">
            {futureDates.slice(0, 3).map(([date, appointments]) => {
              const isToday = moment().isSame(date, 'date');
              const dDays = Math.abs(moment().diff(date, 'days')) + 1;
              const preHeader = isToday
                ? 'Today'
                : dDays === 1
                ? 'Tomorrow'
                : `In ${dDays} ${'day'.pluralize(dDays)}`;
              return (
                <div key={date}>
                  <h6>{preHeader}</h6>
                  <h5>
                    {appointments[0].facility.displayName},{' '}
                    {moment(date).format(DATE_FMT.DOW_MON_D)}
                  </h5>
                  <div className="-mx-6 divide-y divide-gray-300">
                    {appointments.slice(0, 3).map((appointment) => (
                      <Link
                        key={appointment.id}
                        className="block"
                        to={`${SHARED_ROUTES.SCHEDULE.ROOT}?${QueryParams.AppointmentId}=${appointment.id}`}
                      >
                        <ScheduleObject
                          object={{ ...appointment, type: 'APPOINTMENT' }}
                          isClickable={true}
                        />
                      </Link>
                    ))}
                    {appointments.length > 3 && (
                      <div className="px-4 py-2 m-2 text-base italic">{`And ${
                        appointments.length - 3
                      } more booked this day...`}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            to={INSTRUCTOR_ROUTES.SCHEDULE.ROOT}
          >
            Open Calendar
          </Button>
        </CardActions>
      </div>
    </div>
  );
};

const CardHelpButton = ({ onClick }) => (
  <span className="absolute top-0 right-0">
    <button
      onClick={onClick}
      className="text-gray-600 transition-colors duration-150 hover:text-blue-500"
    >
      <HelpIcon width={24} />
    </button>
  </span>
);

const ReliabilityKPI = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const reliability = useSelector(getAccountReliability);
  const recentCancellationRate =
    Number(reliability?.recentCancellationRate) ?? 0;
  const lateCancellationRate = Number(reliability?.lateCancellationRate) ?? 0;
  const overallColor =
    lateCancellationRate > INSTRUCTOR_LATE_CANCELLATION_RATE_MAX
      ? 'red'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX
      ? 'red'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_AVG
      ? 'yellow'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE
      ? 'blue'
      : 'green';
  const overallTitle =
    lateCancellationRate > INSTRUCTOR_LATE_CANCELLATION_RATE_MAX
      ? 'High late cancellation rate'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX
      ? 'Very poor'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_AVG
      ? 'Above average'
      : recentCancellationRate > INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE
      ? 'Very good'
      : 'Top performer';
  return (
    <>
      <div className="card">
        <div className="mb-4">
          <CardHelpButton onClick={() => setShowModal(true)} />
          <span className="text-5xl font-extrabold leading-none">
            {reliability
              ? reliability.recentAppointments > 0
                ? `${Math.round(
                    (reliability.recentCancellations /
                      reliability.recentAppointments) *
                      100
                  )}%`
                : '0%'
              : '—'}
          </span>
          <div>
            <span className={`badge ${overallColor}`}>{overallTitle}</span>
          </div>
        </div>
        <div>
          <h5 className="text-gray-700">Cancellation rate</h5>
          {reliability && (
            <>
              <div className="text-gray-600">
                {reliability.recentCancellations} out of{' '}
                {reliability.recentAppointments} in the last 60 days
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        name={`Instructor Dashboard — Cancellation rate`}
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Cancellation rate"
        maxWidth="xs"
      >
        <div className="space-y-5">
          <div>
            <h2>
              Overall cancellation rate:{' '}
              {(recentCancellationRate * 100).toFixed(1)}%
            </h2>
            <p>
              Instructors with low cancellation rates attract and retain more
              clients. Your cancellation rate may be used to determine your
              profile rankings, recommendations, or visibility on Propel pool
              pages.
            </p>
            <p>
              Profiles with a cancellation rate more than{' '}
              <strong>
                {(INSTRUCTOR_OVERALL_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
              </strong>{' '}
              may not be recommended and won't show on pool pages.
            </p>
          </div>
          <div>
            <h2>
              Late cancellation rate: {(lateCancellationRate * 100).toFixed(1)}%
            </h2>
            <p>
              Just like instructors, hosts require payment for cancellations
              made within 48 hours.
            </p>
            <p>
              When you are responsible to pay for admission, Propel will
              reimburse you if your Late Cancellation Rate is{' '}
              <strong>
                {(INSTRUCTOR_LATE_CANCELLATION_RATE_MAX * 100).toFixed(1)}%
              </strong>{' '}
              or less.
            </p>
          </div>
          <div>
            <h2>Best practices</h2>
            <p>
              While everyone should strive for zero cancellations, Propel's top
              instructors stay well below{' '}
              {(INSTRUCTOR_OVERALL_CANCELLATION_RATE_ELITE * 100).toFixed(1)}%
            </p>
          </div>
          <Controls>
            <Button variant="flat" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

const KPIs = () => {
  const account = useSelector(getAccountDetail) as InstructorAccount;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>();
  const reliability = useSelector(getAccountReliability);
  const earningsData = useSelector(getAccountEarnings);
  const earningsFetchState = useSelector(getAccountEarningsFetchState);
  const earnings = earningsData ? earningsData[0] : undefined;
  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => setModalType(undefined), 500);
  };
  const handleOpenModal = (type: ModalType) => () => {
    setModalType(type);
  };

  const recentCancellationRate =
    Number(reliability?.recentCancellationRate) ?? 0;

  useEffect(() => {
    // This seems unnecessary, but the aim
    // is to ensure that the modalType is
    // always set in the `name` attribute
    // of the Modal, so that tracking events
    // do not have " — undefined" in the modal name
    if (modalType) {
      setShowModal(true);
    }
  }, [modalType]);

  return (
    <>
      <div className="grid gap-6 sm:grid-cols-2">
        <ReliabilityKPI />
        {earningsFetchState === FETCH_STATE.GET ? (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Hourly earnings')} />
              <span className="text-5xl font-extrabold leading-none">—</span>
            </div>
            <div>
              <h5 className="text-gray-700">Hourly earnings</h5>
            </div>
          </div>
        ) : !earnings || earnings.numPeriods === 0 ? (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Equipment bonus')} />
              <span className="text-5xl font-extrabold leading-none">$100</span>
            </div>
            <div>
              <h5 className="text-gray-700">Equipment bonus</h5>
              <div className="text-gray-600">
                Request your $100 bonus when you teach your fifth lesson
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="mb-4">
              <CardHelpButton onClick={handleOpenModal('Hourly earnings')} />
              <span className="text-5xl font-extrabold leading-none">
                {earnings ? earnings.effectiveRate.toCurrency(false) : '—'}
                <span className="font-bold text-md">/hr</span>
              </span>
            </div>
            <div>
              <h5 className="text-gray-700">Hourly earnings</h5>
              {earnings && (
                <div className="text-gray-600">
                  {earnings.netEarnings.toCurrency(false)} over{' '}
                  {earnings.numComplete} lessons taught
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        name={`Instructor Dashboard — ${modalType}`}
        open={showModal}
        onClose={handleCloseModal}
        title={modalType}
        maxWidth="xs"
      >
        {modalType === 'Hourly earnings' ? (
          <div>
            <p>
              This is the total you've earned after fee deductions divided by
              the number of lessons you actually taught.
            </p>
            <p>
              Gift cards and lesson credit you've chosen to give to clients are
              not included in this total.
            </p>
            <Controls>
              {/* <Button>Learn more</Button> */}
              <Button variant="flat" onClick={handleCloseModal}>
                Close
              </Button>
            </Controls>
          </div>
        ) : modalType === 'Equipment bonus' ? (
          <div>
            <p>
              After you teach your fifth lesson, you are eligible for a $100
              equipment bonus!
            </p>
            <p>
              We recommend putting it towards{' '}
              <Link to={EXTERNAL_ROUTES.BLOG.EQUIPMENT_LIST}>
                lesson equipment
              </Link>{' '}
              to use with your clients, ensuring you have everything you need to
              teach and impress them.
            </p>
            <p>
              Sign up using the button below and get the bonus on your next
              payout.
            </p>
            <Controls>
              <Button variant="flat" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                variant="flat"
                color="primary"
                to={EXTERNAL_ROUTES.EQUIPMENT_BONUS_SIGNUP}
              >
                Sign me up
              </Button>
            </Controls>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export const TaxNotice = () => {
  const [inc, setInc] = useState(0);
  const thisYear = moment().year();
  const lastYear = thisYear - 1;
  const taxInfoLastHidden: number =
    LocalStore.getPreference('taxInfoLastHidden');
  const showTaxNotice = isTaxSeason() && taxInfoLastHidden !== thisYear;
  const handleHide = () => {
    setInc(inc + 1); // this causes a rerender
    LocalStore.setPreference('taxInfoLastHidden', thisYear);
  };
  if (!showTaxNotice) {
    return null;
  }
  return (
    <div className="card">
      <h2 className="mb-4">Tax information</h2>
      <p>
        As a self-employed individual, you will likely need to fill out a T2125
        form in addition to your normal tax forms. To make this process easy for
        you, we provide an{' '}
        <Link to={INSTRUCTOR_ROUTES.EARNINGS.DETAIL(lastYear)} underline>
          Annual Summary
        </Link>{' '}
        that breaks down your revenue and expenses for the year.
      </p>
      <CardActions>
        {/* <Button onClick={handleHide} variant="flat">
          Dismiss
        </Button> */}
        <Button
          onClick={SHOW_HELP.TEACHING.FILING_TAXES}
          variant="flat"
          color="primary"
        >
          Learn more
        </Button>
      </CardActions>
    </div>
  );
};

const DashboardContainer = () => {
  const onboarding = useSelector(getInstructorOnboarding);

  return (
    <Dashboard title="Overview" width="3xl">
      <KPIs />
      <TaxNotice />
      <Overview />
    </Dashboard>
  );
};

export default DashboardContainer;

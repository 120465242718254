import { SunIcon } from '@heroicons/react/24/outline';
import {
  InstructorTimeSlotType,
  SchedulableObject,
  ScheduleChangeAction,
  ScheduleDateSerializer,
  Tense,
} from 'api/Serializers/Schedules';
import { DayComponentProps } from 'components/calendar';
import { DATE_FMT } from 'config';
import { FacilityIcon } from 'icons';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAppointments,
  getAvailability,
  getProposalClient,
  getProposalCreateList,
  getProposals,
  getScheduleAppointmentProduct,
  getScheduleChanges,
  getScheduleOpenings,
} from 'state/selectors';

export interface DateBoxProps extends ScheduleDateSerializer {
  onClick(date: string): void;
}

const calChipBase =
  'py-0.5 px-1 rounded-full text-xxs font-medium whitespace-nowrap leading-none';
const calChipText = 'py-0.5 overflow-hidden';
const calBoxBase =
  'group px-px flex flex-col items-center justify-start h-20 py-1 leading-none transition-colors duration-300 cursor-pointer space-y-0.5 rounded-md overflow-hidden';

const LoadingChip = () => (
  <div className="flex flex-col items-center w-full my-2 animate-pulse">
    <div className="w-10 h-3 bg-blue-300 rounded-full" />
  </div>
);

const FacilityOpeningChip = ({ num }) => (
  <div className={`bg-purple-200 ${calChipBase}`}>
    <div className={calChipText}>{`${num} open`}</div>
  </div>
);

const TodayEmptyChip = () => (
  <div className="flex justify-center mt-2">
    <span className="p-1 text-sm text-blue-500 rounded-full">
      <SunIcon width={24} />
    </span>
  </div>
);

const BookingsChip = ({ num }) => (
  <div className={`bg-green-100 ${calChipBase}`}>
    <div className={calChipText}>{`${num} booked`}</div>
  </div>
);
const ProposalChip = ({ num }) => (
  <div className={`bg-orange-100 ${calChipBase}`}>
    <div className={calChipText}>{`${num} proposed`}</div>
  </div>
);

const ClientBookedChip = ({ text }) => (
  <div className={`bg-green-700 ${calChipBase} text-white`}>
    <div className={calChipText}>{text}</div>
  </div>
);
const ClientProposalChip = ({ text }) => (
  <div className={`bg-orange-700/80 ${calChipBase} text-white`}>
    <div className={calChipText}>{text}</div>
  </div>
);
const ClientNewProposalChip = ({ text }) => (
  <div className={`bg-orange-700 ${calChipBase} text-white`}>
    <div className={calChipText}>{text}</div>
  </div>
);
const AvailableChip = ({ num, hasChanged }) => (
  <div
    className={`${
      hasChanged ? 'bg-blue-800 text-white' : 'bg-blue-200'
    } ${calChipBase}`}
  >
    <div className={calChipText}>{`${num} available`}</div>
  </div>
);

export const CalendarDay = ({
  date,
  children,
  onClick = null,
  disabled = false,
}) => {
  const border =
    date === moment().format(DATE_FMT.DATE_KEY) ? 'border border-blue-400' : '';
  return (
    <div
      className={`${calBoxBase} ${border} ${
        !disabled
          ? 'text-gray-900 hover:bg-blue-50 bg-white'
          : 'text-gray-600 bg-gray-100'
      }`}
      onClick={onClick ? () => onClick(date) : null}
    >
      <div className="font-medium">{moment(date).format(DATE_FMT.D)}</div>
      {children}
    </div>
  );
};

export const CalBoxLoadingDate = () => (
  <div className="flex justify-center w-full animate-pulse">
    <div className="w-8 h-2 bg-blue-300 rounded-full" />
  </div>
);

export const AvailabilityCalendarDay = ({
  date,
  onClick,
  tense,
}: DayComponentProps) => {
  const openings = useSelector(getScheduleOpenings);
  const changes = useSelector(getScheduleChanges)?.filter(
    (change) => change.date === date
  );
  const scheduleDate = openings.find((opening) => opening.date === date);
  const times = scheduleDate?.times || [];
  const numOpenTimes = times.filter((time) => time.isBookable).length;
  const worksElsewhere = scheduleDate?.status === 'instructor_works_elsewhere';

  const numBooked = times.filter(
    (t) => t.instructorStatus === InstructorTimeSlotType.Booked
  ).length;
  const numAvailable = times.filter(
    (t) => t.instructorStatus === InstructorTimeSlotType.Available
  ).length;
  const numCreate = changes.filter(
    (c) => c.action === ScheduleChangeAction.Create
  ).length;
  const numDelete = changes.filter(
    (c) => c.action === ScheduleChangeAction.Delete
  ).length;
  const hasThingsToday = numBooked > 0 || numAvailable > 0;
  const numBookableSpots = times.filter((time) => time.isBookable).length;
  const numDisplayTimes =
    numBookableSpots > 0
      ? !hasThingsToday
        ? Math.ceil(numBookableSpots / 2)
        : numBookableSpots
      : 0;
  const disabled =
    [Tense.LastMonth, Tense.NextMonth].includes(tense) ||
    worksElsewhere ||
    (numOpenTimes === 0 && !hasThingsToday);

  const hasChanged = numCreate > 0 || numDelete > 0;

  return (
    <CalendarDay date={date} onClick={onClick} disabled={disabled}>
      <div className="flex flex-col w-full space-y-px">
        <FacilityOpeningChip num={numDisplayTimes} />
        {(numAvailable > 0 || hasChanged) && (
          <AvailableChip
            num={numAvailable + numCreate - numDelete}
            hasChanged={hasChanged}
          />
        )}
      </div>
      {worksElsewhere && (
        <div className="flex flex-col items-center justify-center flex-1 w-full text-lg text-yellow-700">
          <FacilityIcon width={18} />
        </div>
      )}
    </CalendarDay>
  );
};

export const ProposalCalendarLegend = () => {
  return (
    <div className="flex">
      <div className="flex flex-col items-center flex-1 text-xs">
        <FacilityOpeningChip num={3} />
        <span>Facility openings</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <ClientBookedChip text="4:30pm" />
        <span>Client's bookings</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <ClientNewProposalChip text="4:30pm" />
        <span>Client's proposals</span>
      </div>
    </div>
  );
};

export const AvailabilityCalendarLegend = () => {
  return (
    <div className="flex ">
      <div className="flex flex-col items-center flex-1 text-xs">
        <FacilityOpeningChip num={3} />
        <span>Facility openings</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <AvailableChip num={3} hasChanged={false} />
        <span>Current availability</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <AvailableChip num={3} hasChanged={true} />
        <span>Unsaved changes</span>
      </div>
    </div>
  );
};

export const BookingsCalendarLegend = () => {
  return (
    <div className="flex">
      <div className="flex flex-col items-center flex-1 text-xs">
        <BookingsChip num={3} />
        <span>Current bookings</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <AvailableChip num={3} hasChanged={false} />
        <span>Available times</span>
      </div>
      <div className="flex flex-col items-center flex-1 text-xs">
        <ProposalChip num={3} />
        <span>Proposed bookings</span>
      </div>
    </div>
  );
};

export const ProposalCalendarDay = ({
  date,
  onClick,
  tense,
}: DayComponentProps) => {
  const client = useSelector(getProposalClient);
  const openings = useSelector(getScheduleOpenings);
  const timezone = useSelector(getScheduleAppointmentProduct)?.timezone;
  const scheduleDate = openings.find((opening) => opening.date === date);
  const times = scheduleDate?.times || [];
  const numOpenTimes = times.filter((time) => time.isBookable).length;
  const worksElsewhere = scheduleDate?.status === 'instructor_works_elsewhere';

  const numBooked = times.filter(
    (t) => t.instructorStatus === InstructorTimeSlotType.Booked
  ).length;
  const numAvailable = times.filter(
    (t) => t.instructorStatus === InstructorTimeSlotType.Available
  ).length;
  const clientBookings = times.filter((t) =>
    !!t.otherData
      ? t.otherData.clientId === client.id &&
        t.instructorStatus === InstructorTimeSlotType.Booked
      : // LEGACY
        t.clientId === client.id &&
        t.instructorStatus === InstructorTimeSlotType.Booked
  );
  const clientProposals = times.filter((t) =>
    !!t.otherData
      ? t.otherData.clientId === client.id &&
        t.instructorStatus === InstructorTimeSlotType.Pending
      : // LEGACY
        t.clientId === client.id &&
        t.instructorStatus === InstructorTimeSlotType.Pending
  );
  const newProposals = useSelector(getProposalCreateList)?.filter(
    (p) => p.date === date
  );
  const hasThingsToday = numBooked > 0 || numAvailable > 0;
  const numBookableSpots = times.filter((time) => time.isBookable).length;
  const numDisplayTimes =
    numBookableSpots > 0
      ? !hasThingsToday
        ? Math.ceil(numBookableSpots / 2)
        : numBookableSpots
      : 0;
  const showAvailable =
    numAvailable > 0 &&
    newProposals.length === 0 &&
    clientProposals.length === 0 &&
    clientBookings.length === 0;

  const disabled =
    [Tense.LastMonth, Tense.NextMonth].includes(tense) ||
    worksElsewhere ||
    numOpenTimes === 0;

  return (
    <CalendarDay date={date} onClick={onClick} disabled={disabled}>
      <div className="flex flex-col w-full space-y-px">
        <FacilityOpeningChip num={numDisplayTimes} />
        {clientBookings.length > 0 && (
          <ClientBookedChip
            text={moment(clientBookings[0].datetime)
              .tz(timezone)
              .format(DATE_FMT.TIME_A)}
          />
        )}
        {clientProposals.length > 0 && (
          <ClientProposalChip
            text={`${moment(clientProposals[0].datetime)
              .tz(timezone)
              .format(DATE_FMT.TIME_A)}`}
          />
        )}
        {newProposals.length > 0 && (
          <ClientNewProposalChip
            text={`${moment(newProposals[0].datetime)
              .tz(timezone)
              .format(DATE_FMT.TIME_A)}${
              newProposals.length > 1 ? ` (${newProposals.length})` : ''
            }`}
          />
        )}
        {showAvailable && (
          <AvailableChip num={numAvailable} hasChanged={false} />
        )}
      </div>
      {worksElsewhere && (
        <div className="flex flex-col items-center justify-center flex-1 w-full text-lg text-yellow-700">
          <FacilityIcon width={18} />
        </div>
      )}
    </CalendarDay>
  );
};

export const DayLoadingComponent = ({ date, tense }: DayComponentProps) => {
  const disabled = [Tense.LastMonth, Tense.NextMonth].includes(tense);
  return (
    <CalendarDay date={date} disabled={disabled}>
      <LoadingChip />
    </CalendarDay>
  );
};

export const CalendarDayBookings = ({
  date,
  tense,
  onClick,
}: DayComponentProps) => {
  const forDate = (a: SchedulableObject) => a.date === date && !a.cancelled;
  const allAvailability = useSelector(getAvailability);
  const allProposals = useSelector(getProposals);
  const allAppointments = useSelector(getAppointments);
  const appointments = allAppointments.filter(forDate);
  const availability = allAvailability
    .filter(forDate)
    .filter((a) => a.isBookable);
  const proposals = allProposals.filter(forDate);
  const hasAppointments = appointments.length > 0;
  const hasProposals = proposals.length > 0;
  const isAvailable = availability.length > 0;
  const hasNothing = !hasAppointments && !hasProposals && !isAvailable;
  const disabled = [Tense.LastMonth, Tense.NextMonth].includes(tense);
  return (
    <CalendarDay date={date} onClick={onClick} disabled={disabled}>
      <div className="flex flex-col w-full space-y-px">
        {hasAppointments && <BookingsChip num={appointments.length} />}
        {hasProposals && <ProposalChip num={proposals.length} />}
        {isAvailable && (
          <AvailableChip num={availability.length} hasChanged={false} />
        )}
        {hasNothing && tense === Tense.Today && <TodayEmptyChip />}
      </div>
    </CalendarDay>
  );
};

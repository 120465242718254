import {
  InstructorEarningsHistory,
  InstructorReliabilityHistory,
} from 'api/Serializers/Analytics';
import { EventList } from 'api/Serializers/Appointments';
import { AxiosResponse } from 'axios';
import { UserType } from 'config';
import { Address } from 'models/geo';
import { Activity, ActivitySegment } from '../Activities';
import { InstructorListSerializer } from '../Instructors';
import { UserSerializer } from '../Users';
import AdminAccount from './Admin';
import InstructorAccount, {
  AccountUpdateSerializer,
  Certification,
  InstructorActivityPreferences,
  InstructorOnboardingSerializer,
} from './Instructor';

export enum Status {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Stale = 'STALE',
  Rejected = 'REJECTED',
  Suspended = 'SUSPENDED',
  Banned = 'BANNED',
  Hiatus = 'HIATUS',
  Done = 'DONE',
  Unlisted = 'UNLISTED',
  Unqualified = 'UNQUALIFIED',
}

export const ListedStatuses: Status[] = [Status.Active, Status.Inactive];

export enum OnboardingStatus {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
}

export enum FavouriteNotificationFrequency {
  NEVER = 0,
  DAILY = 1,
  WEEKLY = 2,
}

export const OnboardingStatusText = ['Pending', 'Accepted', 'Rejected'];

export interface EmergencyContact {
  id?: string;
  name: string;
  phoneNumber: string;
  relation?: string;
}

export interface WeekDayTimeFilter {
  weekDay: number;
  start: number;
  end: number;
}

export interface LocationFilter {
  latlng: { lat: number; lng: number };
  radius: number;
  address: string;
}

export interface UserSearchPreferences {
  activity: Activity;
  segment: ActivitySegment;
  times: WeekDayTimeFilter[];
  maxPrice: number;
  location: LocationFilter;
}

export const DEFAULT_SEARCH_PREFERENCES: UserSearchPreferences = {
  activity: undefined,
  segment: undefined,
  times: [],
  maxPrice: undefined,
  location: undefined,
};

export interface CreateUserSearchPreferences {
  activityId: number;
  segmentId?: number;
  times?: WeekDayTimeFilter[];
}

export interface PropelAccount {
  id: number;
  slug: string;
  username: string;
  userId: number;
  displayName: string;
  description: string;
  phoneNumber: string;
  phoneRaw: string;
  address: Address;
  status: Status;
  firstName: string;
  lastName: string;
  fullName: string;
  birthdate: string;
  age: number;
  email: string;
  emailConfirmed: string;
  avatar: string;
  type: UserType;
  lastLogin: string;
  dateCreated: string;
  isTosSigned: boolean;
  timezone: string;
  emergencyContact: EmergencyContact;
  messagingDisabled: boolean;
  favouriteNotificationFrequency: FavouriteNotificationFrequency;
}

export interface AccountNoteSerializer {
  id: number;
  note: string;
  created: string;
  modified: string;
  author: UserSerializer;
  user: UserSerializer;
}

export type OverviewActivityType =
  | 'appointment'
  | 'reservation'
  | 'booking'
  | 'cancellation'
  | 'review';

export interface AccountOverviewSerializer {
  upcoming: EventList[];
  recent: EventList[];
}

export interface Contact {
  id?: string;
  created?: string;
  name: string;
  email: string;
  notifications: Array<{ type: number }>;
}

export interface Review {
  id: string;
  instructorId: number;
  instructorName: string;
  instructor: {
    id: number;
    username: string;
    displayName: string;
  };
  reviewer: string;
  title: string;
  review: string;
  recommended: boolean;
  created: string;
  approved: boolean;
  notes: string;
}

export interface EarlyAccessWindow {
  id: string;
  created: string;
  startDate: string;
  endDate: string;
  instructor: {
    id: number;
    slug: string;
    username: string;
    firstName: string;
    fullName: string;
    displayName: string;
    avatar: string;
    status: string;
  };
  facility: {
    slug: string;
    status: string;
    avatar: string;
    displayName: string;
    hero: string;
    category: string;
    assetType: string;
  };
  rollout: {
    scheduleStartDate: string;
    scheduleEndDate: string;
    publicAccessDate: string;
  };
}

export interface AvailableTimesSerializer {
  time: string;
  available: boolean;
}
interface AvailableSupportSchedule {
  date: string;
  times: AvailableTimesSerializer[];
}
export interface AccountSupportSerializer {
  scheduled: string[];
  available: AvailableSupportSchedule[];
}

export interface AccountNoticeSerializer {
  type: string;
  title: string;
  body: string;
  ctaTitle: string;
  ctaLink: string;
  dismissed: boolean;
}

export interface Api {
  retrieve: (
    username: string
  ) => Promise<AxiosResponse<InstructorAccount | AdminAccount>>;
  update: (
    username: string,
    data: Partial<AccountUpdateSerializer>
  ) => Promise<AxiosResponse<any>>;
  certifications: {
    create: (
      username: string,
      data: Certification
    ) => Promise<AxiosResponse<Certification[]>>;
    retrieve: (username: string) => Promise<AxiosResponse<Certification[]>>;
    update: (
      username: string,
      data: Certification
    ) => Promise<AxiosResponse<Certification[]>>;
  };
  contacts: {
    create: (
      username: string,
      data: Contact
    ) => Promise<AxiosResponse<Contact>>;
    update: (
      username: string,
      data: Contact
    ) => Promise<AxiosResponse<Contact>>;
    list: (username: string) => Promise<AxiosResponse<Contact[]>>;
    delete: (
      username: string,
      data: { id: string }
    ) => Promise<AxiosResponse<any>>;
  };
  credit: (username: string) => Promise<AxiosResponse<number>>;
  reliability: (
    username: string
  ) => Promise<AxiosResponse<InstructorReliabilityHistory>>;
  earnings: (
    username: string
  ) => Promise<AxiosResponse<InstructorEarningsHistory[]>>;
  instructors: (
    username: string
  ) => Promise<AxiosResponse<InstructorListSerializer[]>>;
  overview: (
    username: string
  ) => Promise<AxiosResponse<AccountOverviewSerializer>>;
  reviews: (username: string) => Promise<AxiosResponse<Review[]>>;
  onboarding: (
    username: string,
    data?: Partial<InstructorOnboardingSerializer>
  ) => Promise<AxiosResponse<InstructorOnboardingSerializer>>;
  support: {
    get: (params: {
      start: string;
    }) => Promise<AxiosResponse<AccountSupportSerializer>>;
    post: (data: { datetime: string }) => Promise<AxiosResponse<any>>;
  };
  preferredTimes: {
    list: (username: string) => Promise<AxiosResponse<any[]>>;
    update: (username: string, data: any[]) => Promise<AxiosResponse<any>>;
  };
  preferences: (
    username: string,
    data?: InstructorActivityPreferences
  ) => Promise<AxiosResponse<InstructorActivityPreferences>>;
  earlyAccessWindows: (
    username: string
  ) => Promise<AxiosResponse<EarlyAccessWindow[]>>;
  notices: (
    username: string
  ) => Promise<AxiosResponse<AccountNoticeSerializer[]>>;
}

import api from 'api';
import { UserType } from 'config';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  getFavouritesLoaded,
  getSearchCity,
  getSearchFacilities,
  getSearchFacility,
  getSearchInstructor,
  getSearchPreferences,
  getUserType,
} from 'state/selectors';
import { fetchFavourites } from 'state/slice/favourites';
import {
  fetchFacilityDetail,
  fetchFacilityInstructors,
  fetchFacilityList,
  fetchInstructorData,
  setSearchCity,
} from 'state/slice/search';
import { APP_ROUTES, DEPRECATED_ROUTES } from 'utils/routing';
import Cities from './cities';
import FacilityDetailPage from './facility-detail';
import FacilityList from './facility-list';
import InstructorDetail from './instructor-detail';

const Locations = () => {
  const dispatch = useAppDispatch();
  const city = useSelector(getSearchCity);
  const facility = useSelector(getSearchFacility);
  const facilities = useSelector(getSearchFacilities);
  const instructor = useSelector(getSearchInstructor);
  const searchPreferences = useSelector(getSearchPreferences);
  const query = useQuery();
  const { params } = useRouteMatch<any>();
  const userType = useSelector(getUserType);
  const favouritesLoaded = useSelector(getFavouritesLoaded);

  const fetchCityData = async (citySlug: string) => {
    try {
      const response = await api.regions.retrieve(citySlug);
      dispatch(setSearchCity(response.data));
    } catch (error) {
      enqueueSnackbar({
        message: 'We could not locate that city, please try another',
        variant: 'error',
      });
    }
  };

  const fetchInstructorIfNecessary = () => {
    if (instructorSlug && (!instructor || instructor.slug !== instructorSlug)) {
      dispatch(fetchInstructorData(instructorSlug, facilitySlug));
    }
  };

  const { regionSlug, facilitySlug, instructorSlug } = params;
  useEffect(() => {
    if (userType === UserType.Client && !favouritesLoaded) {
      dispatch(fetchFavourites());
    }
    if (regionSlug) {
      if (!city) {
        fetchCityData(regionSlug);
      }
      if (!facilities || facilities.length === 0) {
        dispatch(fetchFacilityList(regionSlug));
      }
    }
    fetchInstructorIfNecessary();
  }, [regionSlug, facilitySlug, instructorSlug, query]);

  useEffect(() => {
    if (!facilitySlug) {
      return;
    }
    if (!facility || facility.slug !== facilitySlug) {
      dispatch(fetchFacilityDetail(facilitySlug));
    }
    dispatch(fetchFacilityInstructors(facilitySlug));
  }, [facilitySlug, searchPreferences]);

  return (
    <>
      {/* <LocationsMenu /> */}
      <div className="min-h-screen">
        <Switch>
          <Route
            exact={true}
            path={`${DEPRECATED_ROUTES.BROWSE_WITH_INSTRUCTORS_PATH}`}
            render={() => (
              <Redirect
                to={APP_ROUTES.BROWSE_INSTRUCTOR(
                  params.regionSlug,
                  params.facilitySlug,
                  params.action
                )}
              />
            )}
          />
          <Route exact={true} path={`${APP_ROUTES.FACILITY_LIST}`}>
            <FacilityList />
          </Route>
          <Route exact={true} path={`${APP_ROUTES.FACILITY_DETAIL}`}>
            <FacilityDetailPage />
          </Route>
          <Route exact={true} path={`${APP_ROUTES.INSTRUCTOR_DETAIL}`}>
            <InstructorDetail />
          </Route>
          <Route exact={true} path={`${APP_ROUTES.BROWSE_CITIES}`}>
            <Cities />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default Locations;

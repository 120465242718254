import { CheckCircleOutline, People, Tune } from '@mui/icons-material';
import { Card } from '@mui/material';
import api from 'api';
import {
  FacilityDetailSerializer,
  FacilityScheduleSerializer,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import Button from 'components/button';
import Hero from 'components/hero';
import Loading from 'components/loading';
import GoogleMap from 'components/map';
import WeeklySchedule from 'components/weekly-schedule';
import { DATE_FMT, IS_SERVER } from 'config';
import Page from 'containers/page';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { OpportunitiesMeta as Meta } from 'metadata';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Scroll from 'react-scroll';
import { InstagramEmbed } from 'react-social-media-embed';
import { getSearchFacility } from 'state/selectors';
import { fetchFacilityDetail } from 'state/slice/search';
import { APP_ROUTES } from 'utils/routing';

const getGoogleJSONScript = (facility: FacilityDetailSerializer) => ({
  '@context': 'https://schema.org/',
  '@type': 'JobPosting',
  title: `Swim Instructor (${facility.displayName})`,
  description: `<p>Take the next step in aquatics and work when you want, how you want – while earning 2-3x what you'll make elsewhere.</p><p><ul> <li>Pay: $35-60/hr (average is $43/hr)</li><li>Qualification: Any swim instructor certificate</li><li>Experience: Learn-to-swim 1 yr (paid)</li><li>Commitment: None, no minimum time requirement</li><li>Schedule: Set by instructor</li><li>Location: Our private pool in ${facility.region.city}, ${facility.region.regionCode}</li><li>Resume: No resume or cover letter required</li><li>Travel: No driving between backyard or condo pools</li></ul></p><p>At Propel, we believe in instructor choice, individuality, and opportunity.</p><p>Choice – On our platform, instructors choose which pools they work in and they are completely in charge of setting their own availability. There are no mandatory hours or inservices, no requests for time off, and no micromanagers to pressure you to take shifts.</p><p>Individuality – Instead of registering for numbered classes, students book lessons by choosing their instructor. At Propel, your experience and expertise truly matter.</p><p>Opportunity – We believe instructors are THE talent. This is why they earn the majority of the money generated from their time spent teaching. We're proud to say that Propel instructors make twice the national average!</p><p>What will I be doing?</p><p>Teaching relaxed private swim lessons with just 1 or 2 students. Click apply to learn more!</p><p>About Propel</p><p>We're not a traditional swim school or public pool. We're a team of swim instructors, engineers, and product managers who are intent making the aquatics industry more fair and accessible to everyone.</p>`,
  identifier: {
    '@type': 'PropertyValue',
    name: 'Propel',
    value: facility.id,
  },
  datePosted: moment().startOf('month').format('YYYY-MM-DD'),
  validThrough: moment().add(2, 'months').endOf('month').format('YYYY-MM-DD'),
  employmentType: 'PART_TIME',
  directApply: 'TRUE',
  hiringOrganization: {
    '@type': 'Organization',
    name: 'Propel',
    sameAs: 'http://www.propelhq.com',
    logo: 'https://oleksiak.s3.us-west-2.amazonaws.com/uploads/2022-02-21/propel-square-logo-400.png',
    experienceRequirements: {
      '@type': 'OccupationalExperienceRequirements',
      monthsOfExperience: '12',
    },
  },
  jobLocation: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      streetAddress: facility.region.line1,
      addressLocality: facility.region.city,
      addressRegion: facility.region.regionCode,
      postalCode: facility.region.postalCode,
      addressCountry: facility.region.countryCode,
    },
  },
  baseSalary: {
    '@type': 'MonetaryAmount',
    currency: 'CAD',
    value: {
      '@type': 'QuantitativeValue',
      minValue: 35,
      maxValue: 60,
      unitText: 'HOUR',
    },
  },
});

const Opportunities = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { facilitySlug } = useParams<any>();
  const [schedule, setSchedule] = useState<FacilityScheduleSerializer>();
  const facility = useSelector(getSearchFacility);

  useEffect(() => {
    dispatch(fetchFacilityDetail(facilitySlug));
  }, []);

  useEffect(() => {
    const getFacilitySchedule = async () => {
      try {
        const response = await api.facilities.schedules(facilitySlug, {
          start: moment().format(DATE_FMT.DATE_KEY),
          end: moment().add(1, 'year').format(DATE_FMT.DATE_KEY),
        });
        setSchedule(response.data[0] ?? null);
      } catch {
        setSchedule(null);
      }
    };
    getFacilitySchedule();
  }, []);

  if (!facility || (schedule === undefined && !IS_SERVER)) {
    return (
      <Page>
        <div></div>
        <Loading />
      </Page>
    );
  }

  if (
    ![
      FacilityStatus.Active,
      FacilityStatus.Seeding,
      FacilityStatus.Created,
    ].includes(facility.status)
  ) {
    return <Redirect to={APP_ROUTES.NOT_FOUND} />;
  }

  return (
    <Page {...Meta(facility)}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(getGoogleJSONScript(facility))}
        </script>
      </Helmet>
      <div>
        <JobDescription facility={facility} schedule={schedule} />
        <Scroll.Element name="the-pool">
          <ThePool facility={facility} />
        </Scroll.Element>
        <HowItWorks />
        <Scroll.Element name="about-propel">
          <AboutPropel />
        </Scroll.Element>
        <Insurance />
        <Scroll.Element name="testimonials">
          <Testimonials />
        </Scroll.Element>
        <SpeakToPro />
        {/* TODO Add the cio integration so we can collect emails from this page with the Subscribe element */}
        {/* <Subscribe /> */}
        <MediaFeatures />
        <Map facility={facility} />
      </div>
    </Page>
  );
};

const JobDescription = ({ facility, schedule }): JSX.Element => {
  return (
    <div className="px-4 py-10 m-auto w-100 max-w-7xl">
      <div className="flex flex-wrap items-center justify-around gap-8 lg:flex-nowrap">
        <div>
          <h6 className="text-blue-500">Pool Opportunities</h6>
          <h1 className="text-4xl">Swim Instructor</h1>
          <h3>
            {facility.region.city}, {facility.region.regionCode}
          </h3>
          <br />
          <div>
            <span>
              <b>Qualification:</b> Any swim instructor certificate
            </span>
            <br />
            <span>
              <b>Experience:</b> Learn-to-swim 1 yr (paid)
            </span>
            <br />
            <span>
              <b>Commitment:</b> None, no lease required
            </span>
            <br />
            <span>
              <b>Insurance:</b> Provided
            </span>
            <br />
            <span>
              <b>Type:</b> Independent operator
            </span>
            <br />
            <span>
              <b>Price:</b> Set by instructor
            </span>
            <br />
            <span>
              <b>Schedule:</b> Set by instructor within pool hours
            </span>
            <br />
            <span>
              <b>Pool Hours:</b>{' '}
              {!schedule
                ? 'Provided during application process'
                : schedule && moment(schedule.start).isAfter(moment())
                ? `Starting ${moment(schedule.start).format(
                    DATE_FMT.MONTH_D_ORDINAL
                  )}`
                : null}
            </span>
            {schedule && <WeeklySchedule schedule={schedule} />}
          </div>
          <br />
          <div>
            <b>
              <u>No resume or cover letter required</u>
            </b>
          </div>
        </div>
        <div className="max-w-lg">
          <img
            src="https://oleksiak.propelhq.com/v4/eyJidWNrZXQiOiJvbGVrc2lhayIsImtleSI6ImFzc2V0cy9pbWFnZXMvcG9vbC1vcHBvcnR1bml0aWVzL3RvcC1waG90by5qcGcifQ=="
            alt="Propel Instructor"
          />
        </div>
      </div>
      <h1 className="mt-16 mb-4 font-semibold text-center text-blue-500">
        Get a $100 bonus after you teach your fifth lesson!
      </h1>
    </div>
  );
};

const ThePool = ({ facility }): JSX.Element => {
  const PoolDescription = ({ className, facility }): JSX.Element => {
    return (
      <div className={className}>
        <div className="p-5 m-5 bg-white border-2 border-black border-solid outline outline-8 outline-white lg:max-w-sm">
          <h6 className="text-blue-500">Welcome to your pool</h6>
          <h1 className="text-4xl">{facility.displayName}</h1>
          {/* <h4>{facility.shortAddress}</h4> */}
          <p>{facility.description}</p>
          <br />
          <Button
            to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
            color="primary"
            variant="flat"
            size="large"
          >
            Get Started
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <PoolDescription
        className="flex justify-end lg:hidden"
        facility={facility}
      />
      <Hero
        background={facility.media[0].url}
        fadeOpacity={0}
        bodyClassName="!max-w-7xl !items-end"
      >
        <PoolDescription
          className="justify-end hidden lg:flex"
          facility={facility}
        />
      </Hero>
    </>
  );
};

const HowItWorks = (): JSX.Element => {
  return (
    <div className="py-10 text-white bg-gray-800">
      <div className="px-4 m-auto w-100 max-w-7xl">
        <h6 className="pb-4">How propel works</h6>
        <div className="flex flex-wrap lg:flex-nowrap gap-x-8 gap-y-4">
          <div className="basis-full">
            <Tune className="!text-5xl" />
            <h2 className="py-2">1. Set Up Your Profile</h2>
            <p className="text-gray-400">
              Create a profile, set your price, and do a quick orientation call.
              Then add your availability to your schedule.
            </p>
          </div>
          <div className="basis-full">
            <CheckCircleOutline className="!text-5xl" />
            <h2 className="py-2">2. Get Booked</h2>
            <p className="text-gray-400">
              Get discovered and booked by any of the tens of thousands who
              visit our website every month looking for swimming lessons.
            </p>
          </div>
          <div className="basis-full">
            <People className="!text-5xl" />
            <h2 className="py-2">3. Teach Your Lessons</h2>
            <p className="text-gray-400">
              Meet your clients at the pool and teach them how to swim. Receive
              your client's payment with fees and sales tax already deducted.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutPropel = (): JSX.Element => {
  return (
    <div className="px-4 py-10 m-auto w-100 max-w-7xl">
      <div className="flex flex-wrap items-center justify-center lg:flex-nowrap gap-x-8 gap-y-16">
        <div className="flex-auto basis-1/2">
          <h6 className="text-blue-500">What is propel?</h6>
          <h1 className="text-4xl">
            A platform that connects pools with instructors
          </h1>
          <p className="py-4">
            At Propel we help facilities become more profitable by earning
            admission revenue from working with instructors who want to teach
            their own private swimming lessons. Propel charges a 16% booking fee
            for facilitating bookings, payments, and insurance, but otherwise
            there are never fees paid out-of-pocket for signing up, listing, or
            promotion.
          </p>
          <Button
            to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
            color="primary"
            variant="flat"
            size="large"
          >
            Learn more
          </Button>
        </div>
        <div className="flex-auto max-w-xl basis-1/2">
          <InstagramEmbed url="https://www.instagram.com/p/BzZl_SLBTkl/?utm_source=ig_embed&utm_campaign=loading" />
        </div>
      </div>
    </div>
  );
};

const Insurance = (): JSX.Element => {
  return (
    <div className="py-10 text-white bg-blue-500">
      <div className="px-4 m-auto w-100 max-w-7xl">
        <div className="flex flex-wrap justify-center lg:flex-nowrap gap-x-8">
          <div>
            <h1 className="text-4xl">$10,000,000 Protecting You</h1>
            <p>
              Propel has industry-leading insurance coverage so that you don't
              have to buy your own.
            </p>
          </div>
          <div className="flex items-center mt-2">
            <Button
              className="!text-xl !px-12"
              to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
              size="large"
              shape="rounded-full"
              variant="flat"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonials = (): JSX.Element => {
  return (
    <div className="px-4 py-10 m-auto w-100 max-w-7xl">
      <h6 className="mb-4 text-blue-500">Testimonials</h6>
      <div className="flex flex-col gap-y-16">
        <div className="flex flex-wrap justify-center gap-8 lg:flex-nowrap">
          <div className="flex-auto max-w-md basis-1/3">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/propel-instructor-testimonial.jpg"
              alt="Propel Instructor"
            />
          </div>
          <div className="flex flex-col justify-center flex-auto basis-2/3">
            <h1 className="text-4xl">
              "I earned more than enough to pay for school while setting a
              schedule around my busy student life."
            </h1>
            <span>
              <b>Priscilla O.</b>
            </span>
            <br />
            <span>Swim instructor in Richmond, BC</span>
          </div>
        </div>
        <div className="flex flex-wrap justify-around md:flex-nowrap gap-y-8 gap-x-12">
          <Card className="max-w-lg">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/opportunities-card-1.jpg"
              alt="Card 1"
            />
            <div className="p-4">
              <h4>
                "Propel takes care of everything, all the hotel has to do is
                provide the trainer with a key card."
              </h4>
              <span>
                <b>Shaun P.</b>
              </span>
              <br />
              <span>Sales Director, YRS Hotel, Toronto, ON</span>
            </div>
          </Card>
          <Card className="max-w-lg">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/opportunities-card-2.jpg"
              alt="Card 2"
            />
            <div className="p-4">
              <h4>
                “Unlike elsewhere, at Propel you keep the majority of the value
                you generate. Wild.”
              </h4>
              <span>
                <b>Olivier H.</b>
              </span>
              <br />
              <span>Swim Instructor in Vancouver, BC</span>
            </div>
          </Card>
          <Card className="max-w-lg">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/opportunities-card-3.jpg"
              alt="Card 3"
            />
            <div className="p-4">
              <h4>
                “We created a platform made for swim instructors, by swim
                instructors.”
              </h4>
              <span>
                <b>Amie N.</b>
              </span>
              <br />
              <span>Cofounder, Propel</span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const SpeakToPro = (): JSX.Element => {
  return (
    <div className="py-10 text-white bg-blue-500">
      <div className="px-4 m-auto w-100 max-w-7xl">
        <div className="flex flex-wrap justify-around gap-8 lg:flex-nowrap">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl">Not sure? Speak to a Pro!</h1>
            <p>
              During your orientation call, we'll be happy to answer any
              questions you have.
            </p>
            <div className="mt-2">
              <Button
                className="!text-xl !px-12"
                to={APP_ROUTES.LEARN_MORE.INSTRUCTORS}
                size="large"
                shape="rounded-full"
                variant="flat"
              >
                Get Started
              </Button>
            </div>
          </div>
          <div className="max-w-sm">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/opportunities-speak-to-pro.jpg"
              alt="Speak to a Pro"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Subscribe = (): JSX.Element => {
  return (
    <div className="px-4 py-10 m-auto w-100 max-w-7xl">
      <div className="flex flex-wrap items-center justify-center flex-auto lg:flex-nowrap gap-x-8">
        <div>
          <h6 className="mb-4 text-blue-500">Take the next step in aquatics</h6>
          <h1 className="text-4xl">Get started today</h1>
          <p>
            Sign up for our newsletter to learn more about how instructors are
            taking the next step into aquatics.
          </p>
        </div>
        <div className="flex flex-none max-w-lg mt-2 basis-full lg:basis-1/2">
          <input
            className="m-0 rounded-none"
            placeholder="you@email.com"
            type="email"
          />
          <Button
            size="large"
            color="primary"
            variant="flat"
            shape="rounded-none"
          >
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

const MediaFeatures = (): JSX.Element => {
  return (
    <div className="py-10 bg-gray-200">
      <div className="px-4 m-auto w-100 max-w-7xl">
        <h2 className="text-center">Featured on</h2>
        <div className="flex flex-wrap items-center justify-center flex-auto gap-8 py-8 lg:flex-nowrap">
          <div className="max-w-sm">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/financial-post.webp"
              alt="Financial Post"
            />
          </div>
          <div className="max-w-sm">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/cbc.webp"
              alt="CBC"
            />
          </div>
          <div className="max-w-sm">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/vancouver-sun.webp"
              alt="Vancouver Sun"
            />
          </div>
          <div className="max-w-sm">
            <img
              src="https://oleksiak.s3.us-west-2.amazonaws.com/assets/images/pool-opportunities/toronto-star.webp"
              alt="Toronto Star"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Map = ({ facility }): JSX.Element => {
  return (
    <div className="flex justify-center overflow-hidden rounded-2xl">
      <GoogleMap latlng={facility.region.latlng} width={800} height={300} />
    </div>
  );
};

export default Opportunities;

import { PropelAccount, Status } from 'api/Serializers/Accounts';
import { Event, TimeFrame } from 'api/Serializers/Appointments';
import {
  FacilityDetailSerializer,
  FacilityStatus,
} from 'api/Serializers/Facilities';
import {
  DATE_FMT,
  DAYS_AFTER_APPOINTMENT_REPORT,
  MIN_INSTRUCTOR_AGE,
  UserType,
} from 'config';
import moment, { Moment } from 'moment-timezone';

export const canAccountModifyAvailability = (account: { status: Status }) =>
  [
    Status.Pending,
    Status.Active,
    Status.Inactive,
    Status.Hiatus,
    Status.Done,
  ].some((s) => s === account.status);

/**
 *
 * @param param0 server returned age value
 * @returns undefined if age not set, or true/false if >= MIN_INSTRUCTOR_AGE
 */
export const isInstructorOldEnough = (age: number) => {
  if (!age) {
    return undefined;
  }
  return age >= MIN_INSTRUCTOR_AGE;
};

/**
 * Returns the logic whether a appointment can be cancelled or not based on the current time
 * Current logic: Cancellations can be done up to the appointment start time
 * @param user User making cancellation request
 * @param appointment Appointment that will be cancelled
 */
export const isWithinCancellationPeriod = (
  user: PropelAccount,
  appointment: Event
) =>
  user.type === UserType.Client
    ? moment().utc().isBefore(appointment.end)
    : moment().utc().isSameOrBefore(moment(appointment.end), 'day');

/**
 * Returns whether an issue is allowed to be reported based on the current time
 * Current logic: Can submit on day of, and up to 2 days after an appointment
 * @param appointment appointment to check
 */
export const isWithinReportPeriod = (appointment: Event) => {
  const earliest = moment(appointment.start).tz(appointment.timezone);
  const latest = moment(appointment.start)
    .tz(appointment.timezone)
    .add(DAYS_AFTER_APPOINTMENT_REPORT, 'days');
  return moment().isBetween(earliest, latest, 'days', '[]');
};

export const canViewFacility = (
  facility: FacilityDetailSerializer,
  user: PropelAccount
) => {
  if (
    facility.status === FacilityStatus.Active ||
    facility.status === FacilityStatus.Seeding
  ) {
    return true;
  } else if (typeof user !== 'undefined') {
    return user.type === UserType.Admin || user.type === UserType.Host;
  }
  return false;
};

/**
 * Returns whether a date's availability can be modified based on the current time
 * @param date Date string for day in question
 */
export const canModifyAvailability = (modDate: Moment) =>
  modDate.isAfter(moment().add(1, 'days'), 'day');

export enum CertificationExpiryStatus {
  Good = 'good',
  Unset = 'unset',
  Expiring = 'expiring',
  Expired = 'expired',
}

export const certificateExpiryStatus = (
  dateExpiry: string
): CertificationExpiryStatus =>
  !dateExpiry
    ? CertificationExpiryStatus.Unset
    : moment(dateExpiry, DATE_FMT.DATE_KEY).isBefore(moment())
    ? CertificationExpiryStatus.Expired
    : moment(dateExpiry, DATE_FMT.DATE_KEY).isBefore(moment().add(2, 'months'))
    ? CertificationExpiryStatus.Expiring
    : CertificationExpiryStatus.Good;

export const getAppointmentTimeFrame = (
  appointment: Pick<Event, 'timezone' | 'start'>
): TimeFrame => {
  if (!appointment) {
    return undefined;
  }
  const now = moment().tz(appointment.timezone);
  const start = moment(appointment.start).tz(appointment.timezone);
  const mins = start.diff(now, 'minutes');
  const hours = Math.floor(mins / 60);
  const sameDay = start.isSame(now, 'date');
  if (hours >= 48) {
    return TimeFrame.Outside48;
  } else if (!sameDay) {
    return TimeFrame.Within48;
  } else if (hours >= 2) {
    return TimeFrame.SameDay;
  } else if (hours >= 0) {
    return TimeFrame.Within2;
  } else if (hours >= -1) {
    return TimeFrame.HasStarted;
  } else if (hours >= -3) {
    return TimeFrame.HasEnded;
  } else if (hours >= -49) {
    return TimeFrame.Complete;
  } else {
    return TimeFrame.Locked;
  }
};

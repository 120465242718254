import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import {
  AccountNoticeSerializer,
  Contact,
  EarlyAccessWindow,
  PropelAccount,
} from 'api/Serializers/Accounts';
import AdminAccount from 'api/Serializers/Accounts/Admin';
import HostAccount from 'api/Serializers/Accounts/Host';
import InstructorAccount, {
  AccountUpdateSerializer,
  Certification,
} from 'api/Serializers/Accounts/Instructor';
import {
  InstructorEarningsHistory,
  InstructorReliabilityHistory,
} from 'api/Serializers/Analytics';
import { ClientAccount, Participant } from 'api/Serializers/Clients';
import { DATE_FMT, FETCH_STATE, UserType } from 'config';
import { AccountUpdateError, GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { APIData } from 'state';
import {
  getAccountDetail,
  getAccountParticipants,
  getUser,
  getUsername,
} from 'state/selectors';
import { AppDispatch } from 'state/store';
import { BLANK_API_DATA, getInitialStateFromServer } from '../utils';

interface AccountReducer {
  detail: InstructorAccount | HostAccount | AdminAccount | ClientAccount;
  fetchState: string;
  isLoaded: boolean;
  type: UserType;
  participants: Participant[];
  credit: number;
  earlyAccessWindows: EarlyAccessWindow[];
  proxy: boolean;
  contacts: APIData<Contact[]>;
  reliability: APIData<InstructorReliabilityHistory>;
  earnings: APIData<InstructorEarningsHistory[]>;
  notices: APIData<AccountNoticeSerializer[]>;
}

const initialState: AccountReducer = {
  detail: undefined,
  fetchState: FETCH_STATE.PRISTINE,
  isLoaded: false,
  type: UserType.Anonymous,
  participants: [],
  earlyAccessWindows: [],
  credit: 0,
  proxy: undefined,
  contacts: BLANK_API_DATA([]),
  reliability: BLANK_API_DATA(undefined),
  earnings: BLANK_API_DATA([]),
  notices: BLANK_API_DATA([]),
};

// Exact name typing is required for typescript to know the attribute value //
const name: 'account' = 'account';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromServer(name, initialState),
  reducers: {
    setNoticesFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.notices.fetchState = action.payload;
    },
    setNoticesData(state, action: PayloadAction<AccountNoticeSerializer[]>) {
      state.notices.data = action.payload.map((notice) => ({
        ...notice,
        dismissed: false,
      }));
    },
    dismissNotice(state, action: PayloadAction<AccountNoticeSerializer>) {
      state.notices.data = state.notices.data.map((notice) =>
        notice.type === action.payload.type
          ? { ...notice, dismissed: true }
          : notice
      );
    },
    setReliabilityFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.reliability.fetchState = action.payload;
    },
    setReliabilityData(
      state,
      action: PayloadAction<InstructorReliabilityHistory>
    ) {
      state.reliability.data = action.payload;
    },
    setEarningsFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.earnings.fetchState = action.payload;
    },
    setContactsData(state, action: PayloadAction<Contact[]>) {
      state.contacts.data = action.payload;
    },
    setContactsFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.contacts.fetchState = action.payload;
    },
    setContactsFetchedAt(state, action: PayloadAction<string>) {
      state.contacts.fetchedAt = action.payload;
    },
    addContact(state, action: PayloadAction<Contact>) {
      state.contacts.data = [...state.contacts.data, action.payload];
    },
    updateContact(state, action: PayloadAction<Contact>) {
      const updateIndex = state.contacts.data.findIndex(
        (elt) => elt.id === action.payload.id
      );
      const newData = [...state.contacts.data];
      newData.splice(updateIndex, 1);
      newData.splice(updateIndex, 0, action.payload);
      state.contacts.data = newData;
    },
    removeContact(state, action: PayloadAction<string>) {
      state.contacts.data = [
        ...state.contacts.data.filter((elt) => elt.id !== action.payload),
      ];
    },
    setInstructorEarningsHistory(
      state,
      action: PayloadAction<InstructorEarningsHistory[]>
    ) {
      state.earnings.data = action.payload;
    },
    setCertifications(state, action) {
      const detail = state.detail as InstructorAccount;
      detail.certifications = action.payload;
    },
    fetchStateGet(state) {
      state.fetchState = FETCH_STATE.GET;
    },
    fetchStatePut(state) {
      state.fetchState = FETCH_STATE.PUT;
    },
    fetchStateFailed(state) {
      state.fetchState = FETCH_STATE.FAILED;
    },
    fetchStateIdle(state) {
      state.fetchState = FETCH_STATE.IDLE;
    },
    setCredit(state, action: PayloadAction<number>) {
      state.credit = action.payload;
    },
    setEarlyAccessWindows(state, action: PayloadAction<EarlyAccessWindow[]>) {
      state.earlyAccessWindows = action.payload;
    },
    setDetail(state, action: PayloadAction<PropelAccount>) {
      state.detail = action.payload;
      state.type = action.payload.type;
      state.fetchState = FETCH_STATE.IDLE;
      state.isLoaded = true;
    },
    setParticipants(state, action: PayloadAction<Participant[]>) {
      state.participants = action.payload;
    },
    setProxy(state, action: PayloadAction<boolean>) {
      state.proxy = action.payload;
    },
    updateAccount(
      state,
      action: PayloadAction<Partial<AccountUpdateSerializer>>
    ) {
      state.detail = { ...state.detail, ...action.payload };
    },
    removeAccountImage(state, action: PayloadAction<number>) {
      const detail = state.detail as InstructorAccount;
      detail.media = [...detail.media].filter((p) => p.id !== action.payload);
    },
  },
});

const {
  setNoticesFetchState,
  setNoticesData,
  setReliabilityData,
  setReliabilityFetchState,
  setContactsData,
  setContactsFetchState,
  setContactsFetchedAt,
  setInstructorEarningsHistory,
  setEarningsFetchState,
  setParticipants,
  setCertifications,
  fetchStateGet,
  fetchStatePut,
  fetchStateFailed,
  fetchStateIdle,
  setDetail,
  setEarlyAccessWindows,
  removeAccountImage,
  setCredit,
  updateAccount,
} = Slice.actions;

export const {
  setProxy,
  addContact,
  updateContact,
  dismissNotice,
  removeContact,
} = Slice.actions;

export const certificationsUpdated = (payload: Certification[]) =>
  setCertifications(payload);

export const modifyAccount =
  (username: string, data: Partial<AccountUpdateSerializer>) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(fetchStatePut());
    const state = getState();
    const preMod = getAccountDetail(state);
    try {
      dispatch(updateAccount(data));
      const response = await api.account.update(username, data);
      return dispatch(getUserAccountSuccess(response.data));
    } catch (error: any) {
      dispatch(updateAccount(preMod));
      enqueueSnackbar(AccountUpdateError);
      dispatch(fetchStateFailed());
      return false;
    }
  };

export const removeImage =
  (mediaId) => async (dispatch: AppDispatch, getState) => {
    dispatch(removeAccountImage(mediaId));
    try {
      await api.media.delete(mediaId);
    } catch (error) {
      enqueueSnackbar({
        message: 'Failed to remove media',
        variant: 'error',
      });
    }
  };

export const fetchNotices = () => async (dispatch, getState) => {
  const state = getState();
  const username = getUsername(state);
  try {
    dispatch(setNoticesFetchState(FETCH_STATE.GET));
    const response = await api.account.notices(username);
    dispatch(setNoticesData(response.data));
  } catch (error) {
    enqueueSnackbar(GenericServerError);
  }
  dispatch(setNoticesFetchState(FETCH_STATE.IDLE));
};

export const retrieveAccount =
  (username: string) => async (dispatch: AppDispatch, getState) => {
    dispatch(fetchStateGet());
    try {
      const response = await api.account.retrieve(username);
      return dispatch(getUserAccountSuccess(response.data));
    } catch (err) {
      enqueueSnackbar(GenericServerError);
      dispatch(fetchStateFailed());
      return null;
    }
  };

export const fetchAccountParticipants = async (
  dispatch: AppDispatch,
  getState
) => {
  const state = getState();
  const user = getUser(state);
  if (user.type !== UserType.Client) {
    return null;
  }
  try {
    dispatch(fetchStateGet());
    const response = await api.clients.participants(user.username);
    dispatch(setParticipants(response.data as Participant[]));
  } catch (error) {
    enqueueSnackbar(GenericServerError);
  }
  dispatch(fetchStateIdle());
  return null;
};

export const fetchReliability =
  () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const user = getUser(state);
    try {
      dispatch(setReliabilityFetchState(FETCH_STATE.GET));
      const response = await api.account.reliability(user.username);
      dispatch(setReliabilityData(response.data));
      dispatch(setReliabilityFetchState(FETCH_STATE.FULFILLED));
    } catch (error) {
      dispatch(setReliabilityFetchState(FETCH_STATE.FAILED));
      return null;
    }
  };

export const fetchEarnings = () => async (dispatch: AppDispatch, getState) => {
  const state = getState();
  const user = getUser(state);
  try {
    dispatch(setEarningsFetchState(FETCH_STATE.GET));
    const response = await api.account.earnings(user.username);
    dispatch(setInstructorEarningsHistory(response.data));
    dispatch(setEarningsFetchState(FETCH_STATE.FULFILLED));
  } catch (error) {
    dispatch(setEarningsFetchState(FETCH_STATE.FAILED));
    return null;
  }
};

export const fetchContacts = () => async (dispatch: AppDispatch, getState) => {
  const state = getState();
  const username = getUsername(state);
  try {
    dispatch(setContactsFetchState(FETCH_STATE.GET));
    const response = await api.account.contacts.list(username);
    dispatch(setContactsData(response.data));
    dispatch(setContactsFetchState(FETCH_STATE.FULFILLED));
    dispatch(setContactsFetchedAt(moment().format(DATE_FMT.DATETIME_FIELD)));
  } catch (error) {
    dispatch(setContactsFetchState(FETCH_STATE.FAILED));
  }
};

export const createAccountParticipant =
  (data: Pick<Participant, 'name' | 'age'>) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(fetchStateGet());
    const state = getState();
    const user = getUser(state);
    try {
      const response = await api.clients.participants(user.username, data);
      const participant = response.data as Participant;
      const newState = getState();
      const participants = getAccountParticipants(newState);
      dispatch(setParticipants(participants.concat([participant])));
      dispatch(fetchStateIdle());
      return participant;
    } catch (error) {
      dispatch(fetchStateIdle());
      enqueueSnackbar({
        message: 'Hmm, we hit a snag saving that. Wait a moment and try again.',
        variant: 'error',
      });
      return undefined;
    }
  };

const getUserAccountSuccess =
  (account: PropelAccount) => (dispatch: AppDispatch) => {
    if (!account) {
      return null;
    }
    return dispatch(setDetail(account));
  };

export const saveCertification =
  (username: string, data: Certification) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(fetchStatePut());
    await api.account.certifications
      .create(username, data)
      .then((response) => {
        dispatch(setCertifications(response.data));
        dispatch(fetchStateIdle());
      })
      .catch((error) => dispatch(fetchStateFailed()));
  };

export const fetchAccountCredit = () => async (dispatch, getState) => {
  const state = getState();
  const username = getUsername(state);
  try {
    const response = await api.account.credit(username);
    dispatch(setCredit(response.data));
  } catch (err) {
    logger.error(err);
    enqueueSnackbar(GenericServerError);
  }
};

export const fetchEarlyAccessWindows = () => async (dispatch, getState) => {
  const state = getState();
  const user = getUser(state);
  if (!user || user.type !== UserType.Instructor) {
    return null;
  }
  try {
    const response = await api.account.earlyAccessWindows(user.username);
    dispatch(setEarlyAccessWindows(response.data));
  } catch (err) {
    logger.captureAxiosError('Error getting early access windows', err as any);
  }
};

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
